import RTableLayout from "../../layout/RTableLayout";
import {DText, Flex, Row} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {MiniLineBreak, RespIndex} from "../../../../styles/main/MainCommonStyles";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import {formatDate} from "../../../../core/utils/common";
import {useMainContext} from "../../../../core/contexts/main";


const RUserActivityTable = ({ data }) => {

    const { lang } = useMainContext()
    const { data: sessions } = data

    return (
        <RTableLayout
            data={data}
            hasPagination={false}
        >
            {sessions?.data?.map((session, idx) => (
                <Row
                    cs={'100%'}
                    index={idx}
                    currentOrder={!session.isDelete}
                    type={'buy'}
                    percent={100}
                    noHover
                >
                    <Flex fw justify={'space-between'}>
                        <DText main fontSize={'s'}>
                            <Text tid={'ip'} /> :
                        </DText>
                        <DText main fontSize={'s'}>
                            {session.ip}
                        </DText>
                    </Flex>
                    <MiniLineBreak />
                    <Flex fw justify={'space-between'}>
                        <DText main fontSize={'s'}>
                            <Text tid={'browser'} /> :
                        </DText>
                        <DText main fontSize={'s'}>
                            {`${session.extra?.deviceName}-${session?.extra?.deviceVersion}-${session?.extra?.appVersion}`}
                        </DText>
                    </Flex>
                    <MiniLineBreak />
                    <Flex fw justify={'space-between'}>
                        <DText main fontSize={'s'}>
                            <Text tid={'platform'} /> :
                        </DText>
                        <DText main fontSize={'s'}>
                            <TradeTypeBadge color={getTradeTypeColor('limit')}>
                                {session.platform}
                            </TradeTypeBadge>
                        </DText>
                    </Flex>
                    <MiniLineBreak />
                    <Flex fw justify={'space-between'}>
                        <DText main fontSize={'s'}>
                            {formatDate(session.createdAt, 'date', lang)}
                        </DText>
                        <DText main fontSize={'s'}>
                            {formatDate(session.createdAt, 'time', lang)}
                        </DText>
                    </Flex>
                    <RespIndex>
                        {idx + 1}
                    </RespIndex>
                </Row>
            ))}
        </RTableLayout>
    )
}


export default RUserActivityTable
