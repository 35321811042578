import styled from "styled-components";
import {DText, Flex} from "../../../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../../../core/constants/urls";


const CoinTag = ({coin}) => {

    return (
        <Wrapper>
            <Flex fw fh justify={'space-around'}>
                <img
                    src={SOCKET_URL + `assets/icon/${coin}.png`}
                    alt={' '}
                    width='28px'
                    height='28px'
                />
                <DText main>
                    {coin.toUpperCase()}
                </DText>
            </Flex>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 140px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.color}10;
  background-color: ${props => props.theme.secondaryBg};
  margin: 4px;
`

export default CoinTag
