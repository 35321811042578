import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import UserLevelsView from "../../../components/main/setting/user-levels/UserLevelsView";


const UserLevels = () => {

    return (
        <ListLayout>
            <PageCard>
                <UserLevelsView />
            </PageCard>
        </ListLayout>
    )
}

export default UserLevels;
