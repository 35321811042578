import {forwardRef, useState, useImperativeHandle, useEffect} from "react";
import {useFilterContext} from "../../../../core/contexts/filter";
import {Background, CFlex, CMargin, DText} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import Input from "../../../common/input/Input";
import {useQueryContext} from "../../../../core/contexts/query";



const InputFilter = forwardRef((props, ref) => {

    const { setToast } = useQueryContext()

    const { filter, onClose } = props
    const { name, search } = filter
    const { filters: _filters, setFilters, cache } = useFilterContext()
    const filters = _filters[cache]

    const [value, setValue] = useState(filters[search] ? filters[search] : '')

    useImperativeHandle(ref, () => ({
        submitFilters: () => {
            if (value) {
                const newState = {...filters}
                newState[search] = value
                setFilters(newState)
                onClose()
            }else {
                setToast({
                    show: true, isError: true,
                    message: 'fill-input-errors'
                })
            }
        }
    }))


    return (
        <CFlex>
            <Background bg={'primaryBg'}>
                <DText main>
                    <Text tid={`filter-${name}`} />
                </DText>
                <CMargin margin={'4px'} />
                <Input
                    size={'small'}
                    value={value}
                    onInputChange={(v) => setValue(v)}
                    label={name}
                />
            </Background>
        </CFlex>
    )
})


export default InputFilter
