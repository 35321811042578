import {Column, Row} from "../../../../../styles/CommonStyles";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import Text from "../../../../../core/utils/Text";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import DateTime from "../../../../common/utils/DateTime";
import CopyText from "../../../../common/utils/CopyText";


const NobitexOrderHistoryTable = ({ data, cs }) => {

    return (
        <>
            {data?.data?.data?.response?.trades?.map((item, idx) => {

                return (
                    <Row cs={cs} key={item.id}>
                        <Column>{idx+1}</Column>
                        <Column>
                            <CopyText text={item.id} />
                        </Column>
                        <Column>{item.market}</Column>
                        <Column>{item.price}</Column>
                        <Column>{item.amount}</Column>
                        <Column>{item.total}</Column>
                        <Column>{item.fee}</Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor(item.type)}>
                                <Text tid={item.type} />
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <CopyText text={item.orderId} />
                        </Column>
                        <Column center>
                            <DateTime dt={item.timestamp} />
                        </Column>
                    </Row>
                )
            })}
        </>
    )
}

export default NobitexOrderHistoryTable
