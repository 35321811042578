import { availableTokenOperations, notAvailableTokenOperations } from "../../../../../core/constants/operation";
import { SOCKET_URL } from "../../../../../core/constants/urls";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import useTokens from "../../../../../core/hooks/main/setting/tokens/useTokens";
import Text from "../../../../../core/utils/Text";
import { getTradeTypeColor } from "../../../../../core/utils/theme";
import { CMargin, DText, Flex, Row } from "../../../../../styles/CommonStyles";
import { MiniLineBreak, RespIndex } from "../../../../../styles/main/MainCommonStyles";
import { TradeTypeBadge } from "../../../../../styles/main/orders";
import Operation from "../../../../common/utils/Operation";
import RTableLayout from "../../../layout/RTableLayout"
import {BiCheck} from "react-icons/bi";
import {RiCloseLine} from "react-icons/ri";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";


const RTokensTable = ({
    data
}) => {

    const { data: tokens } = data
    const { getTableIndex } = useGetTableIndex()

    const {
        tokenList,
        onDetailsClicked,
        onOptionClicked,
        deleteModal,
        onDeleteModalClose,
        onDeleteToken,
        deletingToken,
        deleteFromACModal,
        onACModalClose,
        onDeleteFromAC,
        acLoading
    } = useTokens(tokens)

    return (
        <RTableLayout
            data={data}
        >
            {tokenList.map((token, idx) => (
                <Row index={idx} cs={'100%'} key={token.contract}>
                    <Flex justify='space-between'>
                        <Flex>
                            <img
                                src={SOCKET_URL + `assets/icon/${token.symbol}.png`}
                                alt={' '}
                                width={'24px'}
                            />
                            <DText main style={{ margin: '0 8px' }}>
                                {token.symbol?.toUpperCase()}
                            </DText>
                        </Flex>
                        <Operation
                            options={token.isAvailable ? availableTokenOperations : notAvailableTokenOperations}
                            onOptionClicked={(idx) => onOptionClicked(idx, token)}
                            onDetailsClicked={() => onDetailsClicked(token)}
                            hasWriteAccess
                            hasDetails
                        />
                    </Flex>
                    <CMargin margin='8px' />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='smart-contract' /> :
                        </DText>
                        <DText primary fontSize='s'>
                            {token.contract}
                        </DText>
                    </Flex>
                    <MiniLineBreak />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='blockchain' /> :
                        </DText>
                        <TradeTypeBadge color={getTradeTypeColor('success')}>
                            {token.name}
                        </TradeTypeBadge>
                    </Flex>
                    <MiniLineBreak />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='network' /> :
                        </DText>
                        <TradeTypeBadge color={getTradeTypeColor('limit')}>
                            {token.network}
                        </TradeTypeBadge>
                    </Flex>
                    <MiniLineBreak />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='decimal' /> :
                        </DText>
                        <DText primary fontSize='s'>
                            {token.decimal}
                        </DText>
                    </Flex>
                    <MiniLineBreak />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='is-active' /> :
                        </DText>
                        {token.isActive ?
                            <BiCheck color={'#37b88b'} size={24}/>
                            :
                            <RiCloseLine color={'#f6465d'} size={24} />
                        }
                    </Flex>
                    <MiniLineBreak />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='is-available' /> :
                        </DText>
                        {token.isAvailable ?
                            <BiCheck color={'#37b88b'} size={24}/>
                            :
                            <RiCloseLine color={'#f6465d'} size={24} />
                        }
                    </Flex>
                    <CMargin margin='6px' />
                    <RespIndex>
                        {getTableIndex(idx)}
                    </RespIndex>
                </Row>
            ))}
            <ModalLayout
                onClose={onDeleteModalClose}
                open={deleteModal.open}
                width={'520px'}
            >
                <BasicModal
                    onClose={onDeleteModalClose}
                    _id={deleteModal.token?._id}
                    loading={deletingToken}
                    head={'delete-token-head'}
                    desc={'delete-token-desc'}
                    onSubmit={onDeleteToken}
                />
            </ModalLayout>

            <ModalLayout
                onClose={onACModalClose}
                open={deleteFromACModal.open}
                width={'520px'}
            >
                <BasicModal
                    onClose={onACModalClose}
                    _id={deleteFromACModal.token?._id}
                    loading={acLoading}
                    head={'delete-available-coin-head'}
                    desc={'delete-available-coin-desc'}
                    onSubmit={onDeleteFromAC}
                />
            </ModalLayout>
        </RTableLayout>
    );
};

export default RTokensTable;
