import {Column, Row} from "../../../../../styles/CommonStyles";
import CoinRow from "../../../../common/common/CoinRow";
import CopyText from "../../../../common/utils/CopyText";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import Text from "../../../../../core/utils/Text";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import DateTime from "../../../../common/utils/DateTime";


const NobitexWithdrawsTable = ({ data, cs }) => {

    return (
        <>
            {data?.data?.data?.response?.withdraws?.map((item, idx) => {

                return (
                    <Row key={item.id} cs={cs}>
                        <Column>{idx+1}</Column>
                        <Column>
                            <CopyText text={item.id} />
                        </Column>
                        <CoinRow coin={{ fa: item.currency, id: item.currency, name: item.currency }} />
                        <Column>{item.amount}</Column>
                        <Column>
                            <CopyText text={item.address} />
                        </Column>
                        <Column>
                            <CopyText text={item.wallet_id} />
                        </Column>
                        <Column>
                            <CopyText text={item.blockchain_url} />
                        </Column>
                        <Column>
                            <CoinRow coin={{ fa: item.network, id: item.network?.toLowerCase(), name: item.network }} />
                        </Column>
                        <Column center>
                            <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                                <Text tid={item.status} />
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                    </Row>
                )
            })}
        </>
    )
}


export default NobitexWithdrawsTable
