import {NameAvatar, ProfileAvatar} from "../../../styles/main/profile";
import {getRandomColor} from "../../../core/utils/theme";


const Avatar = ({
    avatar,
    name
}) => {

    return (
        <ProfileAvatar style={{ margin: '0 5px' }}>
            {avatar ?
                <img
                    style={{ borderRadius: '50%' }}
                    width='95%'
                    height='95%'
                    src={avatar}
                    alt={' '}
                />
                :
                <NameAvatar color={getRandomColor(0)}>
                    {name?.toUpperCase().substring(0, 1)}
                </NameAvatar>
            }
        </ProfileAvatar>
    )
}


export default Avatar
