import {useMemo} from "react";
import {useMainContext} from "../../../../core/contexts/main";
import {useState} from "react";
import {useDailyRecords} from "../../../../core/services/react-query/panel";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../../core/utils/common";

export const useChartFilter = () => {

    /* date */
    const [date, setDate] = useState(new Date())
    const { recordFrom, recordTo } = useMemo(() => {
        const formatD = (d) =>  {
            let month = d.getMonth() + 1
            if (month < 10) month = `0${month}`
            let day = d.getDate()
            if (day < 10) day = `0${day}`
            return `${d.getFullYear()}-${month}-${day}`
        }
        const startDate = new Date(date)
        startDate.setDate(1)
        const endDate = new Date(startDate)
        endDate.setMonth(endDate.getMonth() + 1)
        return {
            recordFrom: formatD(startDate),
            recordTo: formatD(endDate)
        }
    }, [date])
    const { data: records } = useDailyRecords({recordFrom, recordTo})

    /* filter coin */
    const [coin, setCoin] = useState({ id: 'btc', name: 'Bitcoin', fa: 'بیتکوین' })

    /* filter mode */
    const modes = ['buy-price', 'sell-price', 'usd-price']
    const [mode, setMode] = useState('buy-price')

    return {
        date, setDate,
        coin, setCoin,
        mode, setMode,
        modes, records
    }
}

export const useGetPriceTimeOptions = ({ records, coin, mode }) => {
    const { theme } = useMainContext()
    return useMemo(() => {

        const xAxis = []
        const yAxis = []

        records?.forEach(rec => {
            xAxis.push(rec.recordAt)
            const cr = rec.record.price?.find(x => x.id === coin.id)
            if (mode === 'buy-price') yAxis.push(cr?.buy || 0)
            if (mode === 'sell-price') yAxis.push(cr?.sell || 0)
            if (mode === 'usd-price') yAxis.push(cr?.value || 0)
        })

        return {
            xAxis: {
                data: xAxis.reverse()
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {

                    return `
                        ${params[0].axisValue} <br />
                        ${params[0].marker} <span style="font-weight:900">${formatNumber(params[0].value)}</span>
                    `
                }
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '10%'],
                splitLine: {
                    lineStyle: {
                        color: theme === 'dark' ? '#c3c5b720' : '#00000020',
                        type: 'dashed'
                    }
                }
            },
            series: [
                {
                    name: 'Time_Price',
                    type: 'line',
                    showSymbol: false,
                    data: yAxis.reverse()
                }
            ]

        }

    }, [records, coin, mode])
}


export const useGetWageVolumeOptions = ({ records, coin }) => {

    const WAGE_SCALE = 20
    const emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)',
        }
    };

    const { theme } = useMainContext()

    return useMemo(() => {
        const xAxis = []
        const yAxis = {
            volume: [],
            wage: []
        }

        records?.forEach(rec => {
            xAxis.push(rec.recordAt)
            const cr = rec.record.wage?.find(x => (x.currency || x._id?.currency) === coin.id)
            yAxis.volume.push(cr?.volumes || 0)
            yAxis.wage.push(cr?.wages * WAGE_SCALE || 0)
        })

        return {
            xAxis: {
                data: xAxis.reverse()
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params) => {

                    return `
                        ${params[0].axisValue} <br />
                        ${params[0].marker} ${params[0].seriesName} <span style="font-weight:900;">${formatNumber(params[0].value)}</span> <br />
                        ${params[1].marker} ${params[1].seriesName} <span style="font-weight:900;">${formatNumber(params[1].value / WAGE_SCALE)}</span>
                    `
                }
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '10%'],
                splitLine: {
                    lineStyle: {
                        color: theme === 'dark' ? '#c3c5b720' : '#00000020',
                        type: 'dashed'
                    }
                }
            },
            series: [
                {
                    name: 'Volume',
                    type: 'bar',
                    stack: 'one',
                    data: yAxis.volume.reverse(),
                    emphasis: emphasisStyle,
                },
                {
                    name: 'Wage',
                    type: 'bar',
                    stack: 'two',
                    data: yAxis.wage.reverse(),
                    emphasis: emphasisStyle,
                },
            ]
        }

    }, [records, coin])
}


export const useGetWageVolumeCompareOptions = ({ records, coin, compare }) => {

    const WAGE_SCALE = 20
    const emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0,0,0,0.3)',
        }
    };

    const { theme } = useMainContext()

    return useMemo(() => {
        const xAxis = []
        const yAxis = {
            first: {
                volume: [],
                wage: []
            },
            second: {
                volume: [],
                wage: []
            }
        }

        records?.forEach(rec => {
            xAxis.push(rec.recordAt)
            const firstCoin = rec.record.wage?.find(x => (x.currency || x._id?.currency) === coin.id)
            const secondCoin = rec.record.wage?.find(x => (x.currency || x._id?.currency) === compare.id)
            yAxis.first.volume.push(firstCoin?.volumes || 0)
            yAxis.first.wage.push(firstCoin?.wages * WAGE_SCALE || 0)

            yAxis.second.volume.push(secondCoin?.volumes || 0)
            yAxis.second.wage.push(secondCoin?.wages * WAGE_SCALE || 0)
        })

        return {
            xAxis: {
                data: xAxis.reverse()
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params) => {

                    return `
                        ${params[0].axisValue} <br />
                        ${params[0].marker} ${params[0].seriesName} <span style="font-weight:900;">${formatNumber(params[0].value)}</span> <br />
                        ${params[1].marker} ${params[1].seriesName} <span style="font-weight:900;">${formatNumber(params[1].value / WAGE_SCALE)}</span> <br />
                        ${params[2].marker} ${params[2].seriesName} <span style="font-weight:900;">${formatNumber(params[2].value)}</span> <br />
                        ${params[3].marker} ${params[3].seriesName} <span style="font-weight:900;">${formatNumber(params[3].value / WAGE_SCALE)}</span>
                    `
                }
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '10%'],
                splitLine: {
                    lineStyle: {
                        color: theme === 'dark' ? '#c3c5b720' : '#00000020',
                        type: 'dashed'
                    }
                }
            },
            series: [
                {
                    name: `Volume ${coin.id?.toUpperCase()}`,
                    type: 'bar',
                    stack: 'one',
                    data: yAxis.first.volume.reverse(),
                    emphasis: emphasisStyle,
                },
                {
                    name: `Wage ${coin.id?.toUpperCase()}`,
                    type: 'bar',
                    stack: 'two',
                    data: yAxis.first.wage.reverse(),
                    emphasis: emphasisStyle,
                },
                {
                    name: `Volume ${compare.id?.toUpperCase()}`,
                    type: 'bar',
                    stack: 'three',
                    data: yAxis.second.volume.reverse(),
                    emphasis: emphasisStyle,
                },
                {
                    name: `Wage ${compare.id?.toUpperCase()}`,
                    type: 'bar',
                    stack: 'four',
                    data: yAxis.second.wage.reverse(),
                    emphasis: emphasisStyle,
                },
            ]
        }

    }, [records, coin, compare])
}
