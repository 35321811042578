import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import AvailableBanksTable from "./AvailableBanksTable";
import RAvailableBanksTable from "../../../../responsive/main/setting/general/available-banks/RAvailableBanksTable";
import MotionFade from "../../../../common/utils/MotionFade";


const AvailableBanks = () => {

    const { width } = useWindowSize()
    const Component = width > TABLET_SIZE ? AvailableBanksTable : RAvailableBanksTable

    return (
        <MotionFade>
            {width && <Component />}
        </MotionFade>
    )
}


export default AvailableBanks
