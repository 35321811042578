import {useEffect, useState} from "react";
import {useSetRobotConfig} from "../../../../services/react-query/setting/robots";
import {deepCopy, formatObjectNumbers} from "../../../../utils/common";


const initialRobot = {
    userId: '',
    userIp: '',
    data: []
}

const initialMarket = {
    coin: null, pair: null, isActive: false,
    order: {
        buy: {
            minAmount: 0, maxAmount: 0, minPriceFactor: 0, maxPriceFactor: 0, skipOnCount: 0
        },
        sell: {
            minAmount: 0, maxAmount: 0, minPriceFactor: 0, maxPriceFactor: 0, skipOnCount: 0
        }
    },
    trade: {
        buy: {
            minAmount: 0, maxAmount: 0, maxPriceFactor: 0, skipOnMinutes: 0, skipOnCount: 0
        },
        sell: {
            minAmount: 0, maxAmount: 0, minPriceFactor: 0, skipOnMinutes: 0, skipOnCount: 0
        }
    }
}


const useRobots = () => {

    const { mutate: updateRobot, isLoading: loading, isSuccess: robotUpdated } = useSetRobotConfig()

    const [robotCache, setRobotCache] = useState(initialRobot)
    const [robot, setRobot] = useState(initialRobot)

    const resetRobot = () => {
        setRobot(robotCache)
    }

    const [editMode, setEditMode] = useState(false)
    const enterEditMode = () => {
        setEditMode(true)
    }

    const exitEditMode = () => {
        setEditMode(false)
        resetRobot()
    }


    const onQuerySuccess = (res) => {
        if (res?.data) {
            setRobot(res?.data)
            setRobotCache(res?.data)
        }
    }

    /**
     * @param v - value
     * @param type - type to change
     * @param index - market index
     * @param extra - { transaction (buy - sell), type (order - trade), key (object key)}
     */
    const onDetailsChange = (v, type, index, extra) => {
        const newRobot = deepCopy(robot)
        const newMarket = newRobot.data
        let market;

        switch (type) {
            case 'userId':
                newRobot.userId = v
                break
            case 'userIp':
                newRobot.userIp = v
                break
            case 'is-active':
                market = newMarket[index]
                market.isActive = v
                setRobot(newRobot)
                break
            case 'coin':
                market = newMarket[index]
                market.coin = v
                break
            case 'pair':
                market = newMarket[index]
                market.pair = v
                break
            case 'market':
                const { transaction, type, key } = extra
                market = newMarket[index]
                market[type][transaction][key] = v
                break
            default:
                break
        }
        setRobot(newRobot)
    }

    const addNewMarket = () => {
        const newRobot = deepCopy(robot)
        newRobot.data.push(initialMarket)
        setRobot(newRobot)
    }

    const deleteMarket = (idx) => {
        const newRobot = deepCopy(robot)
        newRobot.data?.splice(idx, 1)
        setRobot(newRobot)
    }

    const onSubmitChanges = () => {
        if (loading) return

        for (let i = 0; i < robot.data.length; i++) {
            const market = robot.data[i]
            market.order.buy = formatObjectNumbers(market.order.buy)
            market.order.sell = formatObjectNumbers(market.order.sell)
            market.trade.buy = formatObjectNumbers(market.trade.buy)
            market.trade.sell = formatObjectNumbers(market.trade.sell)
        }
        updateRobot(robot)
    }

    useEffect(() => {
        if (robotUpdated) {
            exitEditMode()
        }
    }, [robotUpdated])

    return {
        robot,
        onQuerySuccess,
        editMode,
        enterEditMode,
        exitEditMode,
        onDetailsChange,
        onSubmitChanges,
        loading,
        addNewMarket,
        deleteMarket
    }
}


export default useRobots
