import styled from "styled-components";


const Toggle = (props) => {

    const {
        active,
        onChange
    } = props

    return (
        <Wrapper onClick={onChange} {...props}>
            <ButtonWrapper>
                <Button active={active}/>
            </ButtonWrapper>
        </Wrapper>
    )
}


const Wrapper = styled.div`
  width: 34px;
  height: 16px;
  border-radius: 4px;
  position: relative;
  border: 1px solid ${props => props.theme.color}10;
  background-color: ${props => props.theme.tInputBg};
  cursor: pointer;
`


const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Button = styled.div`
  border-radius: 4px;
  background-color: ${props => props.active ? props.theme.mainGreen : props.theme.mainRed}90;
  box-shadow: 0 0 2px rgb(0, 0, 0, 0.01);
  width: 14px;
  height: 14px;
  position: absolute;
  transition: all 0.3s;
  
  right: ${props => props.active ? 'calc(100% - 15px)' : '2px'};
`


export default Toggle