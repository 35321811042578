import {useConnectionContext} from "../../../core/contexts/connection";
import styled, {css, keyframes} from "styled-components";
import Tooltip from "../../common/utils/Tooltip";
import {Flex} from "../../../styles/CommonStyles";


const ConnectionStatus = () => {

    const { status } = useConnectionContext()

    return (
        <Tooltip
            content={status === 'error' ? 'not-connected' : 'connected'}
            placement={'bottom'}
        >
            <Flex align={'flex-end'} style={{ cursor: 'pointer', margin: '0 8px', direction: 'rtl' }}>
                {[4, 3, 2, 1].map(item => (
                    <Bar key={item} idx={item} connected={status !== 'error'}  />
                ))}
            </Flex>
        </Tooltip>
    )
}



const animate = (theme, idx) => keyframes`
  ${(idx - 1) * 25}% {
    background-color: ${theme.color};
  }
  ${idx * 25}% {
    background-color: ${theme.mainOrange};
  }
  100%{
    background-color: ${theme.mainOrange};
  }
`

const Bar = styled.div`
  height: ${props => props.idx * 4}px;
  width: 3px;
  border-radius: 2px;
  background-color: ${props => props.connected ? props.theme.mainGreen : props.theme.color};
  margin: 0 1.5px;
  
  ${props => !props.connected && css`
    animation: ${props => animate(props.theme, props.idx)} 2s infinite forwards;
  `};
`


export default ConnectionStatus
