import {useQueryContext} from "../../../../core/contexts/query";
import {
    COINING_KEYS, useConfirmCoinTransaction,
    useGetCoiningConfig,
    useUpdateCoinTransaction, useUpdateNobitexCoinTransaction
} from "../../../../core/services/react-query/coining";
import {
    BANKING_KEYS,
    useGetBankingConfig,
    useUpdateBankTransaction
} from "../../../../core/services/react-query/report/banking";
import {useEffect, useMemo, useState} from "react";
import {useGetThirdBalances} from "../../../../core/services/react-query/setting/thirdparty";


export const useTransaction = ({details, onClose}) => {

    const { queryClient, setToast } = useQueryContext()

    const [thirdParty, setThirdParty] = useState('')
    const [note, setNote] = useState('')

    const onTransactionUpdate = () => {
        onClose()
        queryClient.invalidateQueries(
            details.type === 'coining' ?
                COINING_KEYS.GET_A_TRANSACTION
                :
                BANKING_KEYS.GET_A_TRANSACTION
        )
    }
    const configQuery = details.type === 'coining' ? useGetCoiningConfig : useGetBankingConfig
    const { data: config } = configQuery()

    const query = details.type === 'coining' ? useUpdateCoinTransaction : useUpdateBankTransaction
    const { mutate: updateTransaction, isLoading } = query(onTransactionUpdate)

    const {
        nobitexState,
        ga, otp, setGa, setOtp,
        nobitexWithdraw, nobitexId,
        updateTransactionWithNobitex,
        confirmTransaction,
        loadingNobitex, nobitexError
    } = useNobitexTransaction({details, thirdParty, onClose})

    const valid = useMemo(() => {
        if (!!note) return true
        if (details.action === 'approve' && !!thirdParty) {
            if (thirdParty === 'nobitex') {
                if (ga.length === 6 && nobitexState === 1) return true
                if (otp.length === 6 && nobitexState === 2) return true
            } else return true
        }
        return false
    }, [note, details, thirdParty, ga, nobitexState, otp])

    const STATUS = details?.action === 'approve' ? 'success' : (
        details?.action === 'reject' ? 'error' : 'rollback'
    )

    const onSubmitClicked = () => {
        try {
            if (!valid) throw new Error('not-valid-action')

            if (nobitexState === 1) {
                let payload = {
                    id: details?.transaction?._id,
                    data: {
                        status: STATUS,
                        note,
                        thirdParty
                    }
                }

                if (thirdParty === 'nobitex') {
                    payload.data.walletId = nobitexId.toString()
                    payload.data.otp = ga
                    updateTransactionWithNobitex(payload)
                }else {
                    updateTransaction(payload)
                }
            }else {
                let payload = {
                    thirdParty,
                    withdraw: nobitexWithdraw?.data?.data?.thirdParty?.result?.withdraw?.id?.toString(),
                    otp
                }
                confirmTransaction({payload, id: details?.transaction?._id})
            }
        }catch (_) {
            setToast({
                isError: true, show: true,
                message: 'fill-inputs-error'
            })
        }
    }

    return {
        onSubmitClicked,
        loading: isLoading || loadingNobitex,
        thirdParty, setThirdParty,
        config,
        ga, setGa, otp, setOtp,
        nobitexWithdraw, nobitexError,
        note, setNote, valid
    }

}

const useNobitexTransaction = ({details, thirdParty, onClose}) => {

    const [nobitexState, setNobitexState] = useState(1)
    const [nobitexError, setNobitexError] = useState('')

    const onNobitexSuccess = () => {
        setNobitexState(state => state + 1)
    }
    const onNobitexError = (res) => {
        setNobitexError(res?.response?.data?.message)
    }
    const { data: nobitexWithdraw, mutate: updateTransactionWithNobitex } = useUpdateNobitexCoinTransaction(onNobitexSuccess, onNobitexError)
    const { mutate: confirmTransaction } = useConfirmCoinTransaction(onClose)

    /* nobitex only withdraw */
    const [ga, setGa] = useState('')
    const [otp, setOtp] = useState('')
    const [nobitexId, setNobitexId] = useState('')
    const { data: nobitexCoins, refetch, isLoading: loadingNobitex } = useGetThirdBalances('nobitex')
    useEffect(() => {
        if (thirdParty === 'nobitex' && !nobitexCoins) refetch()
    }, [thirdParty, nobitexCoins])

    useEffect(() => {
        if (!!nobitexCoins) {
            let coinObjectInNobitex;
            try {
                coinObjectInNobitex = nobitexCoins.response.wallets[details?.transaction.coin?.toUpperCase()]
                setNobitexId(coinObjectInNobitex?.id)
            }catch (_) {}
        }
    }, [nobitexCoins])

    return {
        nobitexState,
        ga, otp, setGa, setOtp,
        nobitexWithdraw, nobitexId,
        updateTransactionWithNobitex,
        confirmTransaction, loadingNobitex,
        nobitexError
    }
}
