import { AC_SETTING_TYPES } from "../../../../../core/hooks/main/setting/available-coins/useAvailableCoinsSetting"
import Text from "../../../../../core/utils/Text"
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../../../../styles/CommonStyles"
import Input from "../../../../common/input/Input"
import MotionFade from "../../../../common/utils/MotionFade"


const PriceUpdate = ({
    coin,
    onDetailChange
}) => {


    return (
        <MotionFade>
            <CFlex fw align='flex-start'>
                <CMargin margin='4px' />
                <Flex>
                    <Decoration />
                    <DText main>
                        <Text tid='update-price-note' />
                    </DText>
                </Flex>
                <CMargin margin='10px' />
                <Flex fw wrap justify='flex-start'>
                    <Background bg='secondaryBg'>
                        <Input
                            value={coin?.price.buyMin}
                            label={'buy-min-price'}
                            onInputChange={(v) => onDetailChange({
                                type: AC_SETTING_TYPES.PRICE,
                                value: v,
                                key: 'buyMin'
                            })}
                            minWidth='260px'
                            width='50%'
                            currency='usd'
                            number
                        />
                        <Input
                            value={coin?.price.buyMax}
                            label={'buy-max-price'}
                            onInputChange={
                                (v) => onDetailChange({
                                    type: AC_SETTING_TYPES.PRICE,
                                    value: v,
                                    key: 'buyMax'
                                })
                            }
                            minWidth='260px'
                            width='50%'
                            currency='usd'
                            number
                        />
                    </Background>
                </Flex>
                <CMargin margin='12px' />
                <Flex fw wrap justify='flex-start'>
                    <Background bg='secondaryBg'>
                        <Input
                            label='sell-min-price'
                            value={coin?.price.sellMin}
                            onInputChange={v => onDetailChange({
                                type: AC_SETTING_TYPES.PRICE,
                                value: v,
                                key: 'sellMin'
                            })}
                            minWidth='260px'
                            width='50%'
                            currency='usd'
                            number
                        />
                        <Input
                            label='sell-max-price'
                            value={coin?.price.sellMax}
                            onInputChange={v => onDetailChange({
                                type: AC_SETTING_TYPES.PRICE,
                                value: v,
                                key: 'sellMax'
                            })}
                            minWidth='260px'
                            width='50%'
                            currency='usd'
                            number
                        />
                    </Background>
                </Flex>
            </CFlex>
        </MotionFade>
    )
}


export default PriceUpdate
