import { useParams } from "react-router-dom"
import ListLayout from "../../../components/layout/main/ListLayout";
import { PageCard } from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout"
import { useGetCoinTrace } from "../../../core/services/react-query/report/trace";
import {CacheKeys, coinTraceFilterOptions, traceSortOptions} from "../../../core/constants/filter";
import { useState } from "react";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { TABLET_SIZE } from "../../../core/constants/common";
import TraceTable from "../../../components/main/reports/trace/TraceTable";
import RTraceTable from "../../../components/responsive/main/reports/trace/RTraceTable";
import {useAclContext} from "../../../core/contexts/acl";


const WagesTrace = () => {

    const { width } = useWindowSize()
    const params = useParams()

    const [ traces, setTraces ] = useState({data: null, loading: true})

    const {permissions} = useAclContext()
    const hasDownload = permissions?.export?.write

    const onQuerySuccess = (res) => {
        setTraces(res)
    }

    const Component = width > TABLET_SIZE ? TraceTable : RTraceTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetCoinTrace}
                    options={coinTraceFilterOptions}
                    onQuerySuccess={onQuerySuccess}
                    cache={CacheKeys.COIN_TRACE}
                    extra={{ currency: params.id }}
                    hasDownload={hasDownload}
                    sortOptions={traceSortOptions}
                >
                    {width &&
                        <Component
                            data={traces}
                        />
                    }

                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}


export default WagesTrace
