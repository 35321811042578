import MotionFade from "../../../common/utils/MotionFade";
import {UserLevelCard} from "../../../../styles/main/setting";
import UserStars from "./UserStars";
import {CFlex, CMargin, DText, Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {LineBreak, MiniLineBreak} from "../../../../styles/main/MainCommonStyles";
import {formatNumber} from "../../../../core/utils/common";


const UserLevelsShow = ({
    userLevels
}) => {


    return (
        <MotionFade>
            <Flex fw wrap>
                {userLevels?.map((level, idx) => (
                    <UserLevelCard>
                        <CFlex fw>
                            <UserStars count={idx + 1}/>
                            <Flex>
                                <DText main style={{ margin: '0 5px' }}>
                                    <Text tid={'user-star'} />
                                </DText>
                                <DText main>
                                    {idx + 1}
                                </DText>
                            </Flex>
                        </CFlex>
                        <LineBreak />
                        <CMargin margin={'10px'} />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'s'}>
                                <Text tid={'user-level-min'} /> :
                            </DText>
                            <DText primary fontSize={'s'}>
                                {formatNumber(level.min)}
                            </DText>
                        </Flex>
                        <CMargin margin={'5px'} />
                        <MiniLineBreak />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'s'}>
                                <Text tid={'user-level-max'} /> :
                            </DText>
                            <DText primary fontSize={'s'}>
                                {formatNumber(level.max)}
                            </DText>
                        </Flex>
                        <CMargin margin={'5px'} />
                        <MiniLineBreak />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'s'}>
                                <Text tid={'user-level-off'} /> :
                            </DText>
                            <DText primary fontSize={'s'}>
                                {formatNumber(level.off)}
                            </DText>
                        </Flex>
                    </UserLevelCard>
                ))}
            </Flex>

        </MotionFade>
    )
}


export default UserLevelsShow
