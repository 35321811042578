import ListLayout from "../../../components/layout/main/ListLayout"
import { PageCard } from "../../../styles/CommonStyles";
import PairsTable from "../../../components/main/setting/pairs/PairsTable";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize"
import { TABLET_SIZE } from "../../../core/constants/common";
import RPairsTable from "../../../components/responsive/main/setting/pairs/RPairsTable";

const Pairs = () => {

    const {width} = useWindowSize()
    const Component = width > TABLET_SIZE ? PairsTable : RPairsTable

    return (
        <ListLayout>
            <PageCard>
                {width && <Component />}
            </PageCard>
        </ListLayout>
    )
}

export default Pairs;
