import useCurrentOrders from "../../../../../core/hooks/main/orders/useCurrentOrders"
import {formatName, formatNumber, getNames} from "../../../../../core/utils/common"
import Text from "../../../../../core/utils/Text"
import { CFlex, DText, Flex, Padding, Row } from "../../../../../styles/CommonStyles"
import CoinMarket from "../../../../common/common/CoinMarket"
import Operation from "../../../../common/utils/Operation"
import RTableLayout from "../../../layout/RTableLayout"
import { BuyIcon, SellIcon, TradeAmount, TradeTypeBadge } from "../../../../../styles/main/orders"
import { getTradeTypeColor } from "../../../../../core/utils/theme"
import UserLink from "../../../../common/utils/UserLink";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";


const RCurrentOrders = ({data}) => {

    const {data: orders} = data

    const {
        hasWriteAccess,
        onDetailsClicked,
        onDeleteClicked,
        modal,
        onModalClose,
        deletingOrder,
        onSubmitClicked
    } = useCurrentOrders()

    return (
        <RTableLayout
            data={data}
        >
            {orders?.data?.map((order, idx) => {

                const {
                    FULLNAME
                } = getNames(
                    order.user?.firstName,
                    order.user?.lastName
                )

                let percent = 0
                if (order.tradedAmount) {
                    percent = (order.tradedAmount / (order.amount + order.tradedAmount)) * 100
                }


                return (
                    <Row key={order._id} cs={'50% 50%'} index={idx} currentOrder percent={percent} type={order.type}>
                        <Padding padding={'8px 12px'}>
                            <CoinMarket coin={order.coin} pair={order.pair} />
                        </Padding>
                        <Flex justify={'flex-end'}>
                            <Operation
                                onDeleteClicked={() => onDeleteClicked(order)}
                                onDetailsClicked={() => onDetailsClicked(order)}
                                hasWriteAccess={hasWriteAccess}
                                deleteTooltip={'delete-order'}
                                hasDetails
                                hasDelete
                            />
                        </Flex>
                        <CFlex align={'flex-start'} justify={'space-around'} height={'160px'}>
                            <DText fontSize={'ss'} main>
                                <Text tid={'fullname'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'amount'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'traded-amount'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'unitPrice'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'price'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'transaction-type'} /> :
                            </DText>
                            <DText fontSize={'ss'} main>
                                <Text tid={'trade-type'} /> :
                            </DText>
                        </CFlex>
                        <CFlex align={'flex-start'} justify={'space-around'} height={'160px'}>
                            <UserLink
                                name={formatName(FULLNAME)}
                                _id={order.userId}
                            />
                            <TradeAmount type={order.type}>
                                {formatNumber(order.amount + order.tradedAmount)}
                            </TradeAmount>
                            <TradeAmount type={order.type}>
                                {formatNumber(order.tradedAmount)}
                            </TradeAmount>
                            <DText primary fontSize={'ss'}>
                                {formatNumber(order.priceUnit)}
                            </DText>
                            <DText primary fontSize={'ss'}>
                                {formatNumber(order.price)}
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(order.submit)}>
                                {order.submit}
                            </TradeTypeBadge>
                            <Flex>
                                {order.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                                <Flex style={{ margin: '0 7px' }}>
                                    <TradeAmount type={order.type}>
                                        <Text tid={order.type} />
                                    </TradeAmount>
                                </Flex>
                            </Flex>
                        </CFlex>
                        <div />
                        <Flex justify='flex-end' style={{marginTop: '8px'}}>
                            <DText main>
                                {idx + 1}
                            </DText>
                        </Flex>

                    </Row>
                )
            })}
            <ModalLayout
                open={modal.open}
                width={'520px'}
                onClose={onModalClose}
            >
                <BasicModal
                    head={'delete-order-head'}
                    desc={'delete-order-desc'}
                    _id={modal.order?._id}
                    onClose={onModalClose}
                    onSubmit={onSubmitClicked}
                    loading={deletingOrder}
                />
            </ModalLayout>
        </RTableLayout>
    )

}

export default RCurrentOrders
