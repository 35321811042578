import {useState} from "react";
import Tabbar from "../../common/tabs/Tabbar";
import MoveToMaster from "./MoveToMaster";
import ResyncAdress from "./ResyncAdress";


const FullnodeActions = () => {

    const [activeTab, setActiveTab] = useState(1)

    return (
        <>
            <Tabbar
                active={activeTab}
                tabs={['move-to-master', 'resync-address']}
                onTabClicked={(idx) => setActiveTab(idx)}
            />
            {activeTab === 1 &&
               <MoveToMaster />
            }
            {activeTab === 2 &&
                <ResyncAdress />
            }
        </>
    )
}

export default FullnodeActions
