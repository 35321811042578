import TableLayout from "../../../../layout/main/TableLayout";
import {Column, Flex, Relative, Row} from "../../../../../styles/CommonStyles";
import {formatName, formatNumber, getNames} from "../../../../../core/utils/common";
import Avatar from "../../../../common/utils/Avatar";
import {BuyIcon, SellIcon, TradeAmount, TradeTypeBadge} from "../../../../../styles/main/orders";
import Text from "../../../../../core/utils/Text";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import DateTime from "../../../../common/utils/DateTime";
import Operation from "../../../../common/utils/Operation";
import ModalLayout from "../../../../layout/main/ModalLayout";
import UserLink from "../../../../common/utils/UserLink";
import useBankTransactions from "../../../../../core/hooks/main/bank-transactions/useBankTransactions";
import TransactionModal from "../../../../modals/TransactionModal";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {bankTransactionAttributes} from "../../../../../core/constants/headers";


const BankTransactionTable = ({
    data
}) => {

    const { data: transactions } = data
    const { cs, headers } = bankTransactionAttributes
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        onDetailsClicked,
        onOptionClicked,
        modal,
        onModalClose,
        getTransactionOptions
    } = useBankTransactions()


    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess={hasWriteAccess}
        >
            {transactions?.data?.map((item, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    item.user?.firstName,
                    item.user?.lastName
                )

                return(
                    <Relative>
                        <Row cs={cs} index={idx} key={item._id}>
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            <Column avatar>
                                <Flex width='42px'>
                                    <Avatar
                                        name={NAMEAVATAR}
                                        avatar={item.user?.avatar}
                                    />
                                </Flex>
                                <UserLink
                                    _id={item.userId}
                                    name={formatName(FULLNAME)}
                                />
                            </Column>
                            <Column>
                                <TradeAmount type={item.flow}>
                                    {formatNumber(item.amount)}
                                </TradeAmount>
                            </Column>

                            <Column>
                                <Flex>
                                    {item.flow === 'deposit' ? <BuyIcon /> : <SellIcon />}
                                    <Flex style={{ margin: '0 7px' }}>
                                        <TradeAmount type={item.flow}>
                                            <Text tid={item.flow} />
                                        </TradeAmount>
                                    </Flex>
                                </Flex>
                            </Column>

                            <Column>
                                <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                                    <Text tid={item.status} />
                                </TradeTypeBadge>
                            </Column>

                            <Column center>
                                <DateTime dt={item.createdAt} />
                            </Column>
                        </Row>
                        <Column operation>
                            <Operation
                                options={getTransactionOptions(item)}
                                onDetailsClicked={() => onDetailsClicked(item)}
                                hasWriteAccess={hasWriteAccess}
                                onOptionClicked={(idx) => onOptionClicked(idx, item)}
                                hasDetails
                            />
                        </Column>

                    </Relative>
                )
            })}
            <ModalLayout
                open={modal.open}
                width={'520px'}
                onClose={onModalClose}
            >
                <TransactionModal
                    details={modal}
                    onClose={onModalClose}
                />
            </ModalLayout>

        </TableLayout>
    )
}

export default BankTransactionTable



