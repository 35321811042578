import {CFlex, CMargin, DetailsLtr, DetailsRtl, DText, Flex, IconWrapper} from "../../../../../styles/CommonStyles";
import {EditIcon, ModeDecoration} from "../../../../../styles/main/setting";
import Text from "../../../../../core/utils/Text";
import Tooltip from "../../../../common/utils/Tooltip";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import {AnimatePresence} from "framer-motion";
import OtcUpdate from "../OtcUpdate";
import OtcShow from "../OtcShow";
import SubmitModal from "../../../../modals/SubmitSettingModal";
import useOtcSetting from "../../../../../core/hooks/main/setting/otc/useOtcSetting";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {useMainContext} from "../../../../../core/contexts/main";


const ThirdOverview = () => {

    const { width } = useWindowSize()
    const { lang } = useMainContext()
    const {
        otcSetting,
        editMode,
        enterEditMode,
        exitEditMode,
        onSubmitChanges,
        updatingOtc,
        onDetailsClicked,
        onDetailsChange,
        onCoinStateChange,
        changeAllSelectedState,
        baseInputError
    } = useOtcSetting()

    return (
        <>
            <CFlex fw>
                <Flex fw justify='space-between'>
                    <Flex>
                        <ModeDecoration />
                        <DText main fontSize='s'>
                            <Text tid='mode' /> :
                        </DText>
                        <DText main style={{ margin: '0 4px' }} fontSize='s'>
                            <Text tid={`${editMode ? 'open' : 'close'}-mode`} />
                        </DText>
                    </Flex>
                    <Flex>
                        <Tooltip
                            content='edit'
                        >
                            <IconWrapper
                                onClick={enterEditMode}
                            >
                                <EditIcon size={24} />
                            </IconWrapper>
                        </Tooltip>
                        {/*<Tooltip*/}
                        {/*    content='details'*/}
                        {/*>*/}
                        {/*    <IconWrapper*/}
                        {/*        onClick={onDetailsClicked}*/}
                        {/*    >*/}
                        {/*        {lang === 'en' ?*/}
                        {/*            <DetailsLtr size={width > TABLET_SIZE ? 24 : 18 } />*/}
                        {/*            :*/}
                        {/*            <DetailsRtl size={width > TABLET_SIZE ? 24 : 18 } />*/}
                        {/*        }*/}
                        {/*    </IconWrapper>*/}
                        {/*</Tooltip>*/}
                    </Flex>
                </Flex>
                <CMargin margin={'10px'} />
                <AnimatePresence exitBeforeEnter>
                    {editMode ?
                        <OtcUpdate
                            data={otcSetting}
                            onDetailsChange={onDetailsChange}
                            onCoinStateChange={onCoinStateChange}
                            changeAllSelectedState={changeAllSelectedState}
                            baseInputError={baseInputError}
                        />
                        :
                        <OtcShow
                            data={otcSetting}
                        />
                    }
                </AnimatePresence>
            </CFlex>
            <SubmitModal
                editMode={editMode}
                onSubmit={onSubmitChanges}
                onCancel={exitEditMode}
                loading={updatingOtc}
            />
        </>
    )
}

export default ThirdOverview
