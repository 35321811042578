import styled from 'styled-components'
import {BsCheck} from 'react-icons/bs'
import { Flex } from '../../../styles/CommonStyles'


const CheckBox = (props) => {


    return (
        <Wrapper {...props}>
            <Flex fw fh>
                {props.active &&
                    <CheckIcon size={24} />
                }
            </Flex>
        </Wrapper>
    )
}


const Wrapper = styled.div`
    width: 20px;
    height: 20px;
    border: 1px solid ${props => props.active ? props.theme.mainGreen : props.theme.color}80;
    border-radius: 2px;
    cursor: pointer;
`

const CheckIcon = styled(BsCheck)`
    color: ${props => props.theme.mainGreen}
`


export default CheckBox
