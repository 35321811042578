import {useEffect, useState} from "react";
import {deepCopy, formatObjectNumbers, makeObjectFormattedNumber} from "../../../../utils/common";
import {useSetAvailableBanks} from "../../../../services/react-query/setting/available-banks";


export const FIAT_SETTING_TYPES = {
    ALL: 'all',
    OTC: 'otc',
    ORDER: 'order',
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw'
}


const useFiatWage = (data) => {

    const { mutate: updateFiat, isLoading: updatingFiat } = useSetAvailableBanks()

    const { loading: fetchingFiat } = data
    const [fiat, setFiat] = useState(null)

    const resetFiat = () => {
        const current = data?.data
        const _fiat = {
            banks: current?.banks,
            otc: makeObjectFormattedNumber(current?.otc),
            order: makeObjectFormattedNumber(current?.order),
            withdraw: makeObjectFormattedNumber(current?.withdraw),
            deposit: makeObjectFormattedNumber(current?.deposit),
        }
        setFiat(_fiat)
    }

    useEffect(() => {
        if (data?.data) resetFiat()
    }, [data])

    const [editMode, setEditMode] = useState('close')

    const initialState = {
        [FIAT_SETTING_TYPES.ORDER]: false,
        [FIAT_SETTING_TYPES.OTC]: false,
        [FIAT_SETTING_TYPES.DEPOSIT]: false,
        [FIAT_SETTING_TYPES.WITHDRAW]: false
    }
    const [editState, setEditState] = useState(initialState)

    const openAllEditBoxes = () => {
        const temp = {}
        Object.keys(initialState).forEach(key => {
            temp[key] = true
        })
        setEditState(temp)
    }

    const enterEditMode = (type) => {
        switch (type) {
            case 'all':
                setEditMode(type)
                openAllEditBoxes()
                break
            default:
                setEditMode('open')
                setEditState(state => ({ ...state, [type]: true }))
        }
    }

    const exitEditMode = () => {
        setEditMode('close')
        setEditState(initialState)
        resetFiat()
    }


    const onDetailChange = (data) => {
        const { type, value, key } = data
        const newFiat = deepCopy(fiat)
        newFiat[type][key] = value
        setFiat(newFiat)
    }

    const onSubmitChanges = () => {
        const newFiat = {}
        newFiat.order = formatObjectNumbers(fiat.order)
        newFiat.otc = formatObjectNumbers(fiat.otc)
        newFiat.deposit = formatObjectNumbers(fiat.deposit)
        newFiat.withdraw = formatObjectNumbers(fiat.withdraw)
        newFiat.banks = fiat.banks

        updateFiat({data: newFiat})
    }

    return {
        fiat,
        editMode,
        editState,
        enterEditMode,
        exitEditMode,
        onSubmitChanges,
        onDetailChange,
        loading: fetchingFiat || updatingFiat
    }
}

export default useFiatWage
