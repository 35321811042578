import {bankWebhookAttributes} from "../../../../../core/constants/headers";
import TableLayout from "../../../../layout/main/TableLayout";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {Column, Row} from "../../../../../styles/CommonStyles";
import {formatNumber} from "../../../../../core/utils/common";
import CopyText from "../../../../common/utils/CopyText";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import DateTime from "../../../../common/utils/DateTime";


const Table = ({ data }) => {

    const { data: webhooks } = data
    const { cs, headers } = bankWebhookAttributes
    const { getTableIndex } = useGetTableIndex()


    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {webhooks?.data?.map((item, idx) => {

                return (
                    <Row key={item._id} cs={cs}>
                        <Column>{getTableIndex(idx)}</Column>
                        <Column>{formatNumber(item.data?.payAmount)}</Column>
                        <Column>{formatNumber(item.data?.wage)}</Column>
                        <Column><CopyText text={item.data?.sourceIban} /></Column>
                        <Column><CopyText text={item.data?.destinationIban} /></Column>
                        <Column><CopyText text={item.payId} /></Column>
                        <Column>
                            <TradeTypeBadge>
                                {item.thirdParty}
                            </TradeTypeBadge>
                        </Column>
                        <Column><CopyText text={item.trackingCode} /></Column>
                        <Column>
                            <DateTime dt={item.payDate} />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default Table
