import {CFlex, CMargin, Flex} from "../../styles/CommonStyles";
import {SkeletonBar, SKeletonText} from "../../core/packages/skeleton/elements";
import Shimmer from "../../core/packages/skeleton/Shimmer";


const DetailSkeleton = () => {


    return (
        <CFlex fw align={'flex-start'}>
            <SKeletonText width={'128px'} big>
                <Shimmer />
            </SKeletonText>
            <Flex fw justify={'space-between'} wrap>
                <CFlex width={'70%'} style={{ minWidth: '300px' }}>
                    <CMargin margin={'10px'} />
                    {[1, 1.2, 1, 1.4].map((item, idx) => (
                        <Flex key={idx} fw justify={'space-between'} style={{ margin: '10px 0' }}>
                            <SKeletonText width={`${item * 28}%`}>
                                <Shimmer />
                            </SKeletonText>
                            <SKeletonText width={`${item * 24}%`}>
                                <Shimmer />
                            </SKeletonText>
                        </Flex>
                    ))}
                </CFlex>
                <CFlex style={{ margin: 'auto' }}>
                    <SkeletonBar />
                    <CMargin margin={'10px'} />
                    <SKeletonText width={'64px'}>
                        <Shimmer />
                    </SKeletonText>
                </CFlex>
            </Flex>
        </CFlex>
    )
}

export default DetailSkeleton
