import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAclContext } from "../../../contexts/acl"
import { useDeleteAffiliate } from "../../../services/react-query/report/trace"



const useAffiliateList = () => {
    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.affiliate?.write

    const modalInitial = { open: false, order: null }
    const [modal, setModal] = useState(modalInitial)

    const onModalClose = () => setModal(modalInitial)

    const { mutate: deleteAffiliate, isLoading, isSuccess } = useDeleteAffiliate()


    const onSubmitClicked = (fromDetail) => {
        deleteAffiliate(modal.affiliate?._id)
        if (fromDetail) {
            navigate('/reports/affiliate-overview')
        }
    }

    useEffect(() => {
        isSuccess && onModalClose()
    }, [isSuccess])


    const onDetailsClicked = (affiliate) => {
        navigate(`/reports/affiliate-overview/${affiliate._id}`)
    }

    const onDeleteClicked = (affiliate) => {
        setModal({
            open: true,
            affiliate
        })
    }

    return {
        hasWriteAccess,
        loading: isLoading,
        onDeleteClicked,
        onDetailsClicked,
        onSubmitClicked,
        modal,
        onModalClose
    }
}


export default useAffiliateList
