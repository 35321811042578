import {useGetWages, useSetWages} from "../../../../services/react-query/setting/wages";
import {useEffect, useState} from "react";
import {deepCopy, formatObjectNumbers, makeObjectFormattedNumber} from "../../../../utils/common";


const useUserLevels = () => {


    const { data: wages } = useGetWages()
    const { mutate: updateUserLevels, isLoading: loading, isSuccess: userLevelsUpdated} = useSetWages()

    const [userLevels, setUserLevels] = useState([])
    const [editMode, setEditMode] = useState(false)

    const getNewLevel = () => ({
        star: userLevels.length,
        min: 0,
        max: 0,
        off: 0
    })

    const resetUserLevels = () => {
        const _userLevels = []
        wages?.stars?.forEach(star => _userLevels.push(makeObjectFormattedNumber(star)))
        setUserLevels(_userLevels)
    }

    const enterEditMode = () => setEditMode(true)
    const exitEditMode = () => {
        setEditMode(false)
        resetUserLevels()
    }

    useEffect(() => {
        if (wages) resetUserLevels()
    }, [wages])

    const onInputValueChange = (value, index, type) => {
        const newUserLevel = deepCopy(userLevels)
        const star = newUserLevel[index]
        if (star) {
            newUserLevel[index][type] = value
        }
        setUserLevels(newUserLevel)
    }

    const addNewLevel = () => {
        const newLevel = getNewLevel()
        const newUserLevels = deepCopy(userLevels)
        newUserLevels.push(newLevel)
        setUserLevels(newUserLevels)
    }

    const deleteUserLevel = (idx) => {
        const newLevels = deepCopy(userLevels)
        newLevels.splice(idx, 1)
        setUserLevels(newLevels)
    }


    const onSubmitChanges = () => {
        const newWages = deepCopy(wages)
        newWages.stars = userLevels.map((lvl, idx) => {
            const newLevel = {...lvl}
            newLevel.star = idx + 1
            return formatObjectNumbers(newLevel)
        })
        updateUserLevels({data: newWages})
    }

    useEffect(() => {
        if (userLevelsUpdated) {
            exitEditMode()
        }
    }, [userLevelsUpdated])

    return {
        userLevels,
        loading,
        editMode,
        enterEditMode,
        exitEditMode,
        onSubmitChanges,
        onInputValueChange,
        addNewLevel,
        deleteUserLevel
    }
}

export default useUserLevels