import { formatNumber } from "../../../../core/utils/common"
import Text from "../../../../core/utils/Text"
import { CMargin, Decoration, DText, Flex } from "../../../../styles/CommonStyles"
import MotionFade from "../../../common/utils/MotionFade"
import SettingRow from "../../../common/common/SettingRow"
import BoundBox from "../available-coins/details/BoundBox"


/**
 * @param detail - { min, max, makerFeeFactor, takerFeeFactor, makerFeeMax, takerFeeMax }
 * @param type - { order - otc }
 * @param currency - { currency of details }
 * @returns {JSX.Element}
 * @constructor
 */
const OrderBox = ({
    detail,
    currency
}) => {


    return (
        <MotionFade>
            {detail &&
                <>
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-min-max-note' />
                        </DText>
                    </Flex>
                    <BoundBox
                        min={detail.min}
                        max={detail.max}
                        currency={currency}
                    />
                    <CMargin margin='8px' />
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-note' />
                        </DText>
                    </Flex>
                    <CMargin margin='8px' />
                    <SettingRow
                        prefix='taker-fee-factor'
                        suffix={`${detail.takerFeeFactor} %`}
                    />
                    <SettingRow
                        prefix={'taker-fee-max'}
                        suffix={formatNumber(detail.takerFeeMax)}
                        coin={currency}
                        priceNumber
                    />
                    <SettingRow
                        prefix='maker-fee-factor'
                        suffix={`${detail.makerFeeFactor} %`}
                    />
                    <SettingRow
                        prefix={'maker-fee-max'}
                        suffix={formatNumber(detail.makerFeeMax)}
                        coin={currency}
                        priceNumber
                    />
                </>
            }

        </MotionFade>
    )
}


export default OrderBox
