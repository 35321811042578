import {
    useGetAvailableBanks,
    useGetDefinedBanks,
    useSetAvailableBanks
} from "../../../../services/react-query/setting/available-banks";
import {useEffect, useState} from "react";
import {deepCopy} from "../../../../utils/common";


const useAvailableBanks = () => {

    const [bankList, setBankList] = useState([])
    const { data: definedBanks } = useGetDefinedBanks()
    const { data: banks, isFetching: loadingBanks} = useGetAvailableBanks()
    const { mutate: setAvailableBanks, isLoading: settingBanks } = useSetAvailableBanks()

    useEffect(() => {
        if (!!banks && !!definedBanks) {
            let newBankList = []
            definedBanks.forEach(b => {
                let newB = deepCopy(b)
                const isThere = banks.banks.find(item => item.symbol === b.symbol)
                newB.isActive = isThere?.isActive || false
                newBankList.push(newB)
            })
            setBankList(newBankList)
        }
    }, [banks, definedBanks])

    const onStatusChange = (idx) => {
        const newData = deepCopy(banks)
        const theBank = bankList[idx]
        if (theBank.isActive)
            newData.banks = newData.banks.filter(item => item.symbol !== theBank.symbol)
        else
            newData.banks.push({...theBank, isActive: true})

        setAvailableBanks({ data: newData })

        // const newBanks = deepCopy(bankList)
        // newBanks[idx].isActive = !newBanks[idx].isActive
        // setBankList(newBanks)

    }

    return {
        bankList,
        loading: loadingBanks || settingBanks,
        onStatusChange
    }
}


export default useAvailableBanks
