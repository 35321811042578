import {CFlex, DText} from "../../../styles/CommonStyles";
import {formatDate} from "../../../core/utils/common";
import {useMainContext} from "../../../core/contexts/main";
import styled from "styled-components";


const DateTime = ({dt}) => {

    const {lang} = useMainContext()

    return (
        <Wrapper>
            <DText main fontSize={'ss'}>
                {formatDate(dt, 'date', lang)}
            </DText>
            <DText main fontSize={'ss'}>
                {formatDate(dt, 'time', lang)}
            </DText>
        </Wrapper>
    )
}

const Wrapper = styled(CFlex)`
  padding: 6px 16px;
  border: 1px solid ${props => props.theme.color}15;
  border-radius: 8px;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
`

export default DateTime
