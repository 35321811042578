import styled, {keyframes} from "styled-components";
import Shimmer from "../../core/packages/skeleton/Shimmer";
import SidebarSkeleton from "./SidebarSkeleton";
import {Background, CFlex, Flex} from "../../styles/CommonStyles";
import {SideHolder, SideWrapper} from '../../styles/layout/sidebar/index'
import { useMainContext } from "../../core/contexts/main";


const MainSkeleton = () => {

    const { sidebar } = useMainContext()
    
    return (    
        <Wrapper>
            <Header>
                <Shimmer />
            </Header>
            <SideHolder>
                <SideWrapper status={sidebar}>
                    <CFlex width='260px' align='flex-start'>
                        <Background bg={'mainBg'}>
                            <SidebarSkeleton />
                            <SidebarSkeleton />
                            <SidebarSkeleton />
                            <SidebarSkeleton />
                        </Background>
                    </CFlex>
                </SideWrapper>
            </SideHolder>
            <Flex fw fh>
                <Image
                    src={require('../../assets/images/exch.png')}
                    alt=' '
                />
            </Flex>
        </Wrapper>

    )
}


const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  direction: ${props => props.theme.english ? 'ltr' : 'rtl'};
`


const Header = styled.div`
  width: 100%;
  height: 80px;
  z-index: 1000000000;
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${props => props.theme.primaryBg};
`


const animation = keyframes`
    0%{
        opacity: 0.1
    }
    100%{
        opacity: 1
    }
`

const Image = styled.img`
    width: 220px;
    animation: ${animation} 2s infinite ease-in-out;
`


export default MainSkeleton
