import {useLocation} from "react-router-dom";
import ListLayout from "../../../components/layout/main/ListLayout";
import {Background, CFlex, CMargin, DText, Flex} from "../../../styles/CommonStyles";
import {SettingCardWrapper} from "../../../styles/main/setting";
import {useGetAToken, useGetBlockchains} from "../../../core/services/react-query/setting/token";
import {useEffect, useState} from "react";
import Text from "../../../core/utils/Text";
import Input from "../../../components/common/input/Input";
import Select from "../../../components/common/dropdown/Select";
import {LineBreak} from "../../../styles/main/MainCommonStyles";
import ActionButton from "../../../components/common/buttons/ActionButton";
import {useQueryContext} from "../../../core/contexts/query";
import ModalLayout from "../../../components/layout/main/ModalLayout";
import AddTokenModal from "../../../components/modals/AddTokenModal";
import Tooltip from "../../../components/common/utils/Tooltip";
import {stringToNumber} from "../../../core/utils/common";



const INPUT_TYPES = {
    SYMBOL: 'symbol',
    DECIMAL: 'decimal',
    CONTRACT: 'contract',
    IS_ACTIVE: 'isActive',
    TOKEN_ICON: 'token-icon'
}

const TokenOperation = () => {

    const {setToast} = useQueryContext()
    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const operationType = search.get('type')
    const EDIT_MODE = operationType === 'edit'
    const tokenId = search.get('tokenId')

    const initialState = {
        symbol: '', decimal: '',
        network: '', blockchain: '',
        name: '', fa: '',
        contract: '', isActive: false
    }
    const [tokenIcon, setTokenIcon] = useState(null)
    const [tokenData, setTokenData] = useState(initialState)
    const [validOperation, setValidOperation] = useState(false)
    const [ step2Modal, setStep2Modal ] = useState(false)

    const { data: blockchains } = useGetBlockchains()
    const { data: token, refetch: getToken } = useGetAToken(tokenId, false)

    useEffect(() => {
        if (tokenId && EDIT_MODE) {
            getToken()
        }else {
            setTokenData(initialState)
        }
    }, [])

    useEffect(() => {
        if (token && EDIT_MODE) setTokenData(token)
    }, [token])

    useEffect(() => {
        const valid =
            !!tokenData.contract &&
                !!tokenData.name &&
                    !!tokenData.decimal
        setValidOperation(valid)
    }, [tokenData])

    const onInputValueChange = (v, type) => {
        let value = v
        if (type === INPUT_TYPES.TOKEN_ICON)
        {
            setTokenIcon(value)
        }
        else
        {
            if (type === INPUT_TYPES.DECIMAL) value = stringToNumber(value)
            setTokenData(state => ({...state, [type]: value}))
        }
    }


    const onBlockchainChange = (idx) => {
        const blockchain = blockchains[idx]
        setTokenData(state => ({
            ...state,
            name: blockchain.name,
            fa: blockchain.fa,
            network: blockchain.networks[0],
            blockchain: blockchain.id
        }))
    }

    const onSubmitChanges = () => {
        if (validOperation) {
            setStep2Modal(true)
        }else {
            setToast({
                isError: true,
                show: true,
                message: 'fill-input-errors'
            })
        }
    }


    return (
        <ListLayout>
            <CFlex fw>
                <SettingCardWrapper>
                    <Flex fw justify={'flex-start'}>
                        <DText main fontSize={'b'}>
                            <Text tid={operationType} />
                        </DText>
                    </Flex>
                    <CFlex fw align={'flex-start'}>
                        <CMargin margin={'15px'} />
                        <DText main>
                            <Text tid={'blockchain'} />
                        </DText>

                        <Flex fw wrap justify={'space-between'}>
                            <Background bg={'secondaryBg'}>
                                <Select
                                    value={tokenData.name}
                                    options={blockchains ? blockchains.map(item => item.name) : []}
                                    onValueChange={onBlockchainChange}
                                    width={'300px'}
                                    placeHolder={'select-blockchain'}
                                />
                                <Tooltip
                                    content={'blockchain-network-desc'}
                                >
                                    <Flex style={{marginTop: '10px'}}>
                                        <Background bg='secondaryBg'>
                                            <Input
                                                value={tokenData.network}
                                                minWidth={'260px'}
                                                width={'300px'}
                                                label={'network'}
                                                disabled
                                                valid={!!tokenData.network}
                                            />
                                        </Background>
                                    </Flex>
                                </Tooltip>
                            </Background>
                        </Flex>

                        <CMargin margin={'4px'} />
                        <LineBreak />
                        <CMargin margin={'4px'} />

                        <Flex fw wrap justify={'space-between'}>
                            <Background bg={'secondaryBg'}>
                                <Input
                                    value={tokenData.contract}
                                    onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.CONTRACT)}
                                    minWidth={'260px'}
                                    width={'600px'}
                                    label={'smart-contract'}
                                    valid={!!tokenData.contract}
                                />
                                <Input
                                    value={tokenData.symbol}
                                    onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.SYMBOL)}
                                    minWidth={'260px'}
                                    width={'300px'}
                                    label={'symbol'}
                                    valid={!!tokenData.symbol}
                                />
                                <Input
                                    value={tokenData.decimal}
                                    onInputChange={(v) => onInputValueChange(v, INPUT_TYPES.DECIMAL)}
                                    minWidth={'260px'}
                                    width={'300px'}
                                    label={'decimal'}
                                    valid={!!tokenData.decimal}
                                    number
                                />
                            </Background>
                        </Flex>

                        <CMargin margin={'4px'} />
                        <LineBreak />
                        <CMargin margin={'14px'} />

                        <Flex fw>
                            <ActionButton
                                active={validOperation}
                                width={'300px'}
                                onClick={onSubmitChanges}
                            >
                                <Text tid={'submit-changes'} />
                            </ActionButton>
                        </Flex>

                    </CFlex>
                </SettingCardWrapper>
                <ModalLayout
                    width={'480px'}
                    open={step2Modal}
                    onClose={() => setStep2Modal(false)}
                >
                    <AddTokenModal
                        tokenData={tokenData}
                        onInputValueChange={onInputValueChange}
                        tokenIcon={tokenIcon}
                        operationType={operationType}
                        onClose={() => setStep2Modal(false)}
                    />
                </ModalLayout>
            </CFlex>
        </ListLayout>
    )
}


export default TokenOperation
