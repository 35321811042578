import {useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";


const PANEL_KEYS = {
    TRADE_STATS: 'trade-stats',
    ORDER_STATS: 'order-stats',
    USER_STATS: 'user-stats'
}


const useGetTradeStats = () => {

    return useQuery(
        PANEL_KEYS.TRADE_STATS, () => normalFetch('panel/trades'),
        {
            select: res => res?.data?.data
        }
    )
}


const useGetOrderStats = () => {

    return useQuery(
        PANEL_KEYS.ORDER_STATS, () => normalFetch('panel/orders'),
        {
            select: res => res?.data?.data
        }
    )
}


const useGetUserStats = () => {

    return useQuery(
        PANEL_KEYS.USER_STATS, () => normalFetch('panel/users'),
        {
            select: res => res?.data?.data
        }
    )
}


export const useDailyRecords = ({ recordFrom, recordTo }) => {

    return useQuery(
        ['daily_records', recordFrom, recordTo], () => filterFetch({ recordFrom, recordTo }, 'dailyRecords'),
        {
            select: res => res?.data?.data
        }
    )
}



export {
    useGetTradeStats,
    useGetOrderStats,
    useGetUserStats
}
