import { useParams } from "react-router-dom"
import { useGetATrace } from "../../../core/services/react-query/report/trace";
import {
    LeftHeadDecoration,
    RightHeadDecoration,
    DetailRow
} from "../../../styles/main/MainCommonStyles";
import {CMargin, DText, Flex} from "../../../styles/CommonStyles";
import { SOCKET_URL } from "../../../core/constants/urls";
import Text from "../../../core/utils/Text";
import { TradeTypeBadge } from "../../../styles/main/orders";
import { getTradeTypeColor } from "../../../core/utils/theme";
import {formatDate, formatName, formatNumber, getNames} from "../../../core/utils/common";
import { useMainContext } from "../../../core/contexts/main";
import {useGetAUser} from "../../../core/services/react-query/user";
import {useEffect} from "react";
import DetailsLayout from "../../../components/layout/main/DetailsLayout";
import Avatar from "../../../components/common/utils/Avatar";
import UserLink from "../../../components/common/utils/UserLink";


const TraceDetails = () => {


    const params = useParams()
    const {lang} = useMainContext()

    const { data: trace, isFetching, isError } = useGetATrace(params.id)
    const { data: user, refetch: getUser } = useGetAUser(trace?.userId)

    useEffect(() => {
        if (trace) {
            getUser()
        }
    }, [trace])

    const { NAMEAVATAR, FULLNAME } = getNames(user?.firstName, user?.lastName)

    return (
        <DetailsLayout
            isFetching={isFetching}
            isError={isError}
        >
            <RightHeadDecoration>
                <Flex>
                    <img
                        src={
                            trace?.currency === 'usd' ?
                                require('../../../assets/images/tooman.png')
                                :
                                SOCKET_URL + `assets/icon/${trace?.currency}.png`
                        }
                        alt={' '}
                        width={'48px'}
                    />
                </Flex>
            </RightHeadDecoration>
            <LeftHeadDecoration left={'10px'}>
                <DText main>
                    {params.id}
                </DText>
            </LeftHeadDecoration>

            {/* content */}
            <DText fontSize={'b'} primary>
                {trace?.currency?.toUpperCase()}
            </DText>

            <DetailRow>
                <DText main>
                    <Text tid={'user'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <Avatar
                        name={NAMEAVATAR}
                        avatar={user?.avatar}
                    />
                    <UserLink
                        name={formatName(FULLNAME)}
                        _id={trace?.userId}
                    />
                </Flex>
            </DetailRow>
            <CMargin margin={'10px'} />
            <DetailRow>
                <DText main>
                    <Text tid={'volume'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary lineBreak={'anywhere'}>
                        {formatNumber(trace?.volume)}
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'wage'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary>
                        {formatNumber(trace?.wage)}
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'cause'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <TradeTypeBadge color={getTradeTypeColor(trace?.cause?.label)}>
                        <Text tid={trace?.cause?.label} />
                    </TradeTypeBadge>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'action'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <TradeTypeBadge color={getTradeTypeColor(trace?.cause?.action)}>
                        <Text tid={trace?.cause?.action ? trace?.cause?.action : trace?.cause?.label} />
                    </TradeTypeBadge>
                </Flex>

            </DetailRow>

            <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                <DText main>
                    {formatDate(trace?.createdAt, 'date', lang)}
                </DText>
                <DText main>
                    {formatDate(trace?.createdAt, 'time', lang)}
                </DText>
            </Flex>

        </DetailsLayout>
    )
}


export default TraceDetails
