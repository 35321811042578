import {useAclContext} from "../../../contexts/acl";
import {useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import {transactionOperations} from "../../../constants/operation";


const useCoinTransactions = () => {

    const params = useParams()
    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.coining?.write

    const modalInitial = {
        action: null,
        open: false,
        transaction: null,
        type: 'coining'
    }
    const [modal, setModal] = useState(modalInitial)

    const onDetailsClicked = (transaction) => {
        if (params.tab)
            navigate(`/users/${params.id}/${params.tab}/${transaction._id}`)
        else
            navigate(`/reports/coin-transactions/${transaction._id}`)

    }

    const onOptionClicked = (idx, transaction) => {
        setModal({
            open: true,
            action: transactionOperations[idx],
            transaction,
            type: 'coining'
        })
    }

    const onModalClose = () => {
        if (modal.open){
            setModal(modalInitial)
        }
    }

    const getTransactionOptions = (transaction) => {
        if (transaction?.status !== 'success' && transaction?.status !== 'rollback' && transaction?.flow === 'withdraw') {
            return transactionOperations
        }
        return []
    }

    return {
        hasWriteAccess,
        onDetailsClicked,
        onOptionClicked,
        modal,
        onModalClose,
        getTransactionOptions
    }
}

export default useCoinTransactions
