import { useEffect } from "react";
import {usePing} from "../../services/react-query/setting";




const useNetwork = () => {

    const { refetch, status } = usePing()

    useEffect(() => {
        const interval = setInterval(
            () => refetch(),
            30000
        )

        return () => interval && clearInterval(interval)
    }, [])

    return status
}


export default useNetwork;
