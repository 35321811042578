import {useEffect, useRef, useState} from "react";
import {AddFilterBtn, SortIcon} from "../../../../styles/layout/filter";
import FilterDropdown from "../FilterDropdown";
import {AnimatePresence} from "framer-motion";
import {useMainContext} from "../../../../core/contexts/main";
import useClickOutside from "../../../../core/hooks/common/useClickOutside";
import SortBox from "./SortBox";
import {useFilterContext} from "../../../../core/contexts/filter";



const AddSort = ({options = []}) => {

    const { lang } = useMainContext()
    const { pagination, cache } = useFilterContext()

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [sortBoxOpen, setSortBoxOpen] = useState(false)
    const [sort, setSort] = useState(null)
    const [selectedSort, setSelectedSort] = useState([])

    const onSortClose = () => {
        setSortBoxOpen(false)
        setDropdownOpen(false)
    }

    const boxRef = useRef()
    useClickOutside(boxRef, onSortClose)

    const onOptionClicked = (idx) => {
        setSortBoxOpen(true)
        setSort(options[idx])
    }

    useEffect(() => {
        if (!cache) return
        const sort = pagination[cache]?.sort
        if (!sort && selectedSort.length) setSelectedSort([])
        if (sort) {
            const sortOption = options.find(c => c.sort === sort)
            setSelectedSort(sortOption.title)
        }
    }, [pagination, cache])

    return (
        <div style={{ position: 'relative'}}>
            <div
                ref={boxRef}
            >
                <Controller
                    onClick={() => setDropdownOpen(state => !state)}
                />
                {dropdownOpen &&
                    <>
                        <FilterDropdown
                            open={dropdownOpen}
                            options={options.map(item => item.title)}
                            onOptionClicked={onOptionClicked}
                            Controller={Controller}
                            minWidth='160px'
                            right={lang === 'fa' && '0'}
                            selected={selectedSort}
                        />
                        <AnimatePresence exitBeforeEnter>
                            {sortBoxOpen &&
                                <SortBox
                                    type={'create'}
                                    sort={sort}
                                    onClose={onSortClose}
                                />
                            }
                        </AnimatePresence>
                    </>
                }
            </div>
        </div>
    )
}


const Controller = (props) => {

    return (
        <AddFilterBtn {...props} style={{ margin: '0 5px' }}>
            <SortIcon size={18}/>
        </AddFilterBtn>
    )
}

export default AddSort
