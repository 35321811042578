import styled from "styled-components";


export const LoginWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
`


export const LoginLabel = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.color};
  margin: 8px 4px;
`


export const LoginBody = styled.div`
  width: 480px;
  border-radius: 12px;
  align-items: flex-start;
  margin: auto;
  display: flex;
  flex-direction: column;
`
