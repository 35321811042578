import Tabbar from "../../../common/tabs/Tabbar";
import {useEffect, useState} from "react";
import ThirdOverview from "./details/ThirdOverview";
import ThirdOrderHistory from "./details/ThirdOrderHistory";
import ThirdWithdrawHistory from "./details/ThirdWithdrawHistory";
import ThirdDepositAddress from "./details/ThirdDepositAddress";
import {useGetConfigItems} from "../../../../core/services/react-query/setting";
import {useAclContext} from "../../../../core/contexts/acl";
import NoAccess from "../../../layout/main/NoAccess";
import ThirdBalances from "./details/ThirdBalances";
import {useSearchParams} from "react-router-dom";
import {useTabHook} from "../../../../core/hooks/common/useTabHook";


const TABS = ['overview', 'balances-tab', 'order-history', 'withdraw-history', 'deposit-addresses']

const OtcView = () => {


    const { data: config } = useGetConfigItems()

    const { permissions } = useAclContext()
    const hasReadAccess = permissions.thirdParty?.read

    const [third, setThird] = useState(null)

    useEffect(() => config && setThird(config.third.default),[config])

    const {
        tab, setTab
    } = useTabHook()

    const comps = [
        ThirdOverview,
        ThirdBalances,
        ThirdOrderHistory,
        ThirdWithdrawHistory,
        ThirdDepositAddress
    ]
    const ActiveComp = comps[tab - 1]

    if (!hasReadAccess) {
        return <NoAccess />
    }

    return (
        <>
            <Tabbar
                active={tab}
                tabs={TABS}
                onTabClicked={setTab}
            />
            <ActiveComp third={third} />
        </>
    )
}


export default OtcView
