import useUserPieStats from "../../../../core/hooks/main/panel/useUserPieStats";
import Text from "../../../../core/utils/Text";
import {ModalHeader} from "../../../../styles/modals";
import {DText, Flex} from "../../../../styles/CommonStyles";
import SettingCard from "../../../layout/main/SettingCard";
import PanelPieChart from "../PanelPieChar";
import UserStatsTable from "./UserStatsTable";


const UserPieCharts = () => {

    const { active, verify, stats, table } = useUserPieStats()

    return (
        <>
            <Flex fw justify={'space-between'} wrap>
                <SettingCard
                    style={{ minWidth: '300px', padding: '10px' }}
                    width={'48%'}
                    noTitle
                    main
                >
                    <ModalHeader style={{ marginBottom: '10px', padding: '8px 0' }}>
                        <DText main>
                            <Text tid={'verified-users'} />
                        </DText>
                    </ModalHeader>
                    <PanelPieChart
                        data={verify}
                    />
                </SettingCard>
                <SettingCard
                    style={{ minWidth: '300px', padding: '10px' }}
                    width={'48%'}
                    noTitle
                    main
                >
                    <ModalHeader style={{ marginBottom: '10px', padding: '8px 0' }}>
                        <DText main>
                            <Text tid={'active-users'} />
                        </DText>
                    </ModalHeader>
                    <PanelPieChart
                        data={active}
                    />
                </SettingCard>
            </Flex>

            <Flex fw justify={'space-between'} align={'stretch'} wrap>
                <SettingCard
                    style={{ minWidth: '300px', padding: '10px' }}
                    width={'48%'}
                    noTitle
                    main
                >
                    <ModalHeader style={{ marginBottom: '10px', padding: '8px 0' }}>
                        <DText main>
                            <Text tid={'user-reference'} />
                        </DText>
                    </ModalHeader>
                    <PanelPieChart
                        data={stats}
                    />
                </SettingCard>
                <SettingCard
                    style={{ minWidth: '300px', padding: '10px' }}
                    width={'48%'}
                    noTitle
                    main
                >
                    <ModalHeader style={{ marginBottom: '10px', padding: '8px 0' }}>
                        <DText main>
                            <Text tid={'user-stats'} />
                        </DText>
                    </ModalHeader>
                    <UserStatsTable
                        data={table}
                    />
                </SettingCard>
            </Flex>


        </>
    )
}



export default UserPieCharts
