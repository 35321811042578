import React from 'react'
import ServerDown from "./ServerDown";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false};
    }

    static getDerivedStateFromError(error) {
        console.log(error, 'error')
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <ServerDown
                    callback={() => this.setState({ hasError: false })}
                />
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary
