import {useAvailableCoins} from "../../../core/services/react-query/setting";
import {Background, CFlex, CMargin, Decoration, DText, Flex, Padding} from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import {useEffect, useState} from "react";
import Select from "../../common/dropdown/Select";
import ABActionButton from "../setting/general/ABActionButton";
import ModalLayout from "../../layout/main/ModalLayout";
import BasicModal from "../../modals/BasicModal";
import {useMoveToMaster} from "../../../core/services/react-query/fullnode";
import Input from "../../common/input/Input";


const MoveToMaster = () => {

    const { mutate: moveToMaster, isLoading, isSuccess } = useMoveToMaster()
    const { data: coins } = useAvailableCoins()

    const [submitModal, setSubmitModal] = useState(false)
    const openSubmitModal = () => setSubmitModal(true)
    const closeSubmitModal = () => setSubmitModal(false)

    const [selectedCoin, setSelectedCoin] = useState(null)
    const [selectedNetwork, setSelectedNetwork] = useState(null)
    const [address, setAddress] = useState('')
    const [networks, setNetworks] = useState(null)

    const onCoinChange = (idx) => {
        const coin = coins[idx]
        const _networks = coin.depositList?.map(item => item.network)
        setSelectedCoin(coin)
        setNetworks(_networks)
        setSelectedNetwork(null)
    }

    const onNetworkChange = (idx) => {
        setSelectedNetwork(networks[idx])
    }

    const onSubmitClicked = () => {
        const payload = {
            symbol: selectedCoin.id,
            network: selectedNetwork
        }
        if (!!address) payload.address = address
        moveToMaster(payload)
    }

    useEffect(() => {
        if (isSuccess) {
            closeSubmitModal()
            setSelectedCoin(null)
            setSelectedNetwork(null)
        }
    }, [isSuccess])

    return (
        <>
            <CFlex fw align={'flex-start'}>
                <CMargin margin={'10px'} />
                <Flex fw justify={'flex-start'}>
                    <Decoration />
                    <DText main>
                        <Text tid={'move-to-master-note'} />
                    </DText>
                </Flex>
                <Padding padding={'10px'}>

                    <CMargin margin={'8px'} />
                    <DText primary>
                        <Text tid={'coin'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <Select
                        options={coins ? coins : []}
                        value={selectedCoin}
                        placeHolder={'select-coin'}
                        onValueChange={onCoinChange}
                        isCoin
                        height={'42px'}
                        width={'300px'}
                    />


                    {networks &&
                        <>
                            <CMargin margin={'10px'} />
                            <DText primary>
                                <Text tid={'network'} /> :
                            </DText>
                            <CMargin margin={'4px'} />
                            <Select
                                options={networks}
                                value={selectedNetwork}
                                placeHolder={'select-network'}
                                onValueChange={onNetworkChange}
                                height={'42px'}
                                width={'300px'}
                            />
                        </>
                    }

                    {selectedCoin &&
                        <>
                            <CMargin margin={'12px'} />
                            <CFlex align={'flex-start'} width={'360px'}>
                                <div style={{ display: 'flex' }}>
                                    <Decoration style={{ marginTop: '10px' }} />
                                    <DText primary style={{ width: '80%' }} fontSize={'s'}>
                                        <Text tid={'address-optional-1'} />
                                    </DText>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <Decoration style={{ marginTop: '10px' }} />
                                    <DText primary style={{ width: '80%' }} fontSize={'s'}>
                                        <Text tid={'address-optional-2'} />
                                    </DText>
                                </div>
                            </CFlex>
                            <CMargin margin={'4px'} />
                            <Background bg={'mainBg'}>
                                <Input
                                    value={address}
                                    onInputChange={v => setAddress(v)}
                                    label={'address-optional'}
                                    height={'42px'}
                                    width={'300px'}
                                />
                            </Background>
                        </>
                    }
                    <ABActionButton
                        style={{ justifyContent: 'flex-start', margin: '40px 10px' }}
                        show={selectedCoin && selectedNetwork}
                        onClick={openSubmitModal}
                        loading={isLoading}
                    />
                </Padding>
            </CFlex>
            <ModalLayout
                open={submitModal}
                onClose={closeSubmitModal}
                width={'580px'}
            >
                <BasicModal
                    head={'move-to-master'}
                    onClose={closeSubmitModal}
                    onSubmit={onSubmitClicked}
                    loading={isLoading}
                />
            </ModalLayout>
        </>
    )
}


export default MoveToMaster
