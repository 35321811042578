import {SettingCardWrapper} from "../../styles/main/setting";
import {Flex} from "../../styles/CommonStyles";
import {SKeletonText} from "../../core/packages/skeleton/elements";
import Shimmer from "../../core/packages/skeleton/Shimmer";


const SettingCardSkeleton = () => {


    return (
        <SettingCardWrapper
            minHeight={'260px'}
            width={'40%'}
        >
            {[1, 2, 3, 4].map((_, idx) => {
                let width = Math.random() * 100
                if (width < 10) {
                    width = 10
                }
                if (width > 45) {
                    width = 45
                }

                return (
                    <Flex key={idx} fw justify={'space-between'} style={{ margin: '20px 0' }}>
                        <SKeletonText width={`${width}%`}>
                            <Shimmer />
                        </SKeletonText>
                        <SKeletonText width={`${width}%`}>
                            <Shimmer />
                        </SKeletonText>
                    </Flex>
                )
            })}
        </SettingCardWrapper>
    )
}

export default SettingCardSkeleton