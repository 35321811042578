import {Background, CFlex, CMargin, DText, Padding} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {forwardRef, useEffect, useState, useImperativeHandle} from "react";
import {useFilterContext} from "../../../../core/contexts/filter";
import Select from "../../../common/dropdown/Select";
import {useAvailableCoins} from "../../../../core/services/react-query/setting";
import {useQueryContext} from "../../../../core/contexts/query";


const CoinFilter = forwardRef((props, ref) => {

    const { setToast } = useQueryContext()

    const { filter, onClose } = props
    const { name, search } = filter
    const { filters: _filters, setFilters, cache } = useFilterContext()
    const filters = _filters[cache]

    const [value, setValue] = useState(null)
    const { data: availableCoins } = useAvailableCoins()

    useEffect(() => {
        const coin = filters[search]
        if (coin && availableCoins) {
            const _coin = availableCoins.find(item => item.id === coin)
            if (_coin) {
                setValue(_coin)
            }
        }
    }, [filters])

    useEffect(() => {
        const hasTooman = availableCoins.find(item => item.id === 'usd')
        if (availableCoins && !hasTooman) {
            availableCoins.push({
                id: 'usd', fa: 'dollar', name: 'USD'
            })
        }
    }, [availableCoins])

    useImperativeHandle(ref, () => ({
        submitFilters: () => {
            if (value) {
                const newState = {...filters}
                newState[search] = value.id
                setFilters(newState)
                onClose()
            }else {
                setToast({
                    show: true, isError: true,
                    message: 'fill-input-errors'
                })
            }
        }
    }))

    const onCoinsChange = (idx) => {
        setValue(availableCoins[idx])
    }

    return (
        <CFlex>
            <Background bg={'primaryBg'}>
                <DText main>
                    <Text tid={`filter-${name}`} />
                </DText>
                <CMargin margin={'8px'} />
                <Padding padding={'10px'}>
                    <Select
                        options={availableCoins ? availableCoins : []}
                        value={value}
                        onValueChange={onCoinsChange}
                        placeHolder={'select'}
                        width={'200px'}
                        height={'42px'}
                        size={'small'}
                        isCoin
                    />
                </Padding>
            </Background>
        </CFlex>
    )
})


export default CoinFilter
