import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import TradesTable from "./TradesTable";
import RTradesTable from "../../../../responsive/main/reports/orders/RTradesTable";


const Trades = (props) => {

    const { width } = useWindowSize()

    const Component = width > TABLET_SIZE ? TradesTable : RTradesTable
    return (
        <>
            {width ?
                <Component {...props} />
                :
                <></>
            }
        </>
    )

}


export default Trades
