import {CFlex, CMargin, DText, Flex, IconWrapper, Row} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {CopyIcon, LineBreak, RespIndex, ShowIcon} from "../../../../styles/main/MainCommonStyles";
import Address from "../../../common/common/Address";
import Tooltip from "../../../common/utils/Tooltip";
import {useCopyToClipboard} from "../../../../core/hooks/common/useCopyToClipboard";
import {useNavigate} from "react-router-dom";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import RTableLayout from "../../layout/RTableLayout";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import CoinRow from "../../../common/common/CoinRow";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import {formatDate} from "../../../../core/utils/common";
import {useMainContext} from "../../../../core/contexts/main";


const RFullnodeListTable = ({ data }) => {
    const {lang} = useMainContext()
    const { getTableIndex } = useGetTableIndex()
    const { data: fullnodeList } = data
    const { copyToClip } = useCopyToClipboard()

    const navigate = useNavigate()
    const onDetailsClicked = (trans) => {
        navigate(`/fullnode/${trans._id}`)
    }

    return (
        <RTableLayout
            data={data}
        >
            {fullnodeList?.data?.map((item, idx) => (
                <Row cs={'100%'} index={idx}>
                    <CFlex fw>
                        <CMargin margin={'6px'} />
                        <Flex fw justify={'space-between'}>
                            <CoinRow coin={{ id: item.symbol, name: item.symbol, fa: item.symbol }} />
                        </Flex>
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'fromAddress'} /> :
                            </DText>
                            <Flex>
                                <Address address={item.fromAddress} />
                                <Tooltip content={item.fromAddress}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(item.fromAddress)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Flex>
                        <LineBreak style={{ margin: '8px 0' }} />
                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'toAddress'} /> :
                            </DText>
                            <Flex>
                                <Address address={item.toAddress} />
                                <Tooltip content={item.toAddress}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(item.toAddress)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Flex>
                        <LineBreak style={{ margin: '8px 0' }} />

                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'network'} /> :
                            </DText>
                            <TradeTypeBadge>
                                {item.network}
                            </TradeTypeBadge>
                        </Flex>
                        <LineBreak style={{ margin: '8px 0' }} />

                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'txHash'} /> :
                            </DText>
                            <Flex>
                                <Address address={item.txHash} />
                                <Tooltip content={item.txHash}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-txHash'}>
                                    <div onClick={() => copyToClip(item.txHash)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Flex>
                        <LineBreak style={{ margin: '8px 0' }} />

                        <Flex fw justify={'space-between'}>
                            <DText main fontSize={'ss'}>
                                <Text tid={'status'} /> :
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(item.cumulative.status)}>
                                {item.cumulative.status}
                            </TradeTypeBadge>
                        </Flex>
                        <LineBreak style={{ margin: '8px 0' }} />
                        <Flex justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'date'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                {`${formatDate(item.createdAt, 'date', lang)}-${formatDate(item.createdAt, 'time', lang)}`}
                            </DText>
                        </Flex>
                    </CFlex>
                    <RespIndex>
                        {getTableIndex(idx)}
                    </RespIndex>
                </Row>
            ))}
        </RTableLayout>
    )
}

export default RFullnodeListTable
