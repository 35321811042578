import styled from "styled-components";


export const PieCharWrapper = styled.div`
  direction: ltr;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    transform: scale(0.6);
  };
`

export const BarCharWrapper = styled.div`
  direction: ltr;
  overflow: auto;
  
`

