import RTableLayout from "../../../layout/RTableLayout";
import useBankTransaction from "../../../../../core/hooks/main/bank-transactions/useBankTransactions";
import {CFlex, CMargin, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import {formatDate, formatName, getNames} from "../../../../../core/utils/common";
import Avatar from "../../../../common/utils/Avatar";
import Operation from "../../../../common/utils/Operation";
import Text from "../../../../../core/utils/Text";
import {BuyIcon, SellIcon, TradeAmount, TradeTypeBadge} from "../../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import {useMainContext} from "../../../../../core/contexts/main";
import ModalLayout from "../../../../layout/main/ModalLayout";
import UserLink from "../../../../common/utils/UserLink";
import {RespIndex} from "../../../../../styles/main/MainCommonStyles";
import TransactionModal from "../../../../modals/TransactionModal";


const RBankTransactionTable = ({
    data
}) => {

    const { lang } = useMainContext()
    const { data: transactions } = data

    const {
        hasWriteAccess,
        onDetailsClicked,
        onOptionClicked,
        modal,
        onModalClose,
        getTransactionOptions
    } = useBankTransaction()


    return (
        <RTableLayout
            data={data}
        >
            {transactions?.data?.map((item, idx) => {

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    item.user?.firstName,
                    item.user?.lastName
                )

                return (
                    <Row cs={'100%'} index={idx} key={item._id}>
                        <Flex justify={'flex-end'}>
                            <Operation
                                options={getTransactionOptions(item)}
                                onDetailsClicked={() => onDetailsClicked(item)}
                                hasWriteAccess={hasWriteAccess}
                                onOptionClicked={(idx) => onOptionClicked(idx, item)}
                                hasDetails
                            />
                        </Flex>
                        <Flex justify={'space-between'}>
                            <CFlex fw>
                                <CMargin margin={'4px'} />
                                <Avatar
                                    avatar={item.user?.avatar}
                                    name={NAMEAVATAR}
                                />
                                <CMargin margin={'6px'} />
                                <UserLink
                                    _id={item.userId}
                                    name={formatName(FULLNAME)}
                                />
                            </CFlex>
                            <CFlex fw>
                                <TradeTypeBadge color={getTradeTypeColor(item.status)}>
                                    <Text tid={item.status} />
                                </TradeTypeBadge>
                                <CMargin margin={'6px'} />
                                <Flex>
                                    {item.flow === 'deposit' ? <BuyIcon /> : <SellIcon />}
                                    <Flex style={{ margin: '0 7px' }}>
                                        <TradeAmount type={item.flow}>
                                            <Text tid={item.flow} />
                                        </TradeAmount>
                                    </Flex>
                                </Flex>
                            </CFlex>
                        </Flex>

                        <CMargin margin={'8px'} />
                        <Flex justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'amount'} /> :
                            </DText>
                            <TradeAmount type={item.flow}>
                                {item.amount}
                            </TradeAmount>
                        </Flex>
                        <Flex justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'date'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                {formatDate(item.createdAt, 'date', lang)}
                            </DText>
                        </Flex>
                        <Flex justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                <Text tid={'time'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                {formatDate(item.createdAt, 'time', lang)}
                            </DText>
                        </Flex>
                        <RespIndex>
                            {idx + 1}
                        </RespIndex>
                    </Row>
                )
            })}
            <ModalLayout
                open={modal.open}
                width={'520px'}
                onClose={onModalClose}
            >
                <TransactionModal
                    details={modal}
                    onClose={onModalClose}
                />
            </ModalLayout>
        </RTableLayout>
    )
}

export default RBankTransactionTable
