import useRobots from "../../../../core/hooks/main/setting/robot/useRobots";
import SettingDetailLayout from "../../../layout/main/SettingDetailLayout";
import {useGetRobotConfig} from "../../../../core/services/react-query/setting/robots";
import SubmitModal from "../../../modals/SubmitSettingModal";
import SettingCard from "../../../layout/main/SettingCard";
import {CFlex, DText, Flex, IconWrapper} from "../../../../styles/CommonStyles";
import {EditIcon, ModeDecoration} from "../../../../styles/main/setting";
import Text from "../../../../core/utils/Text";
import ListLayout from "../../../layout/main/ListLayout";
import {AnimatePresence} from "framer-motion";
import RobotUserUpdate from "./updates/RobotUserUpdate";
import RobotUser from "./details/RobotUser";
import RobotMarketUpdate from "./updates/RobotMarketUpdate";
import RobotMarket from "./details/RobotMarket";


const RobotsView = () => {

    const {
        robot,
        onQuerySuccess,
        editMode,
        enterEditMode,
        exitEditMode,
        onDetailsChange,
        onSubmitChanges,
        loading,
        addNewMarket,
        deleteMarket
    } = useRobots()


    return (
        <SettingDetailLayout
            query={useGetRobotConfig}
            onQuerySuccess={onQuerySuccess}
        >
            <ListLayout>
                <CFlex fw>
                    <Flex fw justify='flex-end'>
                        <ModeDecoration />
                        <DText main fontSize='s'>
                            <Text tid='mode' /> :
                        </DText>
                        <DText main style={{ margin: '0 4px' }} fontSize='s'>
                            <Text tid={`${editMode ? 'open' : 'close'}-mode`} />
                        </DText>
                        {!editMode &&
                            <IconWrapper onClick={enterEditMode}>
                                <EditIcon size={24} />
                            </IconWrapper>
                        }
                    </Flex>
                    <SettingCard
                        minHeight='0'
                        noTitle
                        main
                    >
                        <AnimatePresence exitBeforeEnter>
                            {editMode ?
                                <RobotUserUpdate
                                    robot={robot}
                                    onDetailsChange={onDetailsChange}
                                />
                                :
                                <RobotUser
                                    robot={robot}
                                />
                            }
                        </AnimatePresence>
                    </SettingCard>

                    {robot?.data?.map((market, index) => (
                        editMode ?
                            <RobotMarketUpdate
                                index={index}
                                market={market}
                                onDetailsChange={onDetailsChange}
                                deleteMarket={deleteMarket}
                            />
                            :
                            <RobotMarket
                                market={market}
                            />
                    ))}

                    {editMode &&
                        <SettingCard
                            minHeight={'0'}
                            main
                            noTitle
                        >
                            <Flex fw fh>
                                <IconWrapper>
                                    <DText main onClick={addNewMarket}>
                                        <Text tid={'add-market'} />
                                    </DText>
                                </IconWrapper>
                            </Flex>
                        </SettingCard>
                    }

                </CFlex>

            </ListLayout>


            <SubmitModal
                editMode={editMode}
                onSubmit={onSubmitChanges}
                onCancel={exitEditMode}
                loading={loading}
            />
        </SettingDetailLayout>
    )
}

export default RobotsView
