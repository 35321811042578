import {bankDepositAccountsAttributes} from "../../../../../core/constants/headers";
import TableLayout from "../../../../layout/main/TableLayout";
import {Column, Row} from "../../../../../styles/CommonStyles";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import UserLink from "../../../../common/utils/UserLink";
import CopyText from "../../../../common/utils/CopyText";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import Operation from "../../../../common/utils/Operation";
import {useNavigate} from "react-router-dom";


const Table = ({ data }) => {

    const { data: accounts } = data
    const { cs, headers } = bankDepositAccountsAttributes
    const { getTableIndex } = useGetTableIndex()

    const navigate = useNavigate()
    const onDetailsClicked = (item) => {
        navigate(`/reports/bank-transactions/accounts/${item._id}`)
    }

    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {accounts?.data?.map((item, idx) => {

                return (
                    <Row cs={cs} key={item._id}>
                        <Column>{getTableIndex(idx)}</Column>
                        <Column>
                            <UserLink _id={item.userId} name={`${item.user?.firstName} ${item.user?.lastName}`} />
                        </Column>
                        <Column>
                            <CopyText text={item.ip} />
                        </Column>
                        <Column>
                            <TradeTypeBadge>
                                {item.thirdParty}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(item)}
                                hasDetails
                            />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default Table
