import {useParams} from "react-router-dom";
import {useMainContext} from "../../core/contexts/main";
import ObjectView from "../../components/common/utils/ObjectView";
import {Flex} from "../../styles/CommonStyles";
import {useGetAFullnodeItem} from "../../core/services/react-query/fullnode";


const FullnodeDetails = () => {

    const { theme } = useMainContext()
    const params = useParams()
    const id = params.id

    const { data: transaction } = useGetAFullnodeItem(id)

    return (
        <Flex fw height={'100vh'}>
            <ObjectView obj={transaction} theme={theme} />
        </Flex>
    )
}

export default FullnodeDetails
