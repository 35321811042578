import {DText, Flex} from "../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../core/constants/urls";
import {useMainContext} from "../../../core/contexts/main";



const CoinRow = ({coin}) => {

    const {lang} = useMainContext()

    return (
        <Flex justify={'flex-start'}>

            <img
                src={
                    coin?.id === 'usd' ?
                        require('../../../assets/images/tooman.png')
                        :
                        SOCKET_URL + `assets/icon/${coin?.id}.png`
                }
                width='28px'
                alt={' '}
                style={{ margin: '0 5px' }}
            />
            <DText main style={{ margin: '0 5px' }} fontSize={'s'}>
                {lang === 'fa' ? coin?.fa : coin?.name}
            </DText>

        </Flex>
    )
}




export default CoinRow
