import MotionFade from "../../../common/utils/MotionFade";
import SettingRow from "../../../common/common/SettingRow";
import {CFlex, CMargin, DText, Flex} from "../../../../styles/CommonStyles";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import Text from "../../../../core/utils/Text";
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import {MOBILE_SIZE} from "../../../../core/constants/common";
import CoinTag from "./elements/CoinTag";


const OtcShow = ({
    data
}) => {

    const { width } = useWindowSize()

    return (
        <MotionFade>
            <CFlex width={'340px'}>
                <SettingRow
                    prefix={'default'}
                    suffix={data?.third?.default}
                    size={width < MOBILE_SIZE && 'small'}
                />
                <SettingRow
                    prefix={'otc-base'}
                    suffix={data?.third?.base}
                    size={width < MOBILE_SIZE && 'small'}
                />
                <SettingRow
                    size={width < MOBILE_SIZE && 'small'}
                    prefix={'active-transactions'}
                    suffix={
                        <Flex>
                            {(data?.third?.on === 'both' || data?.third?.on === 'buy') &&
                                <TradeTypeBadge color={getTradeTypeColor('success')}>
                                    <Text tid={'buy'} />
                                </TradeTypeBadge>
                            }
                            {(data?.third?.on === 'both' || data?.third?.on === 'sell') &&
                                <TradeTypeBadge style={{ margin: '0 2px' }} color={getTradeTypeColor('error')}>
                                    <Text tid={'sell'} />
                                </TradeTypeBadge>
                            }
                        </Flex>
                    }
                />
            </CFlex>
            <CMargin margin={'8px'} />
            <DText main>
                <Text tid={'active-coins'} /> :
            </DText>
            <CMargin margin={'8px'} />
            <Flex fw wrap justify={'flex-start'}>
                {data?.third?.coins.map(coin => (
                    <CoinTag
                        coin={coin}
                    />
                ))}
            </Flex>

        </MotionFade>
    )
}

export default OtcShow
