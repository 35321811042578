import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAclContext } from "../../../contexts/acl"
import { useAvailableCoins } from "../../../services/react-query/setting"
import { useGetAffiliateTrace } from "../../../services/react-query/report/trace"



const useAffiliateTrace = () => {

    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.trace?.write

    const {data: availableCoins} = useAvailableCoins()
    const {data: tracesList, isFetching: loading} = useGetAffiliateTrace()

    const [coin, setCoin] = useState(null)
    const [traces, setTraces] = useState([])

    useEffect(() => {
        const hasTooman = availableCoins?.find(item => item.id === 'usd')
        if (availableCoins && !hasTooman) {
            availableCoins.push({
                id: 'usd', fa: 'dollar', name: 'USD'
            })
        }
    }, [availableCoins])

    useEffect(() => {
        if (coin) {
            setTraces(tracesList?.data?.filter(item => item.currency === coin.id))
        }else {
            setTraces(tracesList?.data)
        }
    }, [coin, tracesList])


    const onCoinChange = (idx) => {
        setCoin(availableCoins[idx])
    }

    const onDetailsClicked = (affiliate) => {
        navigate(`/reports/affiliate-overview/${affiliate?.currency}?type=trace`)
    }

    return {
        hasWriteAccess,
        traces,
        tracesList,
        availableCoins,
        coin,
        onCoinChange,
        onDetailsClicked,
        loading
    }

}



export default useAffiliateTrace
