import {useGetWages, useSetWages} from "../../../../../core/services/react-query/setting/wages";
import {MOBILE_SIZE} from "../../../../../core/constants/common";
import ABActionButton from "../ABActionButton";
import {Absolute, Background, CFlex, CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import {useState, useEffect} from "react";
import {deepCopy, formatObjectNumbers} from "../../../../../core/utils/common";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import MotionFade from "../../../../common/utils/MotionFade";
import Text from "../../../../../core/utils/Text";
import Input from "../../../../common/input/Input";


const VolumeFactor = () => {

    const { width } = useWindowSize()
    const { data: wages } = useGetWages()
    const { mutate: updateWages, isLoading: updatingWages } = useSetWages()

    const [ detailsChanged, setDetailsChanged ] = useState(false)
    const [volumeData, setVolumeData] = useState(null)
    const onInputValueChange = (v, type) => {
        setVolumeData(state => ({
            ...state,
            [type]: v
        }))
    }

    const checkChanges = () => {
        const cache = wages?.volume
        const changed =
            cache?.amountFactor != volumeData?.amountFactor ||
            cache?.valueFactor != volumeData?.valueFactor ||
            cache?.countFactor != volumeData?.countFactor
        setDetailsChanged(changed)
    }
    useEffect(() => {
        checkChanges()
    }, [volumeData])

    useEffect(() => {
        wages && setVolumeData(deepCopy(wages.volume))
    }, [wages])

    const onSubmitClicked = () => {
        const newWages = deepCopy(wages)
        newWages.volume = formatObjectNumbers(volumeData)
        updateWages({data: newWages})
    }

    return (
        <MotionFade>
            <CMargin margin={'30px'} />
            <Flex fw justify={'flex-start'}>
                <Decoration />
                <DText main>
                    <Text tid={'volume-setting-note'} />
                </DText>
            </Flex>
            <CMargin margin={'20px'} />
            <CFlex fw align={'flex-start'}>
                <Background bg={'mainBg'}>
                    <Input
                        value={volumeData?.amountFactor}
                        onInputChange={(v) => onInputValueChange(v, 'amountFactor')}
                        label={'amount-factor'}
                        width={'300px'}
                        code
                    />
                    <Input
                        value={volumeData?.valueFactor}
                        onInputChange={(v) => onInputValueChange(v, 'valueFactor')}
                        label={'value-factor'}
                        width={'300px'}
                        code
                    />
                    <Input
                        value={volumeData?.countFactor}
                        onInputChange={(v) => onInputValueChange(v, 'countFactor')}
                        label={'count-factor'}
                        width={'300px'}
                        code
                    />
                </Background>
            </CFlex>
            {width > MOBILE_SIZE ?
                <ABActionButton
                    show={detailsChanged}
                    onClick={onSubmitClicked}
                    loading={updatingWages}
                />
                :
                <Absolute style={{ position: 'fixed' }} left={0} bottom={0} height={'60px'}>
                    <ABActionButton
                        show={detailsChanged}
                        onClick={onSubmitClicked}
                        loading={updatingWages}
                    />
                </Absolute>
            }
        </MotionFade>
    )
}

export default VolumeFactor
