import {useUsersQuery} from "../../core/services/react-query/user";
import {CacheKeys, userFilterOptions, userSortOptions} from "../../core/constants/filter";
import FilterLayout from "../../components/layout/filter/FilterLayout";
import {useAclContext} from "../../core/contexts/acl";
import {useState} from "react";
import {useWindowSize} from "../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../core/constants/common";
import UsersTable from "../../components/main/users/UsersTable";
import RUserTable from "../../components/responsive/main/users/RUserTable";


const NotVerifiedUsers = () => {

    const { width } = useWindowSize()
    const {permissions} = useAclContext()
    const hasDownloadAccess = permissions?.export?.write
    const [ users, setUsers ] = useState({data: null, loading: true})

    const onUserQuery = (res) => {
        setUsers(res)
    }
    const Component = width > TABLET_SIZE ? UsersTable : RUserTable

    return (
        <FilterLayout
            query={useUsersQuery}
            options={userFilterOptions}
            onQuerySuccess={onUserQuery}
            cache={CacheKeys.USER}
            hasDownload={hasDownloadAccess}
            sortOptions={userSortOptions}
            extra={{ status: 'PROCESSING' }}
        >
            {width &&
                <Component
                    data={users}
                />
            }

        </FilterLayout>
    )
}

export default NotVerifiedUsers
