import {useGetCurrentOrders} from "../../../../core/services/react-query/report/orders";
import {CacheKeys, orderFilterOptions} from "../../../../core/constants/filter";
import {useState} from "react";
import FilterLayout from "../../../layout/filter/FilterLayout";
import {useParams} from "react-router-dom";
import Orders from "../../reports/all-orders/currentOrders/Orders";
import {UserDetailListWrapper} from "../../../../styles/main/user";
import {useAclContext} from "../../../../core/contexts/acl";


const UserCurrentOrders = () => {

    const params = useParams()
    const [orders, setOrders] = useState({loading: true, data: null})
    const { permissions } = useAclContext()
    const hasDownload = permissions?.export?.write
    const onQuerySuccess = (res) => {
        setOrders(res)
    }

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useGetCurrentOrders}
                options={orderFilterOptions}
                onQuerySuccess={onQuerySuccess}
                cache={CacheKeys.USER_ORDERS}
                extra={{ isDelete: false, userId: params.id }}
                hasDownload={hasDownload}
            >
                <Orders
                    data={orders}
                />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}

export default UserCurrentOrders
