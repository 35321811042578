import { CFlex } from "../../../../../styles/CommonStyles"
import MotionFade from "../../../../common/utils/MotionFade"
import SettingRow from "../../../../common/common/SettingRow"


const DetailBox = ({
    coin
}) => {

    return (
        <MotionFade>
            <CFlex align='flex-start'>
                <SettingRow
                    prefix={'coin'}
                    suffix={coin?.id}
                    isCoin
                />

                <SettingRow
                    prefix={'fa-name'}
                    suffix={coin?.fa}
                />

                <SettingRow
                    prefix={'en-name'}
                    suffix={coin?.name}
                />

                {/*<SettingRow*/}
                {/*    prefix={'scanner'}*/}
                {/*    suffix={coin?.scanner}*/}
                {/*    size='small'*/}
                {/*/>*/}

                <SettingRow
                    prefix='is-active'
                    suffix={coin?.isActive}
                    isRadio
                />

                <SettingRow
                    prefix='is-static'
                    suffix={coin?.isStatic}
                    isRadio
                />
            </CFlex>
        </MotionFade>
    )
}


export default DetailBox
