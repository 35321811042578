import { useLocation, useParams } from 'react-router-dom'
import PageNotFound from '../../../components/layout/main/PageNotFound'
import CurrentOrderDetails from '../../../components/main/reports/all-orders/currentOrders/CurrentOrderDetails'
import OtcTradeDetails from '../../../components/main/reports/all-orders/otc/OtcTradeDetails'
import TradeDetails from '../../../components/main/reports/all-orders/trades/TradeDetails'



const OrdersDetails = () => {

    const params = useParams()
    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const type = search.get('type')


    const Component = type === 'order' ? CurrentOrderDetails
        : (type === 'trade' ? TradeDetails
            : OtcTradeDetails
        )

    return (
        <>
            {(params.detailId && type) ?
                <Component />
                :
                <PageNotFound />
            }
        </>

    )
}

export default OrdersDetails
