import MotionFade from "../../../../common/utils/MotionFade";
import {Background, CFlex, CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import Input from "../../../../common/input/Input";
import Text from "../../../../../core/utils/Text";


const TransactionUpdate = ({
    detail,
    type,
    onDetailChange
}) => {


    return (
        <MotionFade>
            {detail &&
                <CFlex fw align='flex-start'>
                    <CMargin margin='4px' />
                    <Flex fw wrap justify='flex-start'>
                        <Background bg='secondaryBg'>
                            <Input
                                value={detail.min}
                                label={`${type}-min`}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'min'
                                })}
                                minWidth='260px'
                                width='50%'
                                currency={'usd'}
                                number
                            />
                            <Input
                                value={detail.max}
                                label={`${type}-max`}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'max'
                                })}
                                minWidth='260px'
                                width='50%'
                                currency={'usd'}
                                number
                            />
                        </Background>
                    </Flex>
                    <CMargin margin='4px' />
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-note' />
                        </DText>
                    </Flex>
                    <CMargin margin='10px' />
                    <Flex fw wrap justify='flex-start'>
                        <Background bg='secondaryBg'>
                            <Input
                                value={detail.feeFactor}
                                label={`${type}-fee-factor`}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'feeFactor'
                                })}
                                minWidth='260px'
                                width='50%'
                                number
                            />
                            <Input
                                value={detail.feeMax}
                                label={`${type}-fee-max`}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'feeMax'
                                })}
                                minWidth='260px'
                                width='50%'
                                currency={'usd'}
                                number
                            />
                        </Background>
                    </Flex>
                </CFlex>
            }
        </MotionFade>
    )
}


export default TransactionUpdate
