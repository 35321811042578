import {createContext, useContext, useEffect} from "react";
import {useAccessLevels} from "../services/acl/useAccessLevels";
import {initialAcl} from "../constants/acl";


const Context = createContext(initialAcl)

export const useAclContext = () => {
    return useContext(Context)
}

const AclContextProvider = ({children}) => {

    const accessLevels = useAccessLevels()

    return (
        <Context.Provider value={accessLevels}>
            {children}
        </Context.Provider>
    )
}

export default AclContextProvider;
