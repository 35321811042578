import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import FilterLayout from "../../../../layout/filter/FilterLayout";
import {
    useGetDepositBankAccounts,
} from "../../../../../core/services/react-query/report/banking";
import {CacheKeys, depositBankAccountOptions} from "../../../../../core/constants/filter";
import Table from "./Table";
import RTable from "./RTable";


const DepositBankAccounts = () => {

    const { width } = useWindowSize()
    const [webhooks, setWebhooks] =  useState({data: null, loading: true})

    const onQuerySuccess = (res) => {
        setWebhooks(res)
    }

    const ActiveComp = width > TABLET_SIZE ? Table : RTable

    return (
        <FilterLayout
            query={useGetDepositBankAccounts}
            onQuerySuccess={onQuerySuccess}
            options={depositBankAccountOptions}
            cache={CacheKeys.DEPOSIT_BANK_ACCOUNTS}
        >
            <ActiveComp data={webhooks} />
        </FilterLayout>
    )
}

export default DepositBankAccounts
