import {useNavigate, useParams} from "react-router-dom";
import {useAclContext} from "../../../contexts/acl";


const useTrades = () => {

    const params = useParams()
    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.trade?.write

    const onDetailsClicked = (trade) => {
        if (params.tab)
            navigate(`/users/${params.id}/${params.tab}/${trade._id}?type=trade`)
        else
            navigate(`/reports/orders/${trade._id}?type=trade`)
    }

    return {
        hasWriteAccess,
        onDetailsClicked
    }
}

export default useTrades
