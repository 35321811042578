import {useAvailableCoins} from "../../../../core/services/react-query/setting";
import {
    useAddCategory,
    useGetCoinCategories,
    useUpdateCategory
} from "../../../../core/services/react-query/setting/coin-list";
import {CFlex, DText, Flex} from "../../../../styles/CommonStyles";
import styled from "styled-components";
import {SOCKET_URL} from "../../../../core/constants/urls";
import {useEffect, useRef, useState} from "react";
import {deepCopy} from "../../../../core/utils/common";
import ABActionButton from "../general/ABActionButton";


const NAME = 'محبوب ترین'
const FavoriteCoins = () => {

    const { data: availableCoins } = useAvailableCoins()
    const { data: categories, isLoading: categoriesLoading } = useGetCoinCategories()

    const { mutate: addCategory, isLoading: createCategoryLoading } = useAddCategory()
    const { mutate: updateCategory, isLoading: updateCategoryLoading } = useUpdateCategory()

    const [favs, setFavs] = useState([])
    useEffect(() => {
        if (!!categories && !!availableCoins) {
            let temp = []
            const isCreated = categories.find(c => c.name === NAME)
            if (isCreated) {
                isCreated.symbols.forEach(s => {
                    const c = availableCoins.find(a => a.id === s)
                    temp.push(c)
                })
            }else
                temp = deepCopy(availableCoins)

            setFavs(temp)
        }
    }, [availableCoins, categories])

    const dragItem = useRef(null)
    const dragOver = useRef(null)
    const handleSort = () => {
        let newFavs = deepCopy(favs)
        const draggedItem = newFavs.splice(dragItem.current, 1)[0]
        newFavs.splice(dragOver.current, 0, draggedItem)
        dragItem.current = null
        dragOver.current = null
        setFavs(newFavs)
    }

    const onSubmitClicked = () => {
        const isCreated = categories.find(c => c.name === NAME)
        const payload = {
            name: NAME,
            icon: '  ',
            symbols: favs.map(f => f.id)
        }
        if (isCreated) {
            updateCategory({ id: isCreated?._id, payload })
        }else {
            addCategory(payload)
        }
    }

    return (
        <CFlex fw style={{ gap: 10, padding: '12px' }} align={'flex-start'}>
            {favs?.map((item, idx) => {

                return (
                    <Wrapper
                        draggable
                        onDragStart={() => dragItem.current = idx}
                        onDragEnter={() => dragOver.current = idx}
                        onDragEnd={handleSort}
                    >
                        <Flex fw fh justify={'flex-start'} style={{ gap: 10 }}>
                            <DText main>{idx+1}-</DText>
                            <img
                                src={SOCKET_URL + `assets/icon/${item.id}.png`}
                                alt={' '}
                                width={'28px'}
                            />
                            <DText main>
                                {item.name}
                            </DText>
                        </Flex>
                    </Wrapper>
                )
            })}
            <ABActionButton
                show
                onClick={onSubmitClicked}
                loading={createCategoryLoading || updateCategoryLoading}
            />
        </CFlex>
    )
}

const Wrapper = styled.div`
  width: 300px;
  border-radius: 8px;
  background-color: ${props => props.theme.secondaryBg};
  padding: 8px 16px;
  cursor: move;
`

export default FavoriteCoins
