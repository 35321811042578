import { useEffect, useState } from "react"
import { useAclContext } from "../../../../contexts/acl"
import { useQueryContext } from "../../../../contexts/query"
import { useGetPairs, useSetPairs } from "../../../../services/react-query/setting/pairs"
import { deepCopy } from "../../../../utils/common"


const usePairsSetting = () => {

    const { setToast } = useQueryContext()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.setting?.write
    
    const { data: pairs, isFetching: loading } = useGetPairs()
    const { mutate: updatePairs, isLoading: updatingPairs, isSuccess: pairsUpdated } = useSetPairs()

    const [addModal, setAddModal] = useState(false)

    const deleteModalInitial = {pair: null, open: false}
    const [deleteModal, setDeleteModal] = useState(deleteModalInitial)

    const enterAddMode = () => setAddModal(true)
    const exitAddMode = () => setAddModal(false)

    const onAddPair = (coin) => {
        if (!!coin) {
            const pair = { 
                id: coin.id, symbol: coin.id, 
                fa: coin.fa, name: coin.name 
            }
            const newPairs = deepCopy(pairs.data)
            newPairs.push(pair)
            updatePairs({data: newPairs})
        }else {
            setToast({
                isError: true, show: true,
                message: 'fill-input-errors'
            })
        }
    }

    const enterDeleteMode = (pair) => setDeleteModal({pair, open: true})
    const exitDeleteMode = () => {
        setDeleteModal(deleteModalInitial)
    }

    const onDeletePair = () => {
        const pair = deleteModal.pair
        const newPairs = pairs?.data?.filter(item => item.id !== pair.id)
        updatePairs({data: newPairs})
    }

    useEffect(() => {
        if (pairsUpdated) {
            exitDeleteMode()
            exitAddMode()
        }
    }, [pairsUpdated])

    return {
        pairs,
        hasWriteAccess,
        loading,
        addModal,
        enterAddMode,
        exitAddMode,
        onAddPair, 
        deleteModal,
        enterDeleteMode,
        exitDeleteMode,
        onDeletePair,
        updatingPairs
    }
}


export default usePairsSetting