import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import Tabbar from "../../../components/common/tabs/Tabbar";
import {useTabHook} from "../../../core/hooks/common/useTabHook";
import BankTransactionComp from "../../../components/main/reports/bank-transactions/bankTransactions/BankTransactionComp";
import BankWebHooks from "../../../components/main/reports/bank-transactions/webhooks/BankWebHooks";
import DepositBankAccounts from "../../../components/main/reports/bank-transactions/depositBankAccounts/DepositBankAccounts";


const TABS = ['transactions', 'webhooks', 'accounts']

const BankTransaction = () => {

    const { tab, setTab } = useTabHook()

    const comps = [
        BankTransactionComp,
        BankWebHooks,
        DepositBankAccounts
    ]
    const ActiveComp = comps[tab-1]

    return (
        <ListLayout>
            <PageCard>
                <Tabbar
                    active={tab}
                    tabs={TABS}
                    onTabClicked={setTab}
                />
                <ActiveComp />
            </PageCard>
        </ListLayout>
    )
}

export default BankTransaction;
