import {AnimatePresence, motion} from "framer-motion";
import {Flex} from "../../../../styles/CommonStyles";
import ActionButton from "../../../common/buttons/ActionButton";
import Text from "../../../../core/utils/Text";


const ABActionButton = ({ onClick, loading, show, ...rest }) => {

    return (
        <AnimatePresence exitBeforeEnter>
            {show &&
                <Flex fw style={{ marginTop: '40px' }} {...rest}>
                    <motion.div
                        variants={abVariants}
                        initial={'out'}
                        exit={'out'}
                        animate={'in'}
                    >
                        <ActionButton
                            width={'280px'}
                            height={'42px'}
                            onClick={onClick}
                            loading={loading}
                            active
                        >
                            <Text tid={'submit-changes'} />
                        </ActionButton>
                    </motion.div>
                </Flex>
            }
        </AnimatePresence>
    )
}


const abVariants = {
    in: {
        x: 0, opacity: 1, transition: {duration: 0.275}
    },
    out: {
        x: '-100%', opacity: 0, transition: {duration: 0.275}
    }
}

export default ABActionButton
