import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";
import {postApi} from "../../fetch-api/post";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";


export const coinListKeys = {
    getCoinCategories: 'getCoinCategories',
    addCategory: 'addCategory',
    updateCategory: 'updateCategory'
}

export const useGetCoinCategories = () => {

    return useQuery(
        coinListKeys.getCoinCategories, () => normalFetch('cryptoCategories/all'),
        {
            select: res => res?.data?.data
        }
    )
}

export const useAddCategory = (onSuccess = () => {}) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        coinListKeys.addCategory, (payload) => postApi(payload, 'cryptoCategories'),
        {
            onSuccess: () => {
                setToast({ message: 'category-added-success', show: true })
                queryClient.invalidateQueries([coinListKeys.getCoinCategories])
                onSuccess()
            }
        }
    )
}


export const useUpdateCategory = (onSuccess = () => {}) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        coinListKeys.updateCategory, ({payload, id}) => putApi(payload, `cryptoCategories/${id}`),
        {
            onSuccess: () => {
                setToast({ message: 'category-updated-success', show: true })
                queryClient.invalidateQueries([coinListKeys.getCoinCategories])
                onSuccess()
            }
        }
    )
}
