import RTableLayout from "../../../../layout/RTableLayout";
import useAvailableBanks from "../../../../../../core/hooks/main/setting/general/useAvailableBanks";
import {Absolute, CFlex, CMargin, Row} from "../../../../../../styles/CommonStyles";
import SettingRow from "../../../../../common/common/SettingRow";
import Toggle from "../../../../../common/buttons/Toggle";
import ABActionButton from "../../../../../main/setting/general/ABActionButton";


const RAvailableBanksTable = () => {

    const {
        bankList,
        loading,
        onStatusChange,
        detailsChanged,
        onSubmitClicked
    } = useAvailableBanks()

    return (
        <>
            <RTableLayout
                data={{ data: bankList, loading}}
                hasPagination={false}
            >
                {bankList.map((bank, idx) => (

                    <Row cs={'100%'} index={idx}>
                        <CFlex fw>
                            <SettingRow
                                prefix={'symbol'}
                                suffix={bank.symbol}
                                size={'small'}
                            />
                            <SettingRow
                                prefix={'en-name'}
                                suffix={bank.name}
                                size={'small'}
                            />
                            <SettingRow
                                prefix={'fa-name'}
                                suffix={bank.fa}
                                size={'small'}
                            />
                            <SettingRow
                                prefix={'status'}
                                suffix={
                                    <Toggle
                                        onChange={() => onStatusChange(idx)}
                                        active={bank.isActive}
                                    />
                                }
                            />
                        </CFlex>
                    </Row>
                ))}
            </RTableLayout>
            <CMargin margin={'80px'} />
            <Absolute style={{ position: 'fixed' }} left={0} bottom={0} height={'60px'}>
                <ABActionButton
                    show={detailsChanged}
                    onClick={onSubmitClicked}
                    loading={loading}
                />
            </Absolute>
        </>
    )
}


export default RAvailableBanksTable
