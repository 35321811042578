import { useMainContext } from '../../../../../core/contexts/main'
import { CFlex, CMargin, DText, Flex } from '../../../../../styles/CommonStyles'
import Text from '../../../../../core/utils/Text'
import Select from '../../../../common/dropdown/Select'
import {
	useGetConfigItems,
	useGetThirdConfig,
	useSetConfigItems,
} from '../../../../../core/services/react-query/setting'
import { deepCopy } from '../../../../../core/utils/common'
import Loader from '../../../../common/loading/Loader'

const GeneralSettings = () => {
	const { lang, setLang, theme, setTheme } = useMainContext()

	const { mutate: setConfigItems, isLoading } = useSetConfigItems()
	const { data: config } = useGetConfigItems()
	const { data: configEnums } = useGetThirdConfig()

	const onCofigChange = (key, idx) => {
		try {
			const newConfig = deepCopy(config)
			newConfig[key] = configEnums[key][idx]
			setConfigItems({ data: newConfig })
		} catch (err) {}
	}

	return (
		<CFlex fw align={'flex-start'}>
			<CMargin margin={'10px'} />
			<Flex justify={'space-between'} width={'300px'}>
				<DText main fontSize={'s'}>
					<Text tid={'language'} /> :
				</DText>

				<Flex style={{ margin: '0 8px' }}>
					<Select
						value={langs.find((item) => item.key === lang)?.value}
						options={langs.map((item) => item.value)}
						onValueChange={(idx) => setLang(langs[idx].key)}
						height={'36px'}
						width={'140px'}
						size={'small'}
						fontSize={'s'}
					/>
				</Flex>
			</Flex>
			<CMargin margin={'10px'} />
			<Flex justify={'space-between'} width={'300px'}>
				<DText main fontSize={'s'}>
					<Text tid={'theme'} /> :
				</DText>

				<Flex style={{ margin: '0 8px' }}>
					<Select
						value={theme}
						options={themes}
						onValueChange={(idx) => setTheme(themes[idx])}
						height={'36px'}
						width={'140px'}
						size={'small'}
						fontSize={'s'}
					/>
				</Flex>
			</Flex>

			{/* sms service */}
			<CMargin margin={'10px'} />
			<Flex justify={'space-between'} width={'300px'}>
				<DText main fontSize={'s'}>
					<Text tid={'smsService'} /> :
				</DText>
				<Flex style={{ margin: '0 8px' }}>
					{isLoading ? (
						<Loader size={8} />
					) : (
						<Select
							value={config?.smsService}
							options={configEnums?.smsService}
							onValueChange={(idx) => onCofigChange('smsService', idx)}
							height={'36px'}
							width={'140px'}
							size={'small'}
							fontSize={'s'}
						/>
					)}
				</Flex>
			</Flex>

			{/* price update */}
			<CMargin margin={'10px'} />
			<Flex justify={'space-between'} width={'300px'}>
				<DText main fontSize={'s'}>
					<Text tid={'priceUpdateSource'} /> :
				</DText>

				<Flex style={{ margin: '0 8px' }}>
					{isLoading ? (
						<Loader size={8} />
					) : (
						<Select
							value={config?.priceUpdateSource}
							options={configEnums?.priceUpdateSource}
							onValueChange={(idx) => onCofigChange('priceUpdateSource', idx)}
							height={'36px'}
							width={'140px'}
							size={'small'}
							fontSize={'s'}
						/>
					)}
				</Flex>
			</Flex>
		</CFlex>
	)
}

const langs = [
	{ key: 'en', value: 'english' },
	// { key: 'fa', value: 'فارسی' }
]

const themes = ['dark', 'light']

export default GeneralSettings
