import {CFlex, CMargin, DText, Flex} from "../../../../../styles/CommonStyles";
import CoinMarket from "../../../../common/common/CoinMarket";
import Text from "../../../../../core/utils/Text";
import {LineBreak} from "../../../../../styles/main/MainCommonStyles";
import RobotOrderBox from "../elements/RobotOrderBox";
import {TradeAmount} from "../../../../../styles/main/orders";
import {AnimatePresence, motion} from "framer-motion"
import {useState} from "react";
import {CollapseLtr, CollapseRtl, SettingCardWrapper} from "../../../../../styles/main/setting";
import {useMainContext} from "../../../../../core/contexts/main";


const RobotMarket = ({
    market
}) => {

    const {lang} = useMainContext()
    const [showDetails, setShowDetails] = useState(false)

    return (
        <SettingCardWrapper
            minHeight={'0'}
            main
        >
            <Flex fw wrap justify={'space-between'}>
                <Flex>
                    <CoinMarket
                        coin={market.coin}
                        pair={market.pair}
                    />
                </Flex>

                <Flex>
                    <DText main>
                        <Text tid={'status'} /> :
                    </DText>
                    <TradeAmount type={market.isActive ? 'buy' : 'sell'} style={{ margin: '0 8px' }}>
                        <Text tid={market.isActive ? 'is-active' : 'not-active'} />
                    </TradeAmount>
                    {lang === 'en' ?
                        <CollapseLtr size={24} open={showDetails} onClick={() => setShowDetails(state => !state)}/>
                        :
                        <CollapseRtl size={24} open={showDetails} onClick={() => setShowDetails(state => !state)}/>
                    }
                </Flex>
            </Flex>

            <LineBreak style={{ margin: '12px auto 0 auto' }}/>

            <AnimatePresence initial={false}>
                {showDetails &&
                    <motion.div
                        style={{ width: '100%', overflow: 'hidden' }}
                        variants={collapseVariants}
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <CFlex fw align={'flex-start'}>
                            <CMargin margin={'10px'} />
                            <DText primary>
                                <Text tid={'order-setting'} />
                            </DText>
                            <Flex fw wrap justify={'space-around'}>
                                <RobotOrderBox
                                    market={market}
                                    type={'order'}
                                    transaction={'buy'}
                                />
                                <RobotOrderBox
                                    market={market}
                                    type={'order'}
                                    transaction={'sell'}
                                />
                            </Flex>

                            <CMargin margin={'6px'} />
                            <LineBreak />
                            <CMargin margin={'6px'} />

                            <DText primary>
                                <Text tid={'trade-setting'} />
                            </DText>
                            <Flex fw wrap justify={'space-around'}>
                                <RobotOrderBox
                                    market={market}
                                    type={'trade'}
                                    transaction={'buy'}
                                />
                                <RobotOrderBox
                                    market={market}
                                    type={'trade'}
                                    transaction={'sell'}
                                />
                            </Flex>
                        </CFlex>
                    </motion.div>
                }
            </AnimatePresence>
        </SettingCardWrapper>
    )
}


const collapseVariants = {
    open: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 }
}

export default RobotMarket
