import FilterLayout from "../../components/layout/filter/FilterLayout";
import {PageCard} from "../../styles/CommonStyles";
import ListLayout from "../../components/layout/main/ListLayout";
import {useGetAllFiles} from "../../core/services/react-query/files";
import {CacheKeys, fileFilterOptions, fileSortOptions} from "../../core/constants/filter";
import {useState} from "react";
import {useWindowSize} from "../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../core/constants/common";
import FilesTable from "../../components/main/files/FilesTable";
import RFilesTable from "../../components/responsive/main/files/RFilesTable";


const Files = () => {

    const {width} = useWindowSize()

    const [files, setFiles] = useState({data: null, loading: true})
    const onQuerySuccess = res => setFiles(res)

    const Component = width > TABLET_SIZE ? FilesTable : RFilesTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetAllFiles}
                    onQuerySuccess={onQuerySuccess}
                    cache={CacheKeys.FILES}
                    options={fileFilterOptions}
                    sortOptions={fileSortOptions}
                >
                    {width && <Component data={files}/>}
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}


export default Files
