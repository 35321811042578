import styled from "styled-components";
import {CMargin, Flex} from "../../../styles/CommonStyles";
import {SkeletonAvatar, SKeletonText} from "../../../core/packages/skeleton/elements";
import Shimmer from "../../../core/packages/skeleton/Shimmer";


const RListSkeleton = () => {

    return (
        <Wrapper>
            <SkeletonAvatar>
                <Shimmer />
            </SkeletonAvatar>
            <CMargin margin={'8px'} />
            <SKeletonText width={'40%'}>
                <Shimmer />
            </SKeletonText>
            <CMargin margin={'8px'} />
            <SKeletonText width={'70%'}>
                <Shimmer />
            </SKeletonText>
            <CMargin margin={'8px'} />
            <SKeletonText width={'50%'}>
                <Shimmer />
            </SKeletonText>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px 0;
  width: 100%;
  background-color: ${props => props.theme.primaryBg};
  border-radius: 8px;
`

export default RListSkeleton