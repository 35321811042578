import ListLayout from '../../components/layout/main/ListLayout'
import { DText, PageCard } from '../../styles/CommonStyles'
import {useEffect, useState} from 'react'
import Tabbar from '../../components/common/tabs/Tabbar'
import FullnodeStats from '../../components/main/fullnode/FullnodeStats'
import FullnodeList from '../../components/main/fullnode/FullnodeList'
import FullnodeActions from '../../components/main/fullnode/FullnodeActions'
import { FaQuestionCircle } from 'react-icons/fa'
import Text from '../../core/utils/Text'
import styled from 'styled-components'
import { getMainTheme } from '../../core/utils/theme'
import { useMainContext } from '../../core/contexts/main'
import { useFullNode } from '../../core/hooks/useFullNode'
import { TradeTypeBadge } from '../../styles/main/orders'
import { useRef } from 'react'
import useClickOutside from '../../core/hooks/common/useClickOutside'
import {useSearchParams} from "react-router-dom";

const Fullnode = () => {
	const { theme, lang, token, sidebar } = useMainContext()

	const [searchParams, setSearchParams] = useSearchParams()

	const [activeTab, setActiveTab] = useState(parseInt(searchParams?.get('tab') || 1))
	const onTabChange = (idx) => {
		setSearchParams({ tab: idx })
	}

	useEffect(() => {
		const tab = searchParams.get('tab')
		if (!!tab) setActiveTab(parseInt(tab))
	}, [searchParams])

	const comps = [FullnodeStats, FullnodeList, FullnodeActions]

	const [isInfoOpen, setIsInfoOpen] = useState(false)
	const { CUMULATIVE_STATUSES, getStatusInfo, mergeSymbols } = useFullNode()

	const ActiveComp = comps[activeTab - 1]

	const outRef = useRef()
	useClickOutside(outRef, () => setIsInfoOpen(false))

	return (
		<ListLayout>
			<PageCard>
				<Tabbar
					tabs={['fullnode-stats', 'fullnode-list', 'fullnode-actions']}
					active={activeTab}
					onTabClicked={onTabChange}
				>
					<div ref={outRef} style={{ position: 'relative', zIndex: 100000000000 }}>
						<span onClick={() => setIsInfoOpen(!isInfoOpen)} style={{ cursor: 'pointer' }}>
							<FaQuestionCircle size={'1.5rem'} color={getMainTheme(theme, lang).mainYellow} />
						</span>

						{isInfoOpen && (
							<InfoWrapper>
								<PageCard
									style={{
										width: 'auto !important',
										minHeight: 150,
										display: 'flex',
										gap: 10,
										flexDirection: 'column',
									}}
								>
									{CUMULATIVE_STATUSES.map((item) => {
										const statusInfo = getStatusInfo(item.status)

										return (
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'start',
												}}
											>
												<span>
													<TradeTypeBadge
														color={statusInfo?.color}
														style={{ margin: '0 8px', minWidth: 'auto' }}
													>
														{item.status}
													</TradeTypeBadge>
												</span>
												<Text
													style={{
														color: getMainTheme(theme, lang).color,
														fontSize: '0.85rem',
													}}
													tid={item.description}
												/>
											</div>
										)
									})}
								</PageCard>
							</InfoWrapper>
						)}
					</div>
				</Tabbar>

				<div style={{ marginTop: '20px' }}>
					<ActiveComp />
				</div>
			</PageCard>
		</ListLayout>
	)
}

const InfoWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 25px;
	z-index: 10000000;
	min-width: 320px;

	@media screen and (max-width: 420px) {
		min-width: 260px;
	}
`

export default Fullnode
