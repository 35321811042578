import {useParams} from "react-router-dom";
import {useState} from "react";
import {UserDetailListWrapper} from "../../../../styles/main/user";
import FilterLayout from "../../../layout/filter/FilterLayout";
import {bankTransactionOptions, CacheKeys} from "../../../../core/constants/filter";
import {TABLET_SIZE} from "../../../../core/constants/common";
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import BankTransactionTable from "../../reports/bank-transactions/bankTransactions/BankTransactionTable";
import RBankTransactionTable from "../../../responsive/main/reports/bank-transactions/RBankTransactionTable";
import {useGetBankTransactions} from "../../../../core/services/react-query/report/banking";
import {useAclContext} from "../../../../core/contexts/acl";


const UserBankTransactions = () => {

    const params = useParams()
    const { width } = useWindowSize()

    const [transactions, setTransactions] = useState({loading: true, data: null})
    const { permissions } = useAclContext()
    const hasDownload = permissions?.export?.write
    const onQuerySuccess = (res) => {
        setTransactions(res)
    }

    const Component = width > TABLET_SIZE ? BankTransactionTable : RBankTransactionTable

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useGetBankTransactions}
                options={bankTransactionOptions}
                onQuerySuccess={onQuerySuccess}
                cache={CacheKeys.USER_BANK_TRANSACTIONS}
                extra={{ userId: params.id }}
                hasDownload={hasDownload}
            >
                <Component
                    data={transactions}
                />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}

export default UserBankTransactions
