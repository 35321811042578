import {PieCharWrapper} from "../../../styles/main/panel";
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import {useState} from "react";
import {useMainContext} from "../../../core/contexts/main";
import {ScaleLoader} from "react-spinners";
import {Flex} from "../../../styles/CommonStyles";


const PanelPieChart = ({
    data
}) => {

    const { lang } = useMainContext()
    const [activeIndex, setActiveIndex] = useState(0)

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload[lang]}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#666">
                    {`${value} ${lang === 'en' ? 'user' : 'کاربر'}`}
                </text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    if (!data) return (
        <Flex fw height={'300px'}>
            <ScaleLoader
                color={'#9F9181'}
                height={26}
                width={3}
            />
        </Flex>
    )

    return (
        <PieCharWrapper>
            <ResponsiveContainer width={520} height={300}>
                <PieChart
                >
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        dataKey="value"
                        onMouseEnter={(_, idx) => setActiveIndex(idx)}
                    >
                        {data.map(item => (
                            <Cell key={item.en} fill={item.color} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </PieCharWrapper>
    )
}


export default PanelPieChart
