import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";


export const useTabHook = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [tab, setTab] = useState(parseInt(searchParams.get('tab')) || 1)
    useEffect(() => {
        setSearchParams({ tab })
    }, [tab])

    return {
        tab, setTab
    }
}
