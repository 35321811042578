import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {useAclContext} from "../../../../../core/contexts/acl";
import RTableLayout from "../../../layout/RTableLayout";
import {CMargin, DText, Flex, IconWrapper, Row} from "../../../../../styles/CommonStyles";
import {EditIcon} from "../../../../../styles/main/setting";
import {RespIndex} from "../../../../../styles/main/MainCommonStyles";
import PermissionColumn from "../../../../main/setting/roles/PermissionColumn";


const RRolesTable = ({
     data,
     enterEditMode
}) => {

    const { data: roles } = data
    const {getTableIndex} = useGetTableIndex()
    const {permissions} = useAclContext()
    const hasWriteAccess = permissions?.role?.write

    return (
        <RTableLayout
            data={data}
        >
            {roles?.data?.map((role, idx) => (
                <Row index={idx} cs={'100%'}>
                    <Flex fw justify={'space-between'}>
                        <DText main fontSize={'s'}>
                            {role.name}
                        </DText>
                        {hasWriteAccess &&
                            <IconWrapper onClick={() => enterEditMode(role)}>
                                <EditIcon size={20} />
                            </IconWrapper>
                        }
                    </Flex>
                    <CMargin margin={'6px'} />
                    <PermissionColumn
                        perms={role.permissions}
                    />
                    <RespIndex>
                        {getTableIndex(idx)}
                    </RespIndex>
                </Row>
            ))}
        </RTableLayout>
    )
}

export default RRolesTable
