import styled from "styled-components"
import { formatNumber } from "../../../../core/utils/common"
import Text from "../../../../core/utils/Text"
import { CMargin, DText, Flex } from "../../../../styles/CommonStyles"



const AffiliateShare = ({
    item
}) => {

    if (!item) return <></>

    const { volumes, count } = item

    return (
        <ShareBox>
            <Flex fw justify='space-between'>
                <DText main fontSize='s'>
                    <Text tid='volume' /> :
                </DText>
                <DText primary fontSize='s'>
                    {formatNumber(volumes)}
                </DText>
            </Flex>
            <CMargin margin='4px' />
            <Flex fw justify='space-between'>
                <DText main fontSize='s'>
                    <Text tid='count' /> :
                </DText>
                <DText primary fontSize='s'>
                    {formatNumber(count)}
                </DText>
            </Flex>

        </ShareBox>
    )
}


const ShareBox = styled.div`
    border: 1px solid ${props => props.theme.color}15;
    border-radius: 4px;
    width: 180px;
    padding: 6px 12px;
    box-shadow: 0 0 6px rgb(0, 0, 0, 0.05);
    background-color: ${props => props.theme.secondaryBg};
`

export default AffiliateShare
