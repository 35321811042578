import SettingDetailsLayout from "../../../components/layout/main/SettingDetailLayout";
import {useGetAvailableBanks} from "../../../core/services/react-query/setting/available-banks";
import {useState} from "react";
import FiatView from "../../../components/main/setting/fiat/FiatView";


const Fiat = () => {

    const [fiat, setFiat] = useState({loading: true, data: null})

    const onQuerySuccess = (res) => setFiat(res)

    return (
        <SettingDetailsLayout
            query={useGetAvailableBanks}
            onQuerySuccess={onQuerySuccess}
        >
            <FiatView
                data={fiat}
            />
        </SettingDetailsLayout>
    )
}


export default Fiat