import {Link} from "react-router-dom";
import {DText} from "../../../styles/CommonStyles";


const UserLink = (props) => {

    const {_id, name, size = 'small'} = props

    return (
        <Link to={`/users/${_id}`} {...props}>
            <DText fontSize={size === 'big' ? 'm' : 's'} primary>
                {name ? name : '--'}
            </DText>
        </Link>
    )
}


export default UserLink
