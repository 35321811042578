import {Fragment, useMemo, useState} from "react"
import {
    CFlex,
    CMargin,
    Column,
    DetailsLtr,
    DetailsRtl,
    DText, Flex,
    IconWrapper,
    Row
} from "../../../../../styles/CommonStyles"
import Tabbar from "../../../../common/tabs/Tabbar"
import TableLayout from "../../../../layout/main/TableLayout"
import {TABLET_SIZE} from "../../../../../core/constants/common"
import { useWindowSize } from "../../../../../core/hooks/common/useWindowSize"
import { TradeTypeBadge } from "../../../../../styles/main/orders"
import SettingRow from "../../../../common/common/SettingRow"
import { getTradeTypeColor } from "../../../../../core/utils/theme"
import PriceNumber from "../../../../common/utils/PriceNumber"
import {availableCoinsWDListAttributes} from "../../../../../core/constants/headers";
import {useMainContext} from "../../../../../core/contexts/main";
import ModalLayout from "../../../../layout/main/ModalLayout";
import Text from "../../../../../core/utils/Text";
import {LineBreak} from "../../../../../styles/main/MainCommonStyles";


const NetworkBox = ({
    coin
}) => {


    const {width} = useWindowSize()
    const tabs = ['depositList', 'withdrawList']
    const [activeTab, setActiveTab] = useState(1)
    const { cs, headers } = availableCoinsWDListAttributes

    return (
        <>
            <CMargin margin='10px'/>
            <Tabbar
                active={activeTab}
                tabs={tabs}
                onTabClicked={(idx) => setActiveTab(idx)}
            />
            {width > TABLET_SIZE ?
                <TableLayout
                    headers={headers}
                    cs={cs}
                    data={{data: coin ? { data: coin[tabs[activeTab - 1]] } : [], loading: false}}
                    hasPagination={false}
                >
                    <DesktopTable
                        data={coin && coin[tabs[activeTab - 1]]}
                        coin={coin}
                    />
                </TableLayout>
                :
                <MobileTable
                    data={coin && coin[tabs[activeTab - 1]]}
                    coin={coin}
                />
            }
        </>
    )
}


const DesktopTable = ({
    data,
    coin
}) => {

    const { cs } = availableCoinsWDListAttributes
    const { lang } = useMainContext()
    const { width } = useWindowSize()

    const [modal, setModal] = useState({ open: false, idx: -1 })

    const details = useMemo(() => {
        const temp = {}
        if (!!coin && !!data && modal.open) {
            temp.network = data[modal.idx].network
            temp.min = data[modal.idx].min
            temp.max = data[modal.idx].max
            temp['fee-factor'] = data[modal.idx].feeFactor
            temp['fee-max'] = data[modal.idx].feeMax
            temp.scanner = data[modal.idx].scanner
        }
        return temp
    }, [coin, data, modal])


    return (
        <>
            {data?.map((item, idx) => (
                    <Row index={idx} cs={cs} key={item.network}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor('limit')}>
                                {item.network}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <PriceNumber
                                num={item.min}
                                coin={coin?.id}
                            />
                        </Column>
                        <Column>
                            <PriceNumber
                                num={item.max}
                                coin={coin?.id}
                            />
                        </Column>
                        <Column>
                            {item.feeFactor} %
                        </Column>
                        <Column>
                            <PriceNumber
                                num={item.feeMax}
                                coin={coin?.id}
                            />
                        </Column>
                        <Column more>
                            <IconWrapper onClick={() => setModal({open: true, idx})}>
                                {lang === 'en' ?
                                    <DetailsLtr size={width > TABLET_SIZE ? 24 : 18 } />
                                    :
                                    <DetailsRtl size={width > TABLET_SIZE ? 24 : 18 } />
                                }
                            </IconWrapper>
                        </Column>
                    </Row>
                ))}

            <ModalLayout
                width='520px'
                onClose={() => setModal({ open: false, idx: -1 })}
                open={modal.open}
            >
                <CFlex fw>
                    <DText primary>
                        <Text tid={'network-details'} />
                    </DText>
                    {Object.keys(details).map(key => (
                        <Fragment key={key}>
                            <Flex fw  justify={'space-between'} style={{ marginTop: '8px' }}>
                                <DText main>
                                    <Text tid={key} /> :
                                </DText>
                                <DText primary>
                                    {key === 'scanner' ?
                                        details[key]
                                        :
                                        <Text tid={details[key]} />
                                    }
                                </DText>
                            </Flex>
                            <LineBreak />
                        </Fragment>
                    ))}
                </CFlex>

            </ModalLayout>
        </>
    )
}


const MobileTable = ({
    data,
    coin
}) => {

    return (
        <>
            {data?.map((item, idx) => (
                <Row index={idx} cs='100%' key={item.network}>
                    <SettingRow
                        prefix='network'
                        suffix={
                            <TradeTypeBadge color={getTradeTypeColor('limit')}>
                                {item.network}
                            </TradeTypeBadge>
                        }
                    />
                    <SettingRow
                        prefix='max'
                        suffix={item.min}
                        coin={coin?.id}
                        priceNumber
                    />
                    <SettingRow
                        prefix='max'
                        suffix={item.max}
                        coin={coin?.id}
                        priceNumber
                    />
                    <SettingRow
                        prefix='fee-factor'
                        suffix={item.feeFactor}
                    />
                    <SettingRow
                        prefix='fee-max'
                        suffix={item.feeMax}
                        coin={coin?.id}
                        priceNumber
                    />
                </Row>
            ))}
        </>
    )
}


export default NetworkBox
