import Text from "../../../core/utils/Text"
import { CMargin, DText, Flex, IconWrapper } from "../../../styles/CommonStyles"
import { EditIcon, SettingCardWrapper } from "../../../styles/main/setting"
import {LineBreak} from '../../../styles/main/MainCommonStyles'
import Tooltip from "../../common/utils/Tooltip"


const SettingCard = (props) => {

    const {
        children,
        title,
        noTitle,
        coin,
        main = false,
        onEditClicked
    } = props

    return (
        <SettingCardWrapper {...props}>
            {!noTitle &&
                <>
                    <Flex fw justify='space-between'>
                        <DText main fontSize='b'>
                            <Text tid={title} />
                        </DText>
                        <Tooltip
                            content='edit'
                        >
                            <IconWrapper
                                onClick={onEditClicked}
                            >
                                <EditIcon size={22}/>
                            </IconWrapper>
                        </Tooltip>
                    </Flex>
                    <LineBreak />
                </>
            }

            <CMargin margin='2px' />
            {(coin || main) ?
                children
                :
                <Flex fw style={{ marginTop: '100px' }}>
                    <DText main>
                        <Text tid='select-coin-to-see-details' />
                    </DText>
                </Flex>

            }

        </SettingCardWrapper>
    )
}


export default SettingCard
