import RTableLayout from "../../../../responsive/layout/RTableLayout";
import {Flex, Row} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {MiniLineBreak} from "../../../../../styles/main/MainCommonStyles";
import UserLink from "../../../../common/utils/UserLink";
import CopyText from "../../../../common/utils/CopyText";
import {TradeTypeBadge} from "../../../../../styles/main/orders";


const RTable = ({ data }) => {

    return (
        <RTableLayout
            data={data}
        >
            {data?.data?.data?.map(item => {

                return (
                    <Row cs={'100%'} key={item._id}>
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'user'} />
                            <UserLink _id={item.userId} name={`${item.user?.firstName} ${item.user?.lastName}`} />
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'ip'} />
                            <CopyText text={item.ip} justify={'flex-end'} />
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'thirdParty'} />
                            <TradeTypeBadge>
                                {item.thirdParty}
                            </TradeTypeBadge>
                        </Flex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}

export default RTable
