import {useParams} from "react-router-dom";
import {
    DetailsWrapper,
    DetailsBody,
    DetailsBorder,
    RightHeadDecoration,
    LeftHeadDecoration
} from "../../../../../styles/main/MainCommonStyles";
import CoinMarket from "../../../../common/common/CoinMarket";
import {useGetAnOrder} from "../../../../../core/services/react-query/report/orders";
import {CFlex, CMargin, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {formatDate, formatNumber} from "../../../../../core/utils/common";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import AmountBar from "../../../../common/charts/AmountBar";
import {useMainContext} from "../../../../../core/contexts/main";
import Skeleton from "../../../../../core/packages/skeleton";
import DetailSkeleton from "../../../../skeleton/DetailSkeleton";
import NoData from "../../../../common/utils/NoData"
import {BuyIcon, SellIcon, TradeAmount, TradeTypeBadge} from "../../../../../styles/main/orders";
import Operation from '../../../../common/utils/Operation'
import useCurrentOrders from "../../../../../core/hooks/main/orders/useCurrentOrders";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";
import DetailsLayout from "../../../../layout/main/DetailsLayout";


const CurrentOrderDetails = () => {

    const { lang } = useMainContext()
    const params = useParams()

    const { data: order, isFetching, isError } = useGetAnOrder(params.detailId)

    const {
        modal,
        deletingOrder,
        onModalClose,
        onSubmitClicked,
        hasWriteAccess,
        onDeleteClicked
    } = useCurrentOrders()

    return (
        <DetailsLayout
            isFetching={isFetching}
            isError={isError}
        >

            <RightHeadDecoration>
                <CoinMarket
                    coin={order?.coin}
                    pair={order?.pair}
                    detail
                    noName
                />
            </RightHeadDecoration>
            <LeftHeadDecoration left={'10px'}>
                <DText main>
                    {params.detailId}
                </DText>
            </LeftHeadDecoration>

            {/* content */}
            <Flex fw justify='space-between'>
                <DText fontSize={'b'} primary>
                    {order?.coin?.toUpperCase()}/{order?.pair?.toUpperCase()}
                </DText>
                <Operation
                    onDeleteClicked={() => onDeleteClicked(order)}
                    hasWriteAccess={hasWriteAccess}
                    deleteTooltip={'delete-order'}
                    hasDelete
                />
            </Flex>


            <Flex justify={'space-between'} wrap>
                <CFlex width={'50%'} style={{ minWidth: '280px' }}>
                    <CMargin margin={'8px'} />
                    <Flex fw justify={'space-between'}>
                        <DText primary>
                            <Text tid={'unitPrice'} /> :
                        </DText>
                        <DText primary>
                            {formatNumber(order?.priceUnit)}
                        </DText>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <Flex fw justify={'space-between'}>
                        <DText primary>
                            <Text tid={'price'} /> :
                        </DText>
                        <DText primary>
                            {formatNumber(order?.price)}
                        </DText>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <Flex fw justify={'space-between'}>
                        <DText primary>
                            <Text tid={'trade-type'} /> :
                        </DText>
                        <TradeTypeBadge color={getTradeTypeColor(order?.submit)}>
                            {order?.submit}
                        </TradeTypeBadge>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <Flex fw justify={'space-between'}>
                        <DText primary>
                            <Text tid={'transaction-type'} /> :
                        </DText>
                        <Flex>
                            {order?.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                            <Flex style={{ margin: '0 7px' }}>
                                <TradeAmount type={order?.type}>
                                    <Text tid={order?.type} />
                                </TradeAmount>
                            </Flex>
                        </Flex>
                    </Flex>
                </CFlex>
                <AmountBar
                    type={order?.type}
                    amount={order?.amount}
                    tradedAmount={order?.tradedAmount}
                />
            </Flex>
            <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                <DText main>
                    {formatDate(order?.createdAt, 'date', lang)}
                </DText>
                <DText main>
                    {formatDate(order?.createdAt, 'time', lang)}
                </DText>
            </Flex>

            <ModalLayout
                open={modal.open}
                width={'520px'}
                onClose={onModalClose}
            >
                <BasicModal
                    head={'delete-order-head'}
                    desc={'delete-order-desc'}
                    _id={modal.order?._id}
                    onClose={onModalClose}
                    onSubmit={() => onSubmitClicked('detail')}
                    loading={deletingOrder}
                />
            </ModalLayout>
        </DetailsLayout>
    )
}

const headers = [

]


export default CurrentOrderDetails
