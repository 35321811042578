import ListLayout from "../../../components/layout/main/ListLayout";
import WagesTable from "../../../components/main/reports/wages/WagesTable";
import RWagesTable from "../../../components/responsive/main/reports/wages/RWagesTable";
import { TABLET_SIZE } from "../../../core/constants/common";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { PageCard } from "../../../styles/CommonStyles";



const WagesList= () => {

    const { width } = useWindowSize()
    const Component = width > TABLET_SIZE ? WagesTable : RWagesTable

    return (
        <ListLayout>
            <PageCard>
                {width && <Component />}
            </PageCard>
        </ListLayout>
    )
}

export default WagesList;
