import {CFlex, CMargin, DText, Padding} from "../../styles/CommonStyles";
import {ModalHeader} from "../../styles/modals";
import Text from "../../core/utils/Text";
import {TextArea} from "../../styles/main/user";
import {useEffect, useState} from "react";
import ActionButton from "../common/buttons/ActionButton";
import {useMainContext} from "../../core/contexts/main";
import {useQueryContext} from "../../core/contexts/query";
import {useUpdateMultiSignature} from "../../core/services/react-query/multi-signature";


const BalanceSignatureActionModal = ({
    data,
    onClose,
}) => {

    const { setToast } = useQueryContext()
    const { lang } = useMainContext()
    const [reply, setReply] = useState('')

    const {
        mutate: updateSignature,
        isLoading: actionLoading,
        isSuccess: actionSuccess
    } = useUpdateMultiSignature()

    const onSubmitClicked = () => {
        if (!!reply) {
            const payload = {
                reply,
                status: data.action === 'accept-action' ? 'done' : 'rejected'
            }
            updateSignature({
                data: payload,
                id: data.item?._id
            })
        } else {
            setToast({
                isError: true, show: true,
                message: 'fill-input-errors'
            })
        }
    }

    useEffect(() => {
        if (actionSuccess) {
            onClose()
        }
    }, [actionSuccess])


    return (
        <CFlex fw>
            <ModalHeader>
                <DText primary>
                    <Text tid={data.action} />
                </DText>
            </ModalHeader>
            <Padding padding={'0 20px'}>
                <CMargin margin={'10px'} />
                <TextArea
                    value={reply}
                    onChange={(e) => setReply(e?.target?.value)}
                    placeholder={
                        lang === 'en' ?
                            'Cause...'
                            :
                            'علت عملیات...'
                    }
                />
                <CMargin margin={'10px'} />
                <ActionButton
                    active={!!reply}
                    onClick={onSubmitClicked}
                    loading={actionLoading}
                >
                    <Text tid={'submit'} />
                </ActionButton>
            </Padding>
        </CFlex>
    )
}


export default BalanceSignatureActionModal
