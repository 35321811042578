import { useEffect } from "react"
import Skeleton from "../../../core/packages/skeleton";
import SettingCardSkeleton from "../../skeleton/SettingCardSkeleton";
import {CFlex, Flex} from "../../../styles/CommonStyles";
import ListLayout from "./ListLayout";



const SettingDetailsLayout = ({
    query,
    onQuerySuccess,
    children
}) => {

    const { data, isFetching: loading} = query() 

    useEffect(() => {
        onQuerySuccess({data, loading})
    }, [data, loading])

    return (
        <>
            {loading ?
                <ListLayout>
                    <CFlex fw>
                        <Flex fw justify={'space-around'} wrap>
                            <Skeleton
                                Content={SettingCardSkeleton}
                            />
                            <Skeleton
                                Content={SettingCardSkeleton}
                            />
                        </Flex>
                        <Flex fw justify={'space-around'} wrap>
                            <Skeleton
                                Content={SettingCardSkeleton}
                            />
                            <Skeleton
                                Content={SettingCardSkeleton}
                            />
                        </Flex>
                    </CFlex>
                </ListLayout>
                :
                children
            }
        </>
    )

}


export default SettingDetailsLayout