import usePanelStats from "../../../../core/hooks/main/panel/usePanelStats";
import Select from "../../../common/dropdown/Select";
import {useState} from "react";
import {PERIODS} from "../../../../core/constants/panel";
import {DText, Flex} from "../../../../styles/CommonStyles";
import {ModalHeader} from "../../../../styles/modals";
import Text from "../../../../core/utils/Text";
import PanelBarChart from "../PanelBarChart";
import {ScaleLoader} from "react-spinners";
import {BarCharWrapper} from "../../../../styles/main/panel";


const TradesPanel = () => {

    const { data } = usePanelStats('trade')
    const [period, setPeriod] = useState('total')

    return (
        <>
            <ModalHeader style={{ marginBottom: '10px', padding: '8px 0' }}>
                <DText main>
                    <Text tid={'trades'} />
                </DText>
            </ModalHeader>
            <Flex fw justify={'flex-start'} style={{ marginBottom: '10px' }}>
                <DText main fontSize={'s'} style={{ margin: '0 5px' }}>
                    <Text tid={'period'} /> :
                </DText>
                <Select
                    width={'180px'}
                    options={PERIODS}
                    value={period}
                    onValueChange={(idx) => setPeriod(PERIODS[idx])}
                    height={'42px'}
                    fontSize={'s'}
                />
            </Flex>
            {!data &&
                <Flex fw height={'300px'}>
                    <ScaleLoader
                        color={'#9F9181'}
                        height={26}
                        width={3}
                    />
                </Flex>
            }

            <BarCharWrapper>
                {data &&
                    <PanelBarChart
                        data={data}
                        period={period}
                    />
                }
            </BarCharWrapper>
        </>

    )
}

export default TradesPanel
