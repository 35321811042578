import {useLocation} from "react-router-dom";
import {CgFeed, CgProfile, CgClipboard} from "react-icons/cg";
import {MdDashboard, MdGeneratingTokens, MdSettingsInputComponent, MdOutlineFeed} from "react-icons/md";
import {HiUserGroup} from "react-icons/hi";
import {BsFilesAlt} from "react-icons/bs";
import {RiBankCard2Fill, RiBankFill, RiCopperCoinFill, RiListUnordered} from "react-icons/ri";
import {FaRobot, FaWallet} from "react-icons/fa";
import {TbAffiliate, TbReportAnalytics} from "react-icons/tb";
import {SiBlockchaindotcom, SiGoogletagmanager} from "react-icons/si";
import {GiTwoCoins} from "react-icons/gi";
import {IoMdSettings} from "react-icons/io"
import {BiCurrentLocation} from "react-icons/bi"
import {GoChecklist, GoScreenFull} from "react-icons/go";



export const useCrumbs = () => {

    const location = useLocation()
    const path = location?.pathname

    const paths = path.split('/').slice(1, path.length - 1)

    const crumbs = paths.map(path => path[0]?.toUpperCase() + path.slice(1, path.length))

    const specialCrumbCases = ['Files', 'Dashboard']

    const getCrumbStatus = (idx) => {
        let temp
        if (specialCrumbCases.includes(crumbs[0])) {
            temp = crumbs.slice(0, 1)
        }else {
            temp = crumbs.slice(0, idx + 1)
        }
        const _path = `/${temp.join('/')?.toLowerCase()}`
        return {
            path: _path,
            valid: idx !== crumbs.length - 1
        }
    }

    return {
        getCrumbStatus,
        crumbs,
        crumbIcons
    }
}



const crumbIcons = {
    '/reports': TbReportAnalytics,
    '/setting': IoMdSettings,
    '/profile': CgProfile,
    '/dashboard': MdDashboard,
    '/users': HiUserGroup,
    '/cartable': CgClipboard,
    '/files': BsFilesAlt,
    '/bank-accounts': RiBankFill,
    '/reports/orders': RiListUnordered,
    '/reports/coin-transactions': FaWallet,
    '/reports/bank-transactions': RiBankCard2Fill,
    '/reports/wages-list': CgFeed,
    '/reports/affiliate-overview': TbAffiliate,
    '/reports/addresses': BiCurrentLocation,
    '/setting/tokens': MdGeneratingTokens,
    '/setting/pairs': RiCopperCoinFill,
    '/setting/roles': SiGoogletagmanager,
    '/setting/robots': FaRobot,
    '/setting/otc': SiBlockchaindotcom,
    '/setting/wages': MdOutlineFeed,
    '/setting/available-banks': RiBankFill,
    '/setting/available-coins': GiTwoCoins,
    '/setting/coin-list': GoChecklist,
    '/setting/overall': MdSettingsInputComponent,
    '/fullnode': GoScreenFull
}
