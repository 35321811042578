import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";


const WAGES_KEYS = {
    GET_WAGES: 'get-wages',
    SET_WAGES: 'set-wages'
}


const useGetWages = () => {

    return useQuery(
        WAGES_KEYS.GET_WAGES, () => normalFetch('settings/wages'),
        {
            select: res => res?.data?.data,
            cacheTime: 5 * 60 * 100,
            staleTime: 5 * 60 * 100
        }
    )
}


const useSetWages = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        WAGES_KEYS.SET_WAGES, (data) => putApi(data, 'settings/wages'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(WAGES_KEYS.GET_WAGES)
                setToast({
                    show: true,
                    message: 'operation-success'
                })
            }
        }
    )
}


export {
    useGetWages,
    useSetWages
}
