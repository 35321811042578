import {useQuery} from "react-query";
import {filterFetch} from "../../fetch-api/get";
import {useMainContext} from "../../../contexts/main";
import {makeQueryFilters} from "../../../utils/query-maker";


const BANK_ACCOUNT_KEYS = {
    GET_BANK_ACCOUNTS: 'get-bank-accounts'
}


const useGetBankAccounts = (filters, pagination) => {

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(filters, lang), ...pagination}

    return useQuery(
        BANK_ACCOUNT_KEYS.GET_BANK_ACCOUNTS, () => filterFetch(params, 'users/kyc/bank')
    )

}


export {
    useGetBankAccounts
}
