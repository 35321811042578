import {useAclContext} from "../../../contexts/acl";
import {useNavigate, useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import { useDeleteCurrentOrder } from "../../../services/react-query/report/orders";


const useCurrentOrders = () => {

    const params = useParams()
    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.order?.write

    const modalInitial = { open: false, order: null }
    const [modal, setModal] = useState(modalInitial)

    const onModalClose = () => setModal(modalInitial)

    const { mutate: deleteOrder, isLoading, isSuccess } = useDeleteCurrentOrder()


    const onSubmitClicked = (fromDetail) => {
        deleteOrder(modal.order?._id)
        if (fromDetail) {
            navigate('/reports/orders')
        }
    }

    useEffect(() => {
        isSuccess && onModalClose()
    }, [isSuccess])

    const onDetailsClicked = (order) => {
        if (params.tab)
            navigate(`/users/${params.id}/${params.tab}/${order._id}?type=order`)
        else
            navigate(`/reports/orders/${order._id}?type=order`)
    }

    const onDeleteClicked = (order) => {
        setModal({
            open: true,
            order
        })
    }


    return {
        hasWriteAccess,
        onDetailsClicked,
        onDeleteClicked,
        modal,
        onModalClose,
        deletingOrder: isLoading,
        onSubmitClicked
    }
}

export default useCurrentOrders
