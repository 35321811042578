import {Background, Flex} from "../../../styles/CommonStyles";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useMainContext} from "../../../core/contexts/main";
import Text from "../../../core/utils/Text";
import styled, {keyframes} from "styled-components";
import {NoAccessDesc, NoAccessText} from "../../../styles/layout";


const PageNotFound = () => {

    const navigate = useNavigate()
    const { theme } = useMainContext()

    useEffect(() => {
        const timeout = setTimeout(() => navigate('/profile'), 5000)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <Flex fw height='100vh' style={{ overflow: 'hidden' }}>
            <Background bg='mainBg'>
                <img
                    src={require(`../../../assets/images/no-page-${theme}.png`)}
                    alt={' '}
                    style={{ objectFit: 'cover' }}
                />
                <NoAccessText>
                    <Text tid={'page-not-found'} />
                </NoAccessText>
                <NoAccessDesc>
                    <Text tid={'moving-to-profile'} />
                </NoAccessDesc>
            </Background>
        </Flex>
    )
}


export default PageNotFound
