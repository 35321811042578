import {useGetDepositWebHooks} from "../../../../../core/services/react-query/report/banking";
import {
    CacheKeys,
    depositWebhookOptions
} from "../../../../../core/constants/filter";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import FilterLayout from "../../../../layout/filter/FilterLayout";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import Table from "./Table";
import RTable from "./RTable";


const BankWebHooks = () => {

    const { width } = useWindowSize()
    const [webhooks, setWebhooks] =  useState({data: null, loading: true})

    const onQuerySuccess = (res) => {
        setWebhooks(res)
    }

    const ActiveComp = width > TABLET_SIZE ? Table : RTable

    return (
        <FilterLayout
            query={useGetDepositWebHooks}
            onQuerySuccess={onQuerySuccess}
            options={depositWebhookOptions}
            cache={CacheKeys.DEPOSIT_WEB_HOOKS}
        >
            <ActiveComp data={webhooks} />
        </FilterLayout>
    )
}

export default BankWebHooks
