import {CFlex, CMargin, DText, Flex} from "../../../styles/CommonStyles";
import {DownloadIcon, ReadIcon} from "../../../styles/main/setting";
import {ReactComponent as NoImage} from "../../../assets/images/no-image.svg";
import ActionButton from "../../common/buttons/ActionButton";
import Text from "../../../core/utils/Text";
import {PreviewImage, ProfileImageBox} from "../../../styles/main/profile";
import {useState} from "react";
import {downloadImage} from "../../../core/utils/common";


const ProfileDocumentImage = (props) => {

    const {
        src,
        type,
        detail,
        onDeleteImage
    } = props

    const previewInitial = {file: null, show: false}
    const [preview, setPreview] = useState(previewInitial)


    const onImagePreview = (file) => {
        setPreview({
            show: true, file
        })
    }

    const onDownloadImage = (url) => {
        downloadImage(url)
    }

    const onDeleteImageClicked = () => {
        if (!src) return
        onDeleteImage(type)
    }

    return (
        <>
            <ProfileImageBox>
                <CFlex fw>
                    <CMargin margin={'2px'} />

                    <Flex fw justify={'space-between'} style={{ padding: '5px' }}>
                        <DText primary fontSize={'s'}>
                            <Text tid={type} />
                        </DText>
                        {src &&
                            <Flex>
                                <ReadIcon
                                    style={{ margin: '0 4px', cursor: 'pointer' }}
                                    onClick={() => onImagePreview(src)}
                                />
                                <DownloadIcon
                                    onClick={() => onDownloadImage(src)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Flex>
                        }

                    </Flex>

                    <CMargin margin={'4px'} />
                    {src ?
                        <img
                            src={src}
                            width={'80%'}
                            height={'140px'}
                            alt={' '}
                        />
                        :
                        <>
                            <Flex fw fh>
                                <NoImage width={'70%'} height={'70%'} />
                            </Flex>
                            <DText main style={{ marginTop: '6px' }} fontSize={'s'}>
                                <Text tid={`no-${type}`} />
                            </DText>
                        </>
                    }
                    <CMargin margin={'4px'} />
                    {(detail && src) &&
                        <ActionButton
                            height={'28px'}
                            active={src}
                            onClick={onDeleteImageClicked}
                        >
                            <Text tid={`delete-${type}`} />
                        </ActionButton>
                    }
                </CFlex>
            </ProfileImageBox>
            {preview.show &&
                <PreviewImage onClick={() => setPreview(previewInitial)}>
                    <Flex fw fh>
                        <img
                            src={preview.file}
                            alt={' '}
                            width={'100%'}
                            height={'100%'}
                            style={{ objectFit: 'scale-down' }}
                        />
                    </Flex>
                </PreviewImage>
            }
        </>
    )
}


export default ProfileDocumentImage
