import ListLayout from "../../../components/layout/main/ListLayout";
import {Flex, IconWrapper, PageCard} from "../../../styles/CommonStyles";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {CacheKeys, tokenFilterOptions, tokenSortOptions} from "../../../core/constants/filter";
import {useState} from "react";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";
import TokensTable from "../../../components/main/setting/tokens/TokensTable";
import RTokensTable from "../../../components/responsive/main/setting/tokens/RTokensTable";
import {useGetTokens} from "../../../core/services/react-query/setting/token";
import {AddIcon} from "../../../styles/main/setting";
import Tooltip from "../../../components/common/utils/Tooltip";
import {useNavigate} from "react-router-dom";


const Tokens = () => {

    const navigate = useNavigate()
    const { width } = useWindowSize()
    const [tokens, setTokens] = useState({data: null, loading: true})
    const onQuerySuccess = (res) => setTokens(res)

    const Component = width > TABLET_SIZE ? TokensTable : RTokensTable

    const onAddToken = () => {
        navigate('/setting/tokens/operation?type=add-token')
    }

    return (
        <ListLayout>
            <PageCard>
                <Flex fw justify={'flex-end'}>
                    <Tooltip
                        content={'add-token'}
                    >
                        <IconWrapper
                            onClick={onAddToken}
                        >
                            <AddIcon
                                size={24}
                            />
                        </IconWrapper>
                    </Tooltip>
                </Flex>
                <FilterLayout
                    query={useGetTokens}
                    options={tokenFilterOptions}
                    onQuerySuccess={onQuerySuccess}
                    cache={CacheKeys.TOKENS}
                    sortOptions={tokenSortOptions}
                >
                    {width &&
                        <Component
                            data={tokens}
                        />
                    }
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default Tokens;
