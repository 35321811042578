import { formatNumber } from "../../../core/utils/common"
import Text from "../../../core/utils/Text"
import {DText} from "../../../styles/CommonStyles"


const PriceNumber = (props) => {

    const {
        num,
        coin = 'usd'
    } = props

    return (
        <DText primary style={{direction: 'ltr'}} fontSize='s' {...props}>
            {formatNumber(num)}{" "}({coin?.toUpperCase()})
        </DText>
    )
}


export default PriceNumber
