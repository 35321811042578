import MotionFade from "../../../../common/utils/MotionFade";
import {useGetThirdBalances} from "../../../../../core/services/react-query/setting/thirdparty";
import {useEffect, useMemo} from "react";
import BinanceTable from "../thirdBalances/BinanceTable";
import KucoinTable from "../thirdBalances/KucoinTable";
import NobitexTable from "../thirdBalances/NobitexTable";


const ThirdBalances = ({
    third
}) => {
    const { data: balances, refetch: getBalances, isFetching: fetchingBalances } = useGetThirdBalances(third)
    useEffect(() => {
        if (third) getBalances()
    },[third])

    const tables = {
        binance: BinanceTable,
        kucoin: KucoinTable,
        nobitex: NobitexTable
    }

    const providable = useMemo(() => {
        if (!!balances && !!third) {
            if (third === 'nobitex') {
                let res = []
                Object.keys(balances?.response?.wallets || {}).forEach(w => {
                    const curr = balances.response.wallets[w]
                    res.push({
                        coin: w,
                        balance: curr.balance,
                        blocked: curr.blocked,
                        id: curr.id
                    })
                })
                return res
            }
            if (third === 'kucoin') {
                return balances?.data
            }
            return balances
        }
        return []
    }, [balances, third])

    if (!third) return <></>
    const Table = tables[third]

    return (
        <MotionFade>
            <div style={{ height: '20px' }} />
            <Table
                data={{ data: { data: providable }, loading: fetchingBalances }}
            />
        </MotionFade>
    )
}



export default ThirdBalances
