import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import OtcTradesTable from "./OtcTradesTable";
import ROtcTradesTable from "../../../../responsive/main/reports/orders/ROtcTradesTable";


const OtcTrades = (props) => {

    const { width } = useWindowSize()

    const Component = width > TABLET_SIZE ? OtcTradesTable : ROtcTradesTable
    return (
        <>
            {width ?
                <Component {...props} />
                :
                <></>
            }
        </>
    )

}


export default OtcTrades
