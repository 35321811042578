import styled, {css} from "styled-components";
import {DText, Flex} from "../../../../../styles/CommonStyles";
import CheckBox from "../../../../common/input/CheckBox";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import {UpdateCoinTagWrapper} from "../../../../../styles/main/setting";


const UpdateCoinTag = ({
    coin,
    activeCoins,
    onCoinStateChange
}) => {

    const isActive = activeCoins?.includes(coin)

    return (
        <UpdateCoinTagWrapper active={isActive}>
            <Flex fw fh justify={'space-between'}>
                <CheckBox
                    active={isActive}
                    onClick={() => onCoinStateChange(coin, isActive)}
                />
                <Flex>
                    <img
                        src={SOCKET_URL + `assets/icon/${coin}.png`}
                        alt={' '}
                        width='28px'
                        height='28px'
                    />
                    <DText main style={{ margin: '0 4px' }}>
                        {coin.toUpperCase()}
                    </DText>
                </Flex>
            </Flex>
        </UpdateCoinTagWrapper>
    )
}

export default UpdateCoinTag
