import styled from "styled-components";
import {SkeletonAvatar, SKeletonText} from "../../core/packages/skeleton/elements";
import Shimmer from "../../core/packages/skeleton/Shimmer";
import {useWindowSize} from "../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../core/constants/common";


const SidebarSkeleton = () => {

    const { width } = useWindowSize()
    return (
        <Wrapper>
            <SkeletonAvatar />
            {width > TABLET_SIZE &&
                <SKeletonText width='60%' margin={'0 10px'} />
            }
            <Shimmer />
        </Wrapper>
    )
}


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 52px;
  width: 80%;
  padding: 12px;
  margin: 20px 0;
  border-radius: 4px;
  background-color: ${props => props.theme.skeletonBg};
  position: relative;
  overflow: hidden;
`


export default SidebarSkeleton
