import NoData from "../../../../common/utils/NoData";
import {useRemoveUser2fa} from "../../../../../core/services/react-query/user";
import { ReactComponent as RemoveSvg } from "../../../../../assets/illustrations/setting/remove-2fa.svg";
import {CFlex, CMargin, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import ActionButton from "../../../../common/buttons/ActionButton";
import {useEffect, useState} from "react";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";


const Remove2fa = ({ user, refetch }) => {

    const Has2fa = !!user?.authenticator
    const { mutate: remove2fa, isSuccess, isLoading } = useRemoveUser2fa()

    const [modal, setModal] = useState(false)
    const onModalClose = () => setModal(false)
    const onModalOpen = () => setModal(true)

    const onRemove2fa = () => {
        remove2fa(user?._id)
    }

    useEffect(() => {
        if (isSuccess) {
            refetch()
            onModalClose()
        }
    }, [isSuccess])

    return (
        <>
            {Has2fa ?
                <CFlex fw>
                    <CMargin margin={'10px'} />
                    <DText main>
                        <Text tid={'remove-2fa'} />
                    </DText>
                    <CFlex width={'300px'}>
                        <RemoveSvg width={'100%'} height={'300px'} />
                        <ActionButton
                            onClick={onModalOpen}
                            height={'42px'}
                            active
                        >
                            <Text tid={'remove-2fa'} />
                        </ActionButton>
                    </CFlex>

                </CFlex>
                :
                <NoData desc={'no-2fa-active'}/>
            }

            <ModalLayout
                open={modal}
                width={'520px'}
                onClose={onModalClose}
            >
                <BasicModal
                    head={`remove-2fa`}
                    _id={user?._id}
                    onClose={onModalClose}
                    onSubmit={onRemove2fa}
                    loading={isLoading}
                />
            </ModalLayout>
        </>
    )
}

export default Remove2fa
