import TableLayout from "../../layout/main/TableLayout";
import {Column, DText, Flex, Relative, Row} from "../../../styles/CommonStyles";
import {formatName, formatNumber, getNames} from "../../../core/utils/common";
import {DocumentStatus, UserStatus} from "../../../styles/main/profile";
import Text from "../../../core/utils/Text";
import Operation from "../../common/utils/Operation";
import Avatar from "../../common/utils/Avatar";
import useUsersList from "../../../core/hooks/main/users/useUsersList";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import {acceptUserOperations, rejectUserOperations} from "../../../core/constants/operation";
import {userAttributes} from "../../../core/constants/headers";
import UserLink from "../../common/utils/UserLink";
import {CgClose} from "react-icons/cg";


const UsersTable = ({
    data
}) => {

    const { data: users } = data
    const { cs, headers } = userAttributes
    const { getTableIndex } = useGetTableIndex()

    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked
    } = useUsersList()

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess={hasWriteAccess}
        >
            {users?.data?.map((user, idx) => {

                const DOCUMENT = !!user.idCard && !!user.selfi
                // const STATUS = !!user.verifyAt

                const {
                    FULLNAME, NAMEAVATAR
                } = getNames(
                    user.firstName,
                    user.lastName
                )

                return(
                    <Relative>
                        <Row cs={cs} index={idx} key={user._id}>
                            <Column>
                                {getTableIndex(idx)}
                            </Column>
                            <Column avatar>
                                <Flex width='42px'>
                                    <Avatar
                                        name={NAMEAVATAR}
                                        avatar={user.avatar}
                                    />
                                </Flex>

                                <DText fontSize={'s'} main>
                                    {formatName(FULLNAME ? FULLNAME : '--')}
                                </DText>
                            </Column>
                            <Column>
                                {user.idNo? user.idNo: '--'}
                            </Column>
                            <Column>
                                {user.mobile ? user.mobile : '--'}
                            </Column>
                            <Column>
                                {formatNumber(user.balance)}
                            </Column>
                            <Column>
                                {user.email ? user.email : '--'}
                            </Column>
                            <Column>
                                <UserStatus active={user.status === 'VERIFIED'}>
                                    <Text tid={user.status} />
                                </UserStatus>
                            </Column>
                            <Column>
                                <DocumentStatus active={DOCUMENT}>
                                    <Text tid={DOCUMENT ? 'complete-document' : 'incomplete-document'} />
                                </DocumentStatus>
                            </Column>
                            <Column>
                                {!!user.verifyBy ?
                                    <UserLink
                                        _id={user.verifyBy}
                                        name={formatName(user.verifyBy, 4, 2)}
                                    />
                                    :
                                    <CgClose color={'#e9106c'} size={14} />
                                }

                            </Column>
                        </Row>
                        <Column operation>
                            <Operation
                                options={user.verifyAt ? rejectUserOperations : acceptUserOperations}
                                onOptionClicked={(idx) => onOptionClicked(idx, user)}
                                onDetailsClicked={() => onDetailsClicked(user)}
                                hasWriteAccess={hasWriteAccess}
                                hasDetails
                            />
                        </Column>
                    </Relative>

                )
            })}
        </TableLayout>
    )
}



export default UsersTable
