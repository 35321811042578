import useBankAccounts from "../../../../core/hooks/main/bank-accounts/useBankAccounts";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import RTableLayout from "../../layout/RTableLayout";
import UserBankActions from "../../../main/users/UserBankActions";
import {CFlex, CMargin, DText, Flex, Row} from "../../../../styles/CommonStyles";
import Avatar from "../../../common/utils/Avatar";
import {bankAccountOperations} from "../../../../core/constants/operation";
import Operation from "../../../common/utils/Operation";
import Text from "../../../../core/utils/Text";
import {formatCardNumber, formatName, getNames} from "../../../../core/utils/common";
import {LineBreak, MiniLineBreak, RespIndex} from "../../../../styles/main/MainCommonStyles";
import {TradeTypeBadge} from "../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../core/utils/theme";
import UserLink from "../../../common/utils/UserLink";
import {useMemo} from "react";



const RBankAccountsTable = ({ data }) => {

    const { getTableIndex } = useGetTableIndex()
    const { data: banks } = data

    const {
        hasWriteAccess,
        actionMode,
        closeActionMode,
        onOptionClicked
    } = useBankAccounts()

    const banksOnBoard = useMemo(() => {
        let temp = []
        banks?.data?.forEach(bank => {
            bank.banks?.forEach(item => {
                if (item.status === 'PROCESSING')
                    temp.push({...item, bank})
            })
        })
        return temp
    }, [banks])

    return (
        <>
            {actionMode.show ?
                <UserBankActions
                    mode={actionMode}
                    closeActionMode={closeActionMode}
                />
                :
                <RTableLayout
                    data={data}
                >
                    {banksOnBoard.map((item, idx) => {
                        const { FULLNAME } = getNames(item.bank.firstName, item.bank.lastName)
                        return (
                            <Row cs={'100%'} index={idx}>
                                <CFlex fw>
                                    <Flex fw justify={'space-between'}>
                                        <Flex justify={'flex-start'} style={{ minWidth: '70%' }}>
                                            <Avatar
                                                avatar={item.bank.avatar}
                                                name={item.bank.firstName}
                                            />
                                            <UserLink
                                                _id={item.bank._id}
                                                name={formatName(FULLNAME)}
                                            />
                                        </Flex>
                                        <Operation
                                            options={bankAccountOperations}
                                            onOptionClicked={(idx) => onOptionClicked(idx, item)}
                                            hasWriteAccess={hasWriteAccess}
                                        />
                                    </Flex>
                                    <CMargin margin={'10px'} />
                                    <Flex fw justify={'space-between'}>
                                        <DText main fontSize={'ss'}>
                                            <Text tid={'card-number'} />
                                        </DText>
                                        <DText primary fontSize={'ss'}>
                                            {formatCardNumber(item.cardNo)}
                                        </DText>
                                    </Flex>

                                    <CMargin margin={'4px'} />
                                    <LineBreak />
                                    <CMargin margin={'6px'} />

                                    <Flex fw justify={'space-between'}>
                                        <DText main fontSize={'ss'}>
                                            <Text tid={'sheba-number'} />
                                        </DText>
                                        <DText primary fontSize={'ss'}>
                                            {item.shebaNo}
                                        </DText>
                                    </Flex>

                                    <CMargin margin={'4px'} />
                                    <LineBreak />
                                    <CMargin margin={'6px'} />

                                    <Flex fw justify={'space-between'}>
                                        <DText main fontSize={'ss'}>
                                            <Text tid={'bank-label'} />
                                        </DText>
                                        <DText primary fontSize={'ss'}>
                                            {item.label}
                                        </DText>
                                    </Flex>

                                    <CMargin margin={'4px'} />
                                    <LineBreak />
                                    <CMargin margin={'6px'} />

                                    <Flex fw justify={'space-between'}>
                                        <DText main fontSize={'ss'}>
                                            <Text tid={'user-status'} />
                                        </DText>
                                        <TradeTypeBadge color={getTradeTypeColor(item.bank.verifyAt ? 'success' : 'error')}>
                                            <Text tid={item.bank.verifyAt ? 'verified' : 'not-verified'} />
                                        </TradeTypeBadge>
                                    </Flex>

                                    <CMargin margin={'4px'} />
                                    <LineBreak />
                                    <CMargin margin={'6px'} />
                                    <Flex fw justify={'space-between'}>
                                        <DText main fontSize={'ss'}>
                                            <Text tid={'replyBy'} />
                                        </DText>
                                        {item.verifyBy ?
                                            <UserLink
                                                _id={item.verifyBy}
                                                name={formatName(item.verifyBy, 6, 2)}
                                            />
                                            :
                                            <TradeTypeBadge
                                                color={getTradeTypeColor(item.verifyAt ? 'success' : 'error')}>
                                                <Text tid={item.verifyAt ? 'verified' : 'not-verified'}/>
                                            </TradeTypeBadge>
                                        }
                                    </Flex>

                                    <RespIndex>
                                        {getTableIndex(idx)}
                                    </RespIndex>
                                </CFlex>
                            </Row>
                        )
                    })}
                </RTableLayout>
            }
        </>
    )
}


export default RBankAccountsTable
