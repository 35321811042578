import { useState } from "react";
import SettingDetailsLayout from "../../../components/layout/main/SettingDetailLayout";
import AvailableCoinsDetail from "../../../components/main/setting/available-coins/AvailableCoinsDetail";
import { useAvailableCoins, useGetPredefinedCoins } from "../../../core/services/react-query/setting"
import {useGetAllTokens} from "../../../core/services/react-query/setting/token";


const AvailableCoins = () => {

    const initialState = { data: null, loading: true }
    const [data, setData] = useState(initialState)
    const [coin, setCoin] = useState(null)

    const onQuerySuccess = (res) => setData(res)
    const { data: predefinedCoins } = useGetPredefinedCoins()
    const { data: tokens } = useGetAllTokens()


    return (
        <SettingDetailsLayout
            query={useAvailableCoins}
            onQuerySuccess={onQuerySuccess}
        >
            <AvailableCoinsDetail
                data={data}
                predefinedCoins={predefinedCoins}
                tokens={tokens}
                coinState={{coin, setCoin}}
            />
        </SettingDetailsLayout>
    )
}

export default AvailableCoins;
