import {DText} from "../../../styles/CommonStyles";
import {FilterIcon, AddFilterBtn} from "../../../styles/layout/filter";
import Text from "../../../core/utils/Text";
import {useMainContext} from "../../../core/contexts/main";
import FilterBox from "./FilterBox";
import {useEffect, useRef, useState} from "react";
import useClickOutside from "../../../core/hooks/common/useClickOutside";
import {AnimatePresence} from "framer-motion";
import FilterDropdown from "./FilterDropdown";
import {useFilterContext} from "../../../core/contexts/filter";
import {CloseIcon, OpenIcon} from "../../../styles/components/common/select-styles";


const AddFilter = ({
    options = [],
    hasSort
}) => {

    const { lang } = useMainContext()
    const { filters, cache } = useFilterContext()

    const [ selectedFilters, setSelectedFilters ] = useState([])

    const [filterBoxOpen, setFilterBoxOpen] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [filter, setFilter] = useState(null)

    const onFilterClose = () => {
        if (filterBoxOpen) setFilterBoxOpen(false)
        if (dropdownOpen) setDropdownOpen(false)
    }

    const boxRef = useRef()
    useClickOutside(boxRef, onFilterClose)

    const onOptionClicked = (idx) => {
        setFilterBoxOpen(true)
        setFilter(options[idx])
    }

    useEffect(() => {
        if (!cache) return
        const temp = Object.keys(filters[cache])
        if (filters.firstName || filters.lastName ) temp.push('name')
        setSelectedFilters(temp)
    }, [filters])

    return (
        <>
            <div style={{ position: 'relative'}}>
                <div
                    ref={boxRef}
                >
                    <Controller
                        onClick={() => setDropdownOpen(state => !state)}
                        open={dropdownOpen}
                        hasSort={hasSort}
                    />
                    {dropdownOpen &&
                        <>
                            <FilterDropdown
                                open={dropdownOpen}
                                options={options.map(item => item.name)}
                                onOptionClicked={onOptionClicked}
                                Controller={Controller}
                                minWidth='160px'
                                right={lang === 'fa' && '0'}
                                selected={selectedFilters}
                            />
                            <AnimatePresence exitBeforeEnter>
                                {filterBoxOpen &&
                                    <FilterBox
                                        type={'create'}
                                        filter={filter}
                                        onClose={onFilterClose}
                                    />
                                }
                            </AnimatePresence>
                        </>
                    }
                </div>
            </div>

        </>
    )
}


const Controller = (props) => {

    const { open, hasSort } = props

    return (
        <AddFilterBtn {...props}>
            <FilterIcon size={18}/>
            {!hasSort &&
                <>
                    <DText primary fontSize={'s'}>
                        <Text tid='add-filter' />
                    </DText>
                    {open ?
                        <CloseIcon size={20} />
                        :
                        <OpenIcon size={20} />
                    }
                </>
            }
        </AddFilterBtn>
    )
}

export default AddFilter
