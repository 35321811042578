import MotionFade from "../../../common/utils/MotionFade"
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../../../styles/CommonStyles"
import Input from "../../../common/input/Input"
import Text from "../../../../core/utils/Text"

/**
 * @param detail - { min, max, makerFeeFactor, takerFeeFactor, makerFeeMax, takerFeeMax }
 * @param type - { order - otc }
 * @param onDetailChange - { update detail }
 * @param currency - { currency of details }
 * @returns {JSX.Element}
 * @constructor
 */
const OrderUpdate = ({
    detail,
    type,
    onDetailChange,
    currency
}) => {


    return (
        <MotionFade>
            {detail &&
               <CFlex fw align='flex-start'>
                    <CMargin margin='4px' />
                    <Flex fw wrap justify='flex-start'>
                        <Background bg='secondaryBg'>
                            <Input
                                value={detail.min}
                                label={'trade-min'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'min'
                                })}
                                minWidth='260px'
                                width='50%'
                                currency={currency}
                                number
                            />
                            <Input
                                value={detail.max}
                                label={'trade-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'max'
                                })}
                                minWidth='260px'
                                width='50%'
                                currency={currency}
                                number
                            />
                        </Background>
                    </Flex>

                    <Flex fw wrap justify='flex-start'>
                        <Background bg='secondaryBg'>
                            <Input
                                value={detail.makerFeeFactor}
                                label={'maker-fee-factor'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'makerFeeFactor'
                                })}
                                minWidth='260px'
                                width='50%'
                                number
                            />
                            <Input
                                value={detail.takerFeeFactor}
                                label={'taker-fee-factor'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'takerFeeFactor'
                                })}
                                minWidth='260px'
                                width='50%'
                                number
                            />
                        </Background>
                    </Flex>

                    <CMargin margin='4px' />
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid='available-order-note' />
                        </DText>
                    </Flex>
                    <CMargin margin='10px' />
                    <Flex fw wrap justify='flex-start'>
                        <Background bg='secondaryBg'>
                            <Input
                                value={detail.makerFeeMax}
                                label={'maker-fee-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'makerFeeMax'
                                })}
                                minWidth='260px'
                                width='50%'
                                currency={currency}
                                number
                            />
                            <Input
                                value={detail.takerFeeMax}
                                label={'taker-fee-max'}
                                onInputChange={(v) => onDetailChange({
                                    type,
                                    value: v,
                                    key: 'takerFeeMax'
                                })}
                                minWidth='260px'
                                width='50%'
                                currency={currency}
                                number
                            />
                        </Background>
                    </Flex>
                </CFlex>
            }
        </MotionFade>
    )
}


export default OrderUpdate
