import {useParams} from "react-router-dom";
import NoData from "../../../../common/utils/NoData"
import { useGetATrade } from "../../../../../core/services/react-query/report/orders";
import {
    DetailsWrapper,
    DetailsBody,
    DetailsBorder,
    RightHeadDecoration,
    LeftHeadDecoration,
} from "../../../../../styles/main/MainCommonStyles";
import Skeleton from "../../../../../core/packages/skeleton";
import DetailSkeleton from "../../../../skeleton/DetailSkeleton";
import CoinMarket from "../../../../common/common/CoinMarket";
import {CFlex, CMargin, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {formatDate, formatNumber} from "../../../../../core/utils/common";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import AmountBar from "../../../../common/charts/AmountBar";
import {useMainContext} from "../../../../../core/contexts/main";
import {BuyIcon, SellIcon, TradeAmount, TradeTypeBadge} from "../../../../../styles/main/orders";
import DetailsLayout from "../../../../layout/main/DetailsLayout";


const TradeDetails = () => {

    const { lang } = useMainContext()
    const params = useParams()
    const { data: trade, isFetching, isError } = useGetATrade(params.detailId)

    return (
        <DetailsLayout
            isFetching={isFetching}
            isError={isError}
        >

            <RightHeadDecoration>
                <CoinMarket
                    coin={trade?.order?.coin}
                    pair={trade?.order?.pair}
                    detail
                    noName
                />
            </RightHeadDecoration>
            <LeftHeadDecoration left={'10px'}>
                <DText main>
                    {params.detailId}
                </DText>
            </LeftHeadDecoration>

            {/* content */}
            <DText fontSize={'b'} primary>
                {trade?.order?.coin?.toUpperCase()}/{trade?.order?.pair?.toUpperCase()}
            </DText>

            <Flex justify={'space-between'} wrap>
                <CFlex width={'50%'} style={{ minWidth: '280px' }}>
                    <CMargin margin={'8px'} />
                    <Flex fw justify={'space-between'}>
                        <DText primary>
                            <Text tid={'unitPrice'} /> :
                        </DText>
                        <DText primary>
                            {formatNumber(trade?.priceUnit)}
                        </DText>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <Flex fw justify={'space-between'}>
                        <DText primary>
                            <Text tid={'price'} /> :
                        </DText>
                        <DText primary>
                            {formatNumber(trade?.price)}
                        </DText>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <Flex fw justify={'space-between'}>
                        <DText primary>
                            <Text tid={'trade-type'} /> :
                        </DText>
                        <TradeTypeBadge color={getTradeTypeColor(trade?.order?.submit)}>
                            {trade?.order?.submit}
                        </TradeTypeBadge>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <Flex fw justify={'space-between'}>
                        <DText primary>
                            <Text tid={'transaction-type'} /> :
                        </DText>
                        <Flex>
                            {trade?.order?.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                            <Flex style={{ margin: '0 7px' }}>
                                <TradeAmount type={trade?.order?.type}>
                                    <Text tid={trade?.order?.type} />
                                </TradeAmount>
                            </Flex>
                        </Flex>
                    </Flex>
                </CFlex>
                <AmountBar
                    type={trade?.order?.type}
                    amount={0}
                    tradedAmount={trade?.amount}
                />
            </Flex>
            <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                <DText main>
                    {formatDate(trade?.order?.createdAt, 'date', lang)}
                </DText>
                <DText main>
                    {formatDate(trade?.order?.createdAt, 'time', lang)}
                </DText>
            </Flex>

        </DetailsLayout>
    )
}

export default TradeDetails
