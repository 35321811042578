import {DText} from "../../../styles/CommonStyles";


const Address = ({ address, ...rest }) => {

    return (
        <DText primary {...rest}>
            {`${address?.substring(0, 4)}...${address?.substring(address.length - 4)}`}
        </DText>
    )
}

export default Address
