import {useAclContext} from "../../../../../core/contexts/acl";
import {Background, CFlex, CMargin, DText} from "../../../../../styles/CommonStyles";
import {ReactComponent as NoAccess} from "../../../../../assets/illustrations/setting/overall.svg";
import Text from "../../../../../core/utils/Text";
import {useCreateNotification, useGetNotificationConfig} from "../../../../../core/services/react-query/notification";
import Input from "../../../../common/input/Input";
import {useCallback, useEffect, useState} from "react";
import Select from "../../../../common/dropdown/Select";
import ActionButton from "../../../../common/buttons/ActionButton";
import {useQueryContext} from "../../../../../core/contexts/query";
import {deepCopy} from "../../../../../core/utils/common";
import {TextArea, UserDetailSettingBox} from "../../../../../styles/main/user";


const SendNotification = ({ user }) => {

    const { setToast } = useQueryContext()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions?.notification?.write

    const initialState = {
        title: '',
        body: '',
        type: '',
        priority: '',
        receptorId: user?._id
    }
    const [notifData, setNotifData] = useState(initialState)

    const { data: config } = useGetNotificationConfig()
    const { mutate: sendNotif, isLoading: sendingNotif, isSuccess } = useCreateNotification()

    const [configTypes, setConfigTypes] = useState([])
    const [configPriorities, setConfigPriorities] = useState([])
    const [sendIsValid, setSendIsValid] = useState(false)


    useEffect(() => {
        if (config) {
            setConfigTypes(Object.keys(config.type))
            setConfigPriorities(Object.keys(config.priority))
        }
    }, [config])

    useEffect(() => {
        const valid = notifData.title &&
            notifData.body &&
            notifData.type &&
            notifData.priority
        setSendIsValid(valid)
    }, [notifData])

    const onInputValueChange = useCallback((value, type) => {
        const newNotifData = deepCopy(notifData)
        newNotifData[type] = value
        setNotifData(newNotifData)
    }, [notifData])

    const onSendNotification = () => {
        if (!sendIsValid) {
            setToast({
                isError: true, show: true,
                message: 'fill-inputs-error'
            })
        }else {
            sendNotif(notifData)
        }
    }

    useEffect(() => {
        if (isSuccess) {
            setToast({
                show: true,
                message: 'notification-sent-success'
            })
            setNotifData(initialState)
        }
    }, [isSuccess])

    return (
        <CFlex fw>
            <CMargin margin={'10px'} />
            {hasWriteAccess ?
                <UserDetailSettingBox>
                    <Background bg={'mainBg'}>
                        <DText main>
                            <Text tid={'notif-title'} />
                        </DText>
                        <Input
                            value={notifData.title}
                            onInputChange={(v) => onInputValueChange(v, 'title')}
                            size={'small'}
                            padding={'0'}
                        />
                        <DText main>
                            <Text tid={'notif-body'} />
                        </DText>
                        <CMargin margin={'6px'} />
                        <TextArea
                            value={notifData.body}
                            onChange={(e) => onInputValueChange(e?.target?.value, 'body')}
                        />
                        <CMargin margin={'8px'} />
                        <DText main>
                            <Text tid={'notif-type'} />
                        </DText>
                        <CMargin margin={'4px'} />
                        <Select
                            options={configTypes}
                            value={notifData.type}
                            onValueChange={(idx) => onInputValueChange(configTypes[idx], 'type')}
                            width={'100%'}
                            height={'42px'}
                            direction={'top'}
                        />
                        <CMargin margin={'8px'} />
                        <DText main>
                            <Text tid={'priority'} />
                        </DText>
                        <CMargin margin={'4px'} />
                        <Select
                            options={configPriorities}
                            value={notifData.priority}
                            onValueChange={(idx) => onInputValueChange(configPriorities[idx], 'priority')}
                            width={'100%'}
                            height={'42px'}
                            direction={'top'}
                        />
                        <CMargin margin={'20px'} />
                        <ActionButton
                            active={sendIsValid}
                            loading={sendingNotif}
                            onClick={onSendNotification}
                        >
                            <Text tid={'send-notif'} />
                        </ActionButton>
                    </Background>
                </UserDetailSettingBox>

                :
                <>
                    <NoAccess
                        width={'300px'}
                        height={'300px'}
                    />
                    <DText main style={{ direction: 'ltr' }}>
                        <Text tid={'no-access'} />
                    </DText>
                </>
            }
        </CFlex>
    )
}


export default SendNotification
