import {useMutation, useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {useMainContext} from "../../../contexts/main";
import {makeQueryFilters} from "../../../utils/query-maker";
import {useQueryContext} from "../../../contexts/query";
import {postApi} from "../../fetch-api/post";


export const fullnodeKeys = {
    getFullnodeStats: 'getFullnodeStats',
    getFullnodeList: 'getFullnodeList',
    getAFullnodeItem: 'getAFullnodeItem',
    moveToMaster: 'moveToMaster',
    resyncAddress: 'resyncAddress'
}

export const useGetFullnodeStats = () => {

    return useQuery(
        fullnodeKeys.getFullnodeStats, () => normalFetch('settings/fullNode/transactions/statics'),
        {
            select: res => res?.data?.data
        }
    )
}


export const useGetFullnodeList = (filters, pagination, extra) => {
    const { lang } = useMainContext()

    const newFilters = {...extra, ...filters}
    const params = makeQueryFilters(newFilters, lang)
    const newParams = {...params, ...pagination}

    return useQuery(
        fullnodeKeys.getFullnodeList, () => filterFetch(newParams, 'settings/fullNode/transactions'),
        {
            select: res => res?.data?.data
        }
    )
}

export const useGetAFullnodeItem = (id) => {

    return useQuery(
        [fullnodeKeys.getAFullnodeItem, id], () => normalFetch(`settings/fullNode/transactions/${id}`),
        {
            select: res => res?.data?.data?.data
        }
    )
}

export const useMoveToMaster = () => {

    const { setToast } = useQueryContext()

    return useMutation(
        fullnodeKeys.moveToMaster, (data) => postApi(data, 'settings/fullnode/wallet'),
        {
            onSuccess: () => {
                setToast({
                    message: 'operation-success',
                    show: true
                })
            }
        }
    )
}

export const useResyncAddress = () => {

    const { setToast } = useQueryContext()

    return useMutation(
        fullnodeKeys.resyncAddress, (data) => postApi(data, 'settings/fullNode/address'),
        {
            onSuccess: () => {
                setToast({
                    message: 'operation-success',
                    show: true
                })
            }
        }
    )
}
