import { useParams } from "react-router-dom"
import NoData from "../../../components/common/utils/NoData"
import DetailSkeleton from "../../../components/skeleton/DetailSkeleton"
import { useMainContext } from "../../../core/contexts/main"
import Skeleton from "../../../core/packages/skeleton"
import { useGetAnAffiliate } from "../../../core/services/react-query/report/trace"
import {formatDate, formatName, getNames} from "../../../core/utils/common"
import Text from "../../../core/utils/Text"
import { DText, Flex } from "../../../styles/CommonStyles"
import { DetailRow, DetailsBody, DetailsBorder, DetailsWrapper, LeftHeadDecoration } from "../../../styles/main/MainCommonStyles"
import {useEffect, useState} from "react";
import {useGetAUser} from "../../../core/services/react-query/user";
import DetailsLayout from "../../../components/layout/main/DetailsLayout";
import Avatar from "../../../components/common/utils/Avatar";
import UserLink from "../../../components/common/utils/UserLink";
import ShareBar from "../../../components/main/reports/affiliates/ShareBar";


const AffiliateListDetails = () => {

    const params = useParams()
    const { lang } = useMainContext()
    const { data: affiliate, isFetching, isError } = useGetAnAffiliate(params.id)

    const [userId, setUserId] = useState(null)
    const { data: user, refetch: getUser } = useGetAUser(userId)

    useEffect(() => {
        if (affiliate) setUserId(affiliate.userId)
    }, [affiliate])

    useEffect(() => {
        if (userId) getUser()
    }, [userId])

    const { FULLNAME, NAMEAVATAR } = getNames(user?.firstName, user?.lastName)

    return (
        <DetailsLayout
            isFetching={isFetching}
            isError={isError}
        >
            <LeftHeadDecoration left={'10px'}>
                <DText main>
                    {params.id}
                </DText>
            </LeftHeadDecoration>

            <DetailRow noBorder style={{ marginBottom: '20px' }}>
                <Flex justify={'flex-start'}>
                    <Avatar
                        avatar={user?.avatar}
                        name={NAMEAVATAR}
                    />
                    <UserLink
                        _id={user?._id}
                        name={formatName(FULLNAME)}
                    />
                </Flex>
                <Flex justify={'flex-end'}>
                    <ShareBar
                        affiliate={affiliate}
                    />
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'affiliate-code'} /> :
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary>
                        {affiliate?.code}
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'max-use'} /> :
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary>
                        {affiliate?.maxUse}
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow noBorder>
                <DText main>
                    <Text tid={'use-count'} /> :
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary>
                        {affiliate?.used}
                    </DText>
                </Flex>
            </DetailRow>
        </DetailsLayout>
    )
}


export default AffiliateListDetails
