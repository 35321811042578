import { TABLET_SIZE } from "../../../../../core/constants/common"
import { useWindowSize } from "../../../../../core/hooks/common/useWindowSize"
import RCurrentOrders from "../../../../responsive/main/reports/orders/RCurrentOrders"
import CurrentOrders from "./CurrentOrders"


const Orders = (props) => {

    const { width } = useWindowSize()

    const Component = width > TABLET_SIZE ? CurrentOrders : RCurrentOrders
    return (
        <>
            {width ?
                <Component {...props} />
                :
                <></>
            }
        </>
    )

}

export default Orders
