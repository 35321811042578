import {Flex} from "../../../styles/CommonStyles";
import styled from "styled-components";
import Text from "../../../core/utils/Text";


const NobitexPagination = ({ pagination, onNext, onBack, hasNext }) => {

    const onBackClick = () => {
        if (pagination?.page > 1) onBack()
    }

    const onNextClick = () => {
        if (hasNext) onNext()
    }

    return (
        <Flex style={{ direction: 'ltr', gap: '8px' }}>
            <Btn active={pagination?.page > 1} onClick={onBackClick}>
                <Text tid={'last-page'} />
            </Btn>
            <PageWrap>
                <span>{pagination?.page || 0}</span>
            </PageWrap>
            <Btn active={hasNext} onClick={onNextClick}>
                <Text tid={'next-page'} />
            </Btn>
        </Flex>
    )
}

const Btn = styled.div`
  width: 114px;
  height: 32px;
  border-radius: 8px;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.active ? 'pointer' : 'not-allowed'};
  background: ${props => props.active ? props.theme.mainOrange : `${props.theme.mainOrange}50`};
`

const PageWrap = styled.div`
  border: 1px solid ${props => props.theme.mainOrange};
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color};
`

export default NobitexPagination
