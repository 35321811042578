import RobotsView from "../../../components/main/setting/robot/RobotsView";


const Robots = () => {


    return (
        <RobotsView />
    )
}

export default Robots;
