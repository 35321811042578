import {CFlex} from "../../../styles/CommonStyles";
import {SorDown, SortUp} from "../../../styles/layout";

/**
 * @param active - 1, -1, false
 * @param rest
 * @return {JSX.Element}
 * @constructor
 */
const SortButton = ({ active, ...rest }) => {

    return (
        <CFlex {...rest}>
            <SortUp active={active === 1} />
            <SorDown active={active === -1} />
        </CFlex>
    )
}


export default SortButton
