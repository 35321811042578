import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import OtcView from "../../../components/main/setting/otc/OtcView";


const Otc = () => {

    return (
        <ListLayout>
            <PageCard>
                <OtcView />
            </PageCard>
        </ListLayout>
    )
}

export default Otc;
