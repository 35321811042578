import {useParams} from "react-router-dom";
import {useGetAnOtcTrade, useGetOtcThirdparty} from "../../../../../core/services/react-query/report/orders";
import {
    RightHeadDecoration,
    LeftHeadDecoration, DetailRow
} from "../../../../../styles/main/MainCommonStyles";
import CoinMarket from "../../../../common/common/CoinMarket";
import {CFlex, CMargin, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {formatDate, formatNumber} from "../../../../../core/utils/common";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import AmountBar from "../../../../common/charts/AmountBar";
import {useMainContext} from "../../../../../core/contexts/main";
import {BuyIcon, SellIcon, TradeAmount, TradeTypeBadge} from "../../../../../styles/main/orders";
import DetailsLayout from "../../../../layout/main/DetailsLayout";
import {useEffect, useState} from "react";


const OtcTradeDetails = () => {

    const { lang } = useMainContext()
    const params = useParams()
    const { data: trade, isFetching, isError } = useGetAnOtcTrade(params.detailId)

    const [ orderId, setOrderId ] = useState(null)
    const { data: party, refetch, isFetching: fetchingParty, isError: partyError } = useGetOtcThirdparty(orderId)

    useEffect(() => {
        if (trade) {
            const partyResponse = trade.party?.result?.response
            if (partyResponse?.code === "200000") {
                setOrderId(partyResponse?.data?.orderId)
            }
        }
    }, [trade])

    useEffect(() => {
        if (orderId) refetch()
    }, [orderId])


    return (
        <>
            <DetailsLayout
                isFetching={isFetching}
                isError={isError}
            >
                <RightHeadDecoration>
                    <CoinMarket
                        coin={trade?.coin}
                        pair={'usd'}
                        detail
                        noName
                    />
                </RightHeadDecoration>
                <LeftHeadDecoration left={'10px'}>
                    <DText main>
                        {params.detailId}
                    </DText>
                </LeftHeadDecoration>

                {/* content */}
                <DText fontSize={'b'} primary>
                    {trade?.coin?.toUpperCase()}/USD
                </DText>

                <Flex justify={'space-between'} wrap>
                    <CFlex width={'50%'} style={{ minWidth: '280px' }}>
                        <CMargin margin={'8px'} />
                        <Flex fw justify={'space-between'}>
                            <DText primary>
                                <Text tid={'unitPrice'} /> :
                            </DText>
                            <DText primary>
                                {formatNumber(trade?.priceUnit)}
                            </DText>
                        </Flex>
                        <CMargin margin={'6px'} />
                        <Flex fw justify={'space-between'}>
                            <DText primary>
                                <Text tid={'price'} /> :
                            </DText>
                            <DText primary>
                                {formatNumber(trade?.price)}
                            </DText>
                        </Flex>
                        <CMargin margin={'6px'} />
                        <Flex fw justify={'space-between'}>
                            <DText primary>
                                <Text tid={'trade-type'} /> :
                            </DText>
                            <TradeTypeBadge color={getTradeTypeColor(trade?.order?.submit)}>
                                OTC
                            </TradeTypeBadge>
                        </Flex>
                        <CMargin margin={'6px'} />
                        <Flex fw justify={'space-between'}>
                            <DText primary>
                                <Text tid={'transaction-type'} /> :
                            </DText>
                            <Flex>
                                {trade?.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                                <Flex style={{ margin: '0 7px' }}>
                                    <TradeAmount type={trade?.type}>
                                        <Text tid={trade?.type} />
                                    </TradeAmount>
                                </Flex>
                            </Flex>
                        </Flex>
                        <CMargin margin={'6px'} />
                        <Flex fw justify={'space-between'}>
                            <DText primary>
                                <Text tid={'wage'} /> :
                            </DText>
                            <DText primary style={{ direction: 'ltr' }}>
                                {`${formatNumber(trade?.wage)} ${trade?.type === 'sell' ? 'USD' : trade?.coin?.toUpperCase()}`}
                            </DText>
                        </Flex>
                        <CMargin margin={'6px'} />
                        <Flex fw justify={'space-between'}>
                            <DText primary fontSize={'s'}>
                                <Text tid={'afterWage'} /> :
                            </DText>
                            <DText primary style={{ direction: 'ltr' }}>
                                {`${formatNumber(trade?.afterWage)} ${trade?.type === 'sell' ? 'USD' : trade?.coin?.toUpperCase()}`}
                            </DText>
                        </Flex>
                    </CFlex>
                    <AmountBar
                        type={trade?.order?.type}
                        amount={trade?.status === 'done' ? 0 : trade?.amount}
                        tradedAmount={trade?.status === 'done' ? trade?.amount : 0}
                    />
                </Flex>
                <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                    <DText main>
                        {formatDate(trade?.createdAt, 'date', lang)}
                    </DText>
                    <DText main>
                        {formatDate(trade?.createdAt, 'time', lang)}
                    </DText>
                </Flex>
            </DetailsLayout>

            {orderId &&
                <DetailsLayout
                    isFetching={fetchingParty || isFetching}
                    isError={partyError || isError}
                    style={{ marginTop: '10px' }}
                >
                    <DText primary margin={'0 0 15px 0'}>
                        <Text tid={'party-details'} />
                    </DText>
                    <DetailRow>
                        <DText main>
                            <Text tid={'symbol'} /> :
                        </DText>
                        <Flex justify={'flex-end'}>
                            <DText primary lineBreak={'anywhere'}>
                                {party?.symbol}
                            </DText>
                        </Flex>
                    </DetailRow>
                    <DetailRow>
                        <DText main>
                            <Text tid={'price'} /> :
                        </DText>
                        <Flex justify={'flex-end'}>
                            <DText primary lineBreak={'anywhere'}>
                                {party?.price}
                            </DText>
                        </Flex>
                    </DetailRow>
                    <DetailRow>
                        <DText main>
                            <Text tid={'party-size'} /> :
                        </DText>
                        <Flex justify={'flex-end'}>
                            <DText primary lineBreak={'anywhere'}>
                                {party?.size}
                            </DText>
                        </Flex>
                    </DetailRow>
                    <DetailRow>
                        <DText main>
                            <Text tid={'party-funds'} /> :
                        </DText>
                        <Flex justify={'flex-end'}>
                            <DText primary lineBreak={'anywhere'}>
                                {party?.funds}
                            </DText>
                        </Flex>
                    </DetailRow>
                    <DetailRow>
                        <DText main>
                            <Text tid={'party-deal-size'} /> :
                        </DText>
                        <Flex justify={'flex-end'}>
                            <DText primary lineBreak={'anywhere'}>
                                {party?.dealSize}
                            </DText>
                        </Flex>
                    </DetailRow>
                    <DetailRow>
                        <DText main>
                            <Text tid={'party-deal-fund'} /> :
                        </DText>
                        <Flex justify={'flex-end'}>
                            <DText primary lineBreak={'anywhere'}>
                                {party?.dealFunds}
                            </DText>
                        </Flex>
                    </DetailRow>

                    <DetailRow>
                        <DText main>
                            <Text tid={'status'} /> :
                        </DText>
                        <Flex justify={'flex-end'}>
                            <TradeAmount type={party?.isActive ? 'buy' : 'sell'}>
                                <Text tid={party?.isActive ? 'active' : 'not-active'} />
                            </TradeAmount>
                        </Flex>
                    </DetailRow>
                </DetailsLayout>
            }
        </>
    )
}

export default OtcTradeDetails
