import {useEffect, useState} from "react";
import {deepCopy} from "../../../../utils/common";
import {useGetConfigItems, useSetConfigItems} from "../../../../services/react-query/setting";


const useBlockAccess = () => {

    const { data: config, isFetching: fetchingConfig } = useGetConfigItems()
    const { mutate: updateConfig, isLoading: updatingConfig, isSuccess: configUpdated } = useSetConfigItems()

    const [ detailsChanged, setDetailsChanged ] = useState(false)
    const [accessData, setAccessData] = useState(null)

    useEffect(() => {
        config && setAccessData(deepCopy(config.block))
    }, [config])

    const checkChanges = () => {
        const cache = config?.block
        const cacheKeys = Object.keys(cache)
        let changed = false
        for (let i = 0; i < cacheKeys.length; i++) {
            const key = cacheKeys[i]
            const cacheItem = cache[key]
            const newItem = accessData[key]
            if (newItem !== cacheItem) {
                changed = true
                break
            }
        }
        setDetailsChanged(changed)
    }

    const onInputValueChange = (v, type) => {
        if (type === 'deposit') {
            setAccessData(state => ({
                ...state,
                deposit: v,
                depositCoining: v,
                depositBanking: v
            }))
            return
        }
        if (type === 'withdraw') {
            setAccessData(state => ({
                ...state,
                withdraw: v,
                withdrawCoining: v,
                withdrawBanking: v
            }))
            return
        }
        setAccessData(state => ({
            ...state,
            [type]: v
        }))
    }

    useEffect(() => {
        if (accessData) {
            checkChanges()
        }
    }, [accessData])

    const onSubmitClicked = () => {
        const newConfig = deepCopy(config)
        newConfig.block = accessData
        updateConfig({data: newConfig})
    }

    return {
        accessData,
        onInputValueChange,
        loading: fetchingConfig || updatingConfig,
        detailsChanged,
        onSubmitClicked
    }
}


export default useBlockAccess
