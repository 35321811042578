import {CFlex, DText, Flex} from "../../../styles/CommonStyles";
import styled from "styled-components";
import Tooltip from "../utils/Tooltip";
import Text from "../../../core/utils/Text";
import {TradeAmount} from "../../../styles/main/orders";
import {formatNumber} from "../../../core/utils/common";


const AmountBar = (props) => {

    const {amount, tradedAmount, type} = props

    let percent = 0
    if (tradedAmount) {
        percent = (tradedAmount / (amount + tradedAmount)) * 100
        percent = percent?.toFixed(2)
    }

    return (
        <Tooltip
            content={`${percent}%`}
        >
            <AmountBarWrapper>
                <Bar
                    percent={percent}
                    type={type}
                >
                    <BarText>
                        {percent}%
                    </BarText>
                </Bar>
                <Flex fw justify={'space-between'}>
                    <DText fontSize={'s'} main>
                        <Text tid={'amount'} />
                    </DText>
                    <TradeAmount type={type}>
                        {formatNumber(amount + tradedAmount)}
                    </TradeAmount>
                </Flex>
                <Flex fw justify={'space-between'}>
                    <DText fontSize={'s'} main>
                        <Text tid={'traded-amount'} />
                    </DText>
                    <TradeAmount type={type}>
                        {formatNumber(tradedAmount)}
                    </TradeAmount>
                </Flex>
            </AmountBarWrapper>
        </Tooltip>
    )
}

const AmountBarWrapper = styled(CFlex)`
  width: 180px;
  margin: auto;
    
  @media screen and (max-width: 1050px) {
    margin-top: 30px;
  }
`


const Bar = styled.div`
  width: 42px;
  height: 148px;
  border: 2px solid ${props => props.theme.secondary}30;
  border-radius: 8px 8px 0 0;
  position: relative;
  display: flex;
  justify-content: center;
  
  &::after{
    content: ' ';
    background-color: ${props => props.type === 'sell' ? props.theme.mainRed : props.theme.mainGreen}70;
    width: 100%;
    height: ${props => props.percent ? props.percent : 0}%;
    position: absolute;
    bottom: 0;
    border-radius: 8px 8px 0 0;
  };
`

const BarText = styled.div`
  position: absolute;
  bottom: 8px;
  color: ${props => props.theme.primary};
  font-size: 0.7rem;
`


export default AmountBar
