import TableLayout from "../../../layout/main/TableLayout";
import {useAclContext} from "../../../../core/contexts/acl";
import {Column, DText, IconWrapper, Row} from "../../../../styles/CommonStyles";
import useGetTableIndex from "../../../../core/hooks/layout/useGetTableIndex";
import PermissionColumn from "./PermissionColumn";
import DateTime from "../../../common/utils/DateTime";
import {EditIcon} from "../../../../styles/main/setting";
import Tooltip from "../../../common/utils/Tooltip";
import {roleAttributes} from "../../../../core/constants/headers";


const RolesTable = ({
    data,
    enterEditMode
}) => {

    const { data: roles } = data
    const { headers, cs } = roleAttributes
    const {getTableIndex} = useGetTableIndex()
    const {permissions} = useAclContext()
    const hasWriteAccess = permissions?.role?.write

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
            hasWriteAccess={hasWriteAccess}
        >
            {roles?.data?.map((role, idx) => (
                <Row index={idx} cs={cs} noHover>
                    <Column>
                        {getTableIndex(idx)}
                    </Column>
                    <Column>
                        <DText main fontSize={'s'}>
                            {role.name}
                        </DText>
                    </Column>
                    <Column>
                        <PermissionColumn perms={role.permissions}/>
                    </Column>
                    <Column center>
                        <DateTime dt={role.createdAt}/>
                    </Column>
                    <Column center>
                        <Tooltip
                            content={'edit-role'}
                        >
                            <IconWrapper onClick={() => enterEditMode(role)}>
                                <EditIcon size={22}/>
                            </IconWrapper>
                        </Tooltip>
                    </Column>
                </Row>
            ))}
        </TableLayout>
    )
}


export default RolesTable
