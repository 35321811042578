import TableLayout from "../../../../layout/main/TableLayout";
import {Column, Row} from "../../../../../styles/CommonStyles";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import DateTime from "../../../../common/utils/DateTime";


const UserActivityTable = ({ data }) => {

    const { data: sessions } = data

    return (
        <TableLayout
            headers={headers}
            cs={CS}
            hasPagination={false}
            data={data}
        >
            {sessions?.data?.map((session, idx) => (
                <Row cs={CS} index={idx} currentOrder={!session.isDelete} type={'buy'} percent={100} noHover>
                    <Column>
                        {idx + 1}
                    </Column>
                    <Column>
                        {session.ip}
                    </Column>
                    <Column>
                        {`${session.extra?.deviceName}-${session?.extra?.deviceVersion}-${session?.extra?.appVersion}`}
                    </Column>
                    <Column>
                        <TradeTypeBadge color={getTradeTypeColor('limit')}>
                            {session.platform}
                        </TradeTypeBadge>
                    </Column>
                    <Column center>
                        <DateTime dt={session.createdAt} />
                    </Column>
                </Row>
            ))}
        </TableLayout>
    )
}


const CS = '5% 25% 25% 25% 20%'
const headers = [
    'ip', 'browser', 'platform', 'date'
]

export default UserActivityTable
