import {useEffect, useState} from "react";
import {useProfileQuery} from "../../../services/react-query/profile";


const useGetProfileOrUser = (detail, user) => {

    const [profile, setProfile] = useState(null)
    const { data: profileQuery } = useProfileQuery()

    useEffect(() => {
        if (detail && user) setProfile(user)
    }, [detail, user])

    useEffect(() => {
        if (!detail && profileQuery) setProfile(profileQuery)
    }, [detail, profileQuery])

    return {
        profile
    }
}

export default useGetProfileOrUser