import {thirdBalancesNobitexAttributes} from "../../../../../core/constants/headers";
import TableLayout from "../../../../layout/main/TableLayout";
import {Column, Flex, Row} from "../../../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../../../core/constants/urls";
import {formatNumber} from "../../../../../core/utils/common";


const NobitexTable = ({ data }) => {

    const { cs, headers } = thirdBalancesNobitexAttributes

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={cs}
        >
            {data?.data?.data?.map((item, idx) => {
                const symbol = item.coin === 'RLS' ? 'irt' : item.coin.toLowerCase()
                return (
                    <Row cs={cs} key={item.id}>
                        <Column>{idx + 1}</Column>
                        <Column>
                            <Flex style={{ gap: '8px' }}>
                                <img
                                    src={
                                        symbol === 'irt'
                                            ? require('../../../../../assets/images/tooman.png')
                                            : SOCKET_URL + `assets/icon/${symbol}.png`
                                    }
                                    width='32px'
                                    alt=' '
                                />
                                <span>{item.coin}</span>
                            </Flex>
                        </Column>
                        <Column>{item.id}</Column>
                        <Column>{formatNumber(item.balance, { type: symbol })}</Column>
                        <Column>{formatNumber(item.blocked, { type: symbol })}</Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}

export default NobitexTable
