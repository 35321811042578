import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";
import {postApi} from "../../fetch-api/post";


const NOTIF_KEYS = {
    NOTIF_CONFIG: 'notification-config',
    CREATE_NOTIF: 'create-notification'
}


const useGetNotificationConfig = () => {

    return useQuery(
        NOTIF_KEYS.NOTIF_CONFIG, () => normalFetch('configs/notification'),
        {
            select: res => res?.data?.data
        }
    )
}


const useCreateNotification = () => {

    return useMutation(
        NOTIF_KEYS.CREATE_NOTIF, (data) => postApi(data, 'notifications')
    )
}


export {
    useGetNotificationConfig,
    useCreateNotification
}
