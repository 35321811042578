import ListLayout from "../../components/layout/main/ListLayout";
import {PageCard} from "../../styles/CommonStyles";
import FilterLayout from "../../components/layout/filter/FilterLayout";
import {useGetBankAccounts} from "../../core/services/react-query/bank-accounts";
import {bankAccountFilterOptions, CacheKeys} from "../../core/constants/filter";
import {useState} from "react";
import {useWindowSize} from "../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../core/constants/common";
import BankAccountsTable from "../../components/main/bank-accounts/BankAccountsTable";
import RBankAccountsTable from "../../components/responsive/main/bank-accounts/RBankAccountsTable";


const BankAccounts = () => {

    const { width } = useWindowSize()

    const [ banks, setBanks ] = useState({ data: null, loading: true })

    const onQuerySuccess = (res) => setBanks(res)

    const Component = width > TABLET_SIZE ? BankAccountsTable : RBankAccountsTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetBankAccounts}
                    cache={CacheKeys.BANK_ACCOUNTS}
                    onQuerySuccess={onQuerySuccess}
                    options={bankAccountFilterOptions}
                >
                    <Component
                        data={banks}
                    />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default BankAccounts
