import Input from "../../common/input/Input";
import {useEffect, useState} from "react";
import ModalLayout from "../../layout/main/ModalLayout";
import {ModalHeader} from "../../../styles/modals";
import {CFlex, CMargin, Decoration, DText, Flex} from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import {RoleSelectRow, RolesWrapper} from "../../../styles/main/profile";
import ActionButton from "../../common/buttons/ActionButton";
import {useQueryContext} from "../../../core/contexts/query";
import {useUpdateAUser} from "../../../core/services/react-query/user";
import {useAclContext} from "../../../core/contexts/acl";
import {useGetAllRolesNoPagination} from "../../../core/services/react-query/setting/role";
import {useLogout} from "../../../core/services/react-query/auth";


const ProfileRole = ({ role, _id, refetch, isProfile }) => {

    const { setToast } = useQueryContext()
    const { data: roles, refetch: getAllRoles } = useGetAllRolesNoPagination()
    const { mutate: updateUserRole, isLoading, isSuccess } = useUpdateAUser()
    const { mutate: logout } = useLogout()

    const {permissions} = useAclContext()
    const hasRoleAccess = permissions?.role?.read

    useEffect(() => {
        if (hasRoleAccess) getAllRoles()
    }, [permissions])

    const [rolesCache, setRolesCache] = useState([])
    const [allRoles, setAllRoles] = useState([])
    const [search, setSearch] = useState('')
    const [roleSelectMode, setRoleSelectMode] = useState(false)

    const resetAllRoles = () => {
        if (roles) {
            const temp = [...roles.data]
            temp.unshift({ _id: 'admin', name: 'admin' })
            temp.unshift({ _id: 'user', name: 'user' })
            setAllRoles(temp)
            setRolesCache(temp)
        }
    }

    useEffect(() => {
        resetAllRoles()
    }, [roles])

    const openRoleSelectMode = () => {
        if (!hasRoleAccess) {
            setToast({
                isError: true,
                show: true,
                message: 'no-access-func'
            })
        }else {
            setRoleSelectMode(true)
        }
    }
    const closeRoleSelectMode = () => {
        setRoleSelectMode(false)
        setSearch('')
        setSelectedRole(null)
        setAllRoles(rolesCache)
    }

    const [selectedRole, setSelectedRole] = useState(null)

    const onChangeUserRole = () => {
        if (!selectedRole) {
            setToast({
                isError: true,
                show: true,
                message: 'no-changes'
            })
        }else {
            const payload = {
                data: {
                    role:
                        (selectedRole === 'admin' || selectedRole === 'user') ?
                            selectedRole
                            :
                            selectedRole._id
                },
                id: _id
            }
            updateUserRole(payload)
        }
    }

    useEffect(() => {
        if (isSuccess) {
            closeRoleSelectMode()
            refetch && refetch()
            isProfile && logout()
        }
    }, [isSuccess])

    const onSearchValueChange = (v) => {
        setSearch(v)

        if (!v) {
            resetAllRoles()
        }else {
            const newRoles = rolesCache.filter(item => item.name.includes(v))
            setAllRoles(newRoles)
        }
    }

    return (
        <>
            <Input
                label={'role'}
                value={
                    (role === 'admin' || role === 'user') ?
                        role
                        :
                        role.name
                }
                disabled
                onClick={openRoleSelectMode}
            />
            {hasRoleAccess &&
                <ModalLayout
                    width={'520px'}
                    onClose={closeRoleSelectMode}
                    open={roleSelectMode}
                >
                    <ModalHeader>
                        <DText primary>
                            <Text tid={'select-role'} />
                        </DText>
                    </ModalHeader>
                    {isProfile &&
                        <Flex fw style={{ margin: '8px 0' }} justify={'flex-start'}>
                            <Decoration />
                            <DText main fontSize={'s'}>
                                <Text tid={'profile-change-role-note'} />
                            </DText>
                        </Flex>
                    }
                    <Input
                        label={'search'}
                        value={search}
                        onInputChange={(v) => onSearchValueChange(v)}
                        height={'32px'}
                        size={'small'}
                        padding={'4px 0'}
                    />
                    <RolesWrapper>
                        <CFlex fw fh justify={'flex-start'}>
                            {allRoles.map((item, idx) => (
                                <RoleSelectRow
                                    index={idx}
                                    selected={selectedRole?._id === item?._id}
                                    role={item.name === role.name}
                                    onClick={() => setSelectedRole(item)}
                                >
                                    <DText main>
                                        {item.name}
                                    </DText>
                                </RoleSelectRow>
                            ))}
                        </CFlex>
                    </RolesWrapper>
                    <ActionButton
                        style={{ marginTop: '8px' }}
                        active={!!selectedRole}
                        onClick={onChangeUserRole}
                        loading={isLoading}
                    >
                        <Text tid={'change-role'} />
                    </ActionButton>
                </ModalLayout>
            }

        </>
    )
}




export default ProfileRole
