import {useEffect, useState} from "react";
import {useGetUserSessions} from "../../../../../core/services/react-query/user";
import {useParams} from "react-router-dom";
import {UserDetailListWrapper} from "../../../../../styles/main/user";
import Pagination from "../../../../common/utils/Pagination";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import UserActivityTable from "./UserActivityTable";
import RUserActivityTable from "../../../../responsive/main/users/RUserActivityTable";


const UserActivities = () => {

    const params = useParams()
    const { width } = useWindowSize()

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const onPageChange = (page) => setPage(page)
    const onLimitChange = (limit) => setLimit(limit)

    const { data: sessions, isFetching: loading, refetch } = useGetUserSessions({page, limit}, params.id)
    const TOTAL = sessions?.meta?.total ? sessions?.meta.total : 0

    useEffect(() => {
        refetch()
    }, [page, limit])

    const Table = width > TABLET_SIZE ? UserActivityTable : RUserActivityTable

    return (
        <UserDetailListWrapper>
            <Table
                data={{ data: sessions, loading }}
            />
            <Pagination
                total={TOTAL}
                page={page}
                limit={limit}
                onPageChange={onPageChange}
                onLimitChange={onLimitChange}
            />
        </UserDetailListWrapper>
    )
}



export default UserActivities
