import {useResyncAddress} from "../../../core/services/react-query/fullnode";
import {Background, CFlex, CMargin, Decoration, DText, Flex, Padding} from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import ABActionButton from "../setting/general/ABActionButton";
import ModalLayout from "../../layout/main/ModalLayout";
import BasicModal from "../../modals/BasicModal";
import Input from "../../common/input/Input";
import {useState} from "react";


const ResyncAdress = () => {

    const { mutate: resync, isLoading } = useResyncAddress()

    const [submitModal, setSubmitModal] = useState(false)
    const openSubmitModal = () => setSubmitModal(true)
    const closeSubmitModal = () => setSubmitModal(false)

    const [network, setNetwork] = useState('')
    const [address, setAddress] = useState('')

    const onSubmitClicked = () => {
        resync({
            network, address
        })
    }

    return (
        <>
            <CFlex fw align={'flex-start'}>
                <CMargin margin={'10px'} />
                <Flex fw justify={'flex-start'}>
                    <Decoration />
                    <DText main>
                        <Text tid={'resync-note'} />
                    </DText>
                </Flex>
                <Padding padding={'10px'}>
                    <Background bg={'mainBg'}>
                        <DText primary>
                            <Text tid={'address'} /> :
                        </DText>
                        <CMargin margin={'2px'} />
                        <Input
                            label={'address'}
                            value={address}
                            onInputChange={(v) => setAddress(v)}
                            width={'320px'}
                        />

                        <DText primary>
                            <Text tid={'network'} /> :
                        </DText>
                        <CMargin margin={'2px'} />
                        <Input
                            label={'network'}
                            value={network}
                            onInputChange={(v) => setNetwork(v)}
                            width={'320px'}
                        />
                    </Background>


                    <ABActionButton
                        style={{ justifyContent: 'flex-start', margin: '40px 10px' }}
                        show={!!network && !!address}
                        onClick={openSubmitModal}
                        loading={isLoading}
                    />
                </Padding>
            </CFlex>
            <ModalLayout
                open={submitModal}
                onClose={closeSubmitModal}
                width={'580px'}
            >
                <BasicModal
                    head={'move-to-master'}
                    onClose={closeSubmitModal}
                    onSubmit={onSubmitClicked}
                    loading={isLoading}
                />
            </ModalLayout>
        </>
    )
}

export default ResyncAdress
