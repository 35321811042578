import {useAclContext} from "../../../contexts/acl";
import {useEffect, useState} from "react";
import {fileDownloader} from "../../../utils/common";
import {useDownloadAFile} from "../../../services/react-query/files";


const useFiles = () => {

    const {permissions} = useAclContext()
    const hasWriteAccess = permissions.export.write

    const [selectedFile, setSelectedFile] = useState(null)

    const onFileDownloaded = (res) => {
        fileDownloader({
            data: [res].join("\n"),
            fileName: `${selectedFile?.name}.csv`,
            fileType: "text/csv",
        })
    }

    const {
        refetch: downloadFile,
        isFetching: loading
    } = useDownloadAFile(selectedFile, onFileDownloaded)


    const onDownloadFile = (file) => {
        setSelectedFile(file)
    }

    useEffect(() => {
        if (!!selectedFile) {
            downloadFile()
        }
    }, [selectedFile])

    return {
        hasWriteAccess,
        loading,
        selectedFile,
        onDownloadFile
    }
}


export default useFiles
