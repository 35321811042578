import { useEffect, useState } from "react"
import styled from "styled-components"
import { keyframes } from "styled-components"
import { CFlex, DText, Flex } from "../../../../styles/CommonStyles"
import Tooltip from "../../../common/utils/Tooltip"
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import {MOBILE_SIZE, TABLET_SIZE} from "../../../../core/constants/common";
import Text from "../../../../core/utils/Text";



const ShareBar = ({
    affiliate
}) => {

    const { width } = useWindowSize()

    return (
        <Flex fw justify={width > TABLET_SIZE ? 'center' : 'space-between'} align={'flex-end'}>
            {width < TABLET_SIZE &&
                <DText main fontSize={'ss'}>
                    <Text tid={'actor-share'} />
                </DText>
            }
            <CFlex width={ width > MOBILE_SIZE ? '180px' : '160px' }>
                <Flex fw justify='space-between'>
                    <DText main fontSize={'s'}>
                        {affiliate.actorShare}%
                    </DText>
                    <DText main fontSize={'s'}>
                        {affiliate.objectShare}%
                    </DText>
                </Flex>
                <Flex fw>
                    <Flex width={`${affiliate.actorShare}%`} justify='flex-start'>
                        <Tooltip content='actor-share'>
                            <BarItem actor/>
                        </Tooltip>
                    </Flex>
                    <Flex width={`${affiliate.objectShare}%`} justify='flex-end'>
                        <Tooltip content='object-share'>
                            <BarItem/>
                        </Tooltip>
                    </Flex>
                </Flex>
            </CFlex>
            {width < TABLET_SIZE &&
                <DText main fontSize={'ss'}>
                    <Text tid={'object-share'} />
                </DText>
            }
        </Flex>

    )
}


const animation = keyframes`
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
`

const BarItem = styled.div`
    width: 100%;
    height: 10px;
    border-radius: 6px;
    background-color: ${props => props.actor ? '#819F82' : '#9F819E'};
    animation: ${animation} 1s forwards;
    margin: 0 1px;
`


export default ShareBar
