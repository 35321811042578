import {useParams} from "react-router-dom";
import {useGetADepositAccountDetail} from "../../../core/services/react-query/report/banking";
import {Flex} from "../../../styles/CommonStyles";
import ObjectView from "../../../components/common/utils/ObjectView";
import {useMainContext} from "../../../core/contexts/main";


const BankDepositAccountDetails = () => {

    const { theme } = useMainContext()
    const params = useParams()
    const { data: account } = useGetADepositAccountDetail(params.detailId)

    return (
        <Flex fw height={'100vh'}>
            <ObjectView obj={account} theme={theme} />
        </Flex>
    )
}

export default BankDepositAccountDetails
