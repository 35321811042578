import {useMutation, useQuery} from "react-query";
import {useMainContext} from "../../../contexts/main";
import {makeQueryFilters} from "../../../utils/query-maker";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";
import {postApi} from "../../fetch-api/post";


export const ROLE_KEYS = {
    GET_ALL_ROLES: 'get-all-roles',
    GET_ALL_ROLES_NO_PAGINATION: 'get-all-roles-no-pagination',
    GET_A_ROLE: 'get-a-role',
    ROLE_CONFIG: 'role-config',
    CREATE_ROLE: 'create-role',
    UPDATE_ROLE: 'update-role'
}


const useGetAllRoles = (filters, pagination) => {

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(filters, lang), ...pagination}

    return useQuery(
        ROLE_KEYS.GET_ALL_ROLES, () => filterFetch(params, 'roles')
    )
}

const useGetAllRolesNoPagination = () => {

    return useQuery(
        ROLE_KEYS.GET_ALL_ROLES_NO_PAGINATION, () => normalFetch('roles/all'),
        {
            enabled: false
        }
    )
}


const useGetRoleQuery = (id, adminCheck = true) => {

    const { setToken } = useMainContext()
    const { setToast } = useQueryContext()

    return (
        useQuery(
            ROLE_KEYS.GET_A_ROLE, () => normalFetch(`roles/${id}`), {
                enabled: false,
                cacheTime: 0,
                staleTime: 0,
                select: res => res?.data?.data,
                onError: () => {
                    if (adminCheck) {
                        setToken('')
                        setToast({
                            isError: true, show: true,
                            message: 'no-admin-error'
                        })
                    }
                }
            }
        )
    )
}


const useGetRoleConfig = () => {

    return useQuery(
        ROLE_KEYS.ROLE_CONFIG, () => normalFetch('configs/role'), {
            cacheTime: 30 * 60 * 60,
            staleTime: 30 * 60 * 60,
            select: res => res?.data?.data,
        }
    )
}


const useCreateARole = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        ROLE_KEYS.CREATE_ROLE, (data) => postApi(data, 'roles'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(ROLE_KEYS.GET_ALL_ROLES)
                setToast({
                    show: true,
                    message: 'operation-success'
                })
            }
        }
    )
}


const useUpdateARole = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        ROLE_KEYS.UPDATE_ROLE, (data) => putApi(data.data, `roles/${data.id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(ROLE_KEYS.GET_ALL_ROLES)
                setToast({
                    show: true,
                    message: 'operation-success'
                })
            }
        }
    )
}


export {
    useGetRoleQuery,
    useGetAllRoles,
    useGetAllRolesNoPagination,
    useGetRoleConfig,
    useCreateARole,
    useUpdateARole
}
