import { ThemeProvider } from "styled-components";
import {useEffect} from "react";
import {useMainContext} from "../../../core/contexts/main";
import {getMainTheme} from "../../../core/utils/theme";
import {Flex, MainBody, Body} from "../../../styles/CommonStyles";
import Toast from "../../common/utils/Toast";
import MainHeader from "./MainHeader";
import Sidebar from "../sidebar/Sidebar";
import {useLocation, useNavigate} from "react-router-dom";
import Restricted from "./Restricted";
import ConnectionContextProvider from "../../../core/contexts/connection";
import FilterContextProvider from "../../../core/contexts/filter";
import Navigator from "../navigator/Navigator";
import ErrorBoundary from "./ErrorBoundary";




const MainLayout = ({ children }) => {

    const navigate = useNavigate()
    const location = useLocation()
    const { theme, lang, token, sidebar } = useMainContext()

    const hasSidebar = location.pathname !== '/login'

    useEffect(() => {
        const isAssetLinks = location.pathname === '/.well-known/assetLinks.json'
        if (!token && !isAssetLinks) navigate('/login')
    }, [token])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <ConnectionContextProvider>
            <FilterContextProvider>
                <ThemeProvider theme={getMainTheme(theme, lang)}>
                    <ErrorBoundary>
                        <Restricted>
                            <MainHeader />
                            <MainBody>
                                <Flex fw fh justify='flex-start' align={'flex-start'}>
                                    {hasSidebar && <Sidebar />}
                                    <Body status={sidebar} hasSidebar={hasSidebar}>
                                        {children}
                                    </Body>
                                </Flex>
                            </MainBody>
                            <Navigator />
                            <Toast />
                        </Restricted>
                    </ErrorBoundary>
                </ThemeProvider>
            </FilterContextProvider>
        </ConnectionContextProvider>
    )
}


export default MainLayout;
