import MotionFade from "../../../../common/utils/MotionFade";
import useDollarFee from "../../../../../core/hooks/main/setting/general/useDollarFee";
import {CFlex, CMargin, Decoration, DText, Flex, IconWrapper} from "../../../../../styles/CommonStyles";
import Tooltip from "../../../../common/utils/Tooltip";
import {EditIcon} from "../../../../../styles/main/setting";
import {AnimatePresence} from "framer-motion";
import DollarFeeUpdate from "./DollarFeeUpdate";
import DollarFeeDetails from "./DollarFeeDetails";
import SubmitModal from "../../../../modals/SubmitSettingModal";
import Text from "../../../../../core/utils/Text";


const DollarFee = () => {

    const {
        dollarFee,
        loading,
        editMode,
        enterEditMode,
        exitEditMode,
        onSubmitChanges,
        onInputValueChange
    } = useDollarFee()


    return (
        <MotionFade>
            <CFlex fw>
                <Flex fw justify={'flex-end'}>
                    <Tooltip
                        content={'edit'}
                    >
                        <IconWrapper onClick={enterEditMode}>
                            <EditIcon size={22} />
                        </IconWrapper>
                    </Tooltip>
                </Flex>
                <Flex fw justify={'flex-start'}>
                    <Decoration />
                    <DText main>
                        <Text tid={'static-price-note'} />
                    </DText>
                </Flex>
                <CMargin margin={'10px'} />
                <AnimatePresence exitBeforeEnter>
                    {editMode ?
                        <DollarFeeUpdate
                            data={dollarFee}
                            onInputValueChange={onInputValueChange}
                        />
                        :
                        <DollarFeeDetails
                            data={dollarFee}
                        />
                    }
                </AnimatePresence>
            </CFlex>
            <SubmitModal
                editMode={editMode}
                onSubmit={onSubmitChanges}
                onCancel={exitEditMode}
                loading={loading}
            />
        </MotionFade>
    )
}


export default DollarFee
