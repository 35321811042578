import {useParams} from "react-router-dom";
import {useState} from "react";
import {UserDetailListWrapper} from "../../../../styles/main/user";
import FilterLayout from "../../../layout/filter/FilterLayout";
import {CacheKeys, coinTransactionOptions} from "../../../../core/constants/filter";
import {TABLET_SIZE} from "../../../../core/constants/common";
import CoinTransactionTable from "../../reports/coin-transactions/CoinTransactionTable";
import RCoinTransactionTable from "../../../responsive/main/reports/coin-transactions/RCoinTransactionTable";
import {useWindowSize} from "../../../../core/hooks/common/useWindowSize";
import {useAclContext} from "../../../../core/contexts/acl";
import {useGetCoinTransactions} from "../../../../core/services/react-query/coining";


const UserCoinTransactions = () => {

    const params = useParams()
    const { width } = useWindowSize()

    const [transactions, setTransactions] = useState({loading: true, data: null})
    const { permissions } = useAclContext()
    const hasDownload = permissions?.export?.write
    const onQuerySuccess = (res) => {
        setTransactions(res)
    }

    const Component = width > TABLET_SIZE ? CoinTransactionTable : RCoinTransactionTable

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useGetCoinTransactions}
                options={coinTransactionOptions}
                onQuerySuccess={onQuerySuccess}
                cache={CacheKeys.USER_COIN_TRANSACTIONS}
                extra={{ userId: params.id }}
                hasDownload={hasDownload}
            >
                <Component
                    data={transactions}
                />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}

export default UserCoinTransactions
