import {useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {useMainContext} from "../../../contexts/main";
import {makeQueryFilters} from "../../../utils/query-maker";


const FILE_KEYS = {
    FETCH_FILES: 'fetch-files',
    DOWNLOAD_FILE: 'download-file'
}


const useGetAllFiles = (filters, pagination) => {

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(filters, lang), ...pagination}

    return useQuery(
        FILE_KEYS.FETCH_FILES, () => filterFetch(params, 'fileExports')
    )
}


const useDownloadAFile = (file, onSuccess) => {

    return useQuery(
        FILE_KEYS.DOWNLOAD_FILE, () => normalFetch(`fileExports/download/${file?.name}`),
        {
            enabled: false,
            select: res => res?.data,
            onSuccess
        }
    )
}


export {
    useGetAllFiles,
    useDownloadAFile
}
