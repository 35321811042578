import {CFlex, CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {useEffect, useState} from "react";
import ActionButton from "../../../../common/buttons/ActionButton";
import {useQueryContext} from "../../../../../core/contexts/query";
import {useChangeUserKycStatus} from "../../../../../core/services/react-query/user";
import {useMainContext} from "../../../../../core/contexts/main";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";
import {TextArea} from "../../../../../styles/main/user";


const RejectUser = ({ user, refetch }) => {

    const { lang } = useMainContext()
    const { setToast } = useQueryContext()
    const [note, setNote] = useState('')

    const {
        mutate: changeState,
        isLoading,
        isSuccess
    } = useChangeUserKycStatus()

    const [modal, setModal] = useState(false)
    const onModalOpen = () => setModal(true)
    const onModalClose = () => setModal(false)

    const onInputChange = (e) => {
        const value = e?.target?.value
        setNote(value)
    }

    const onSubmitClicked = () => {
        if (!note) {
            setToast({
                message: 'fill-inputs-error',
                show: true, isError: true
            })
        }else {
            onModalOpen()
        }
    }

    const onRejectUser = () => {
        changeState({id: user?._id, data: {status: "REJECTED", rejectReason: note}})
    }

    useEffect(() => {
        if (isSuccess) {
            refetch()
            onModalClose()
        }
    }, [isSuccess])

    return (
        <CFlex fw>
            <CMargin margin={'20px'} />
            <CFlex width={'300px'}>
                <Flex fw justify={'flex-start'}>
                    <Decoration />
                    <DText main>
                        <Text tid={'reject-cause'} />
                    </DText>
                </Flex>
                <CMargin margin={'5px'} />
                <TextArea
                    value={note}
                    onChange={onInputChange}
                    placeholder={lang === 'en' ? 'Cause...' : 'علت...'}
                />
                <CMargin margin={'20px'} />
                <ActionButton
                    height={'42px'}
                    active={!!note}
                    onClick={onSubmitClicked}
                    loading={isLoading}
                >
                    <Text tid={'submit'} />
                </ActionButton>
            </CFlex>

            <ModalLayout
                open={modal}
                width={'520px'}
                onClose={onModalClose}
            >
                <BasicModal
                    head={`reject-user`}
                    _id={user?._id}
                    onClose={onModalClose}
                    onSubmit={onRejectUser}
                    loading={isLoading}
                />
            </ModalLayout>
        </CFlex>
    )
}


export default RejectUser
