import {useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";


const THIRD_KEYS = {
    THIRD_BALANCES: 'get-balances',
    THIRD_ORDER_HISTORY: 'third-order-history',
    THIRD_WITHDRAW_HISTORY: 'third-withdraw-history',
    GET_THIRD_DEPOSIT_ADDRESSES: 'get-third-deposit-addresses'
}


const useGetThirdBalances = (third) => {

    return useQuery(
        THIRD_KEYS.THIRD_BALANCES, () => normalFetch(`thirdparty/${third}/balance`),
        {
            select: res => res?.data?.data,
            enabled: false
        }
    )
}


const useGetThirdOrderHistory = (filters, pagination, extra) => {

    return useQuery(
        THIRD_KEYS.THIRD_ORDER_HISTORY, () => filterFetch(pagination, `thirdparty/${extra?.third}/orders`),
        {
            select: res => res?.data,
            enabled: false
        }
    )
}

const useGetThirdWithdrawHistory = (filters, pagination, extra) => {

    return useQuery(
        THIRD_KEYS.THIRD_WITHDRAW_HISTORY, () => filterFetch(pagination, `thirdparty/${extra?.third}/withdraw`),
        {
            select: res => res?.data,
            enabled: false
        }
    )
}

const useGetNobitexDepositAddress = (third, coin) => {

    return useQuery(
        [THIRD_KEYS.GET_THIRD_DEPOSIT_ADDRESSES, coin], () => normalFetch(`thirdparty/${third}/deposit/${coin}`),
        {
            select: res => res?.data?.data,
            enabled: !!coin,
            cacheTime: 5 * 60 * 1000,
            staleTime: 5 * 60 * 1000
        }
    )
}


export {
    useGetThirdBalances,
    useGetThirdOrderHistory,
    useGetThirdWithdrawHistory,
    useGetNobitexDepositAddress
}
