import {useParams} from "react-router-dom";
import {useGetAMultiSignature} from "../../core/services/react-query/multi-signature";
import {useGetAUser} from "../../core/services/react-query/user";
import {useEffect, useState} from "react";
import DetailsLayout from "../../components/layout/main/DetailsLayout";
import {
    CartableAttachmentBox,
    DetailRow,
    LeftHeadDecoration,
    RightHeadDecoration
} from "../../styles/main/MainCommonStyles";
import {DText, Flex} from "../../styles/CommonStyles";
import {SOCKET_URL} from "../../core/constants/urls";
import Text from "../../core/utils/Text";
import {formatDate, formatName, formatNumber, getNames} from "../../core/utils/common";
import UserLink from "../../components/common/utils/UserLink";
import {getTradeTypeColor} from "../../core/utils/theme";
import {TradeTypeBadge} from "../../styles/main/orders";
import {PreviewImage} from "../../styles/main/profile";
import {useMainContext} from "../../core/contexts/main";
import Operation from "../../components/common/utils/Operation";
import {balanceSignatureOperations} from "../../core/constants/operation";
import useCartable from "../../core/hooks/main/cartable/useCartable";
import ModalLayout from "../../components/layout/main/ModalLayout";
import BalanceSignatureActionModal from "../../components/modals/BalanceSignatureActionModal";
import BasicModal from "../../components/modals/BasicModal";


const CartableDetails = () => {

    const {lang} = useMainContext()
    const params = useParams()
    const { data, isFetching, isError } = useGetAMultiSignature(params.id)

    const previewInitial = {file: null, show: false}
    const [preview, setPreview] = useState(previewInitial)

    const [askById, setAskById] = useState(null)
    const [replyById, setReplyById] = useState(null)
    const [userId, setUserId] = useState(null)

    const { data: askBy, refetch: requestAskBy } = useGetAUser(askById)
    const { data: replyBy, refetch: requestReplyBy } = useGetAUser(replyById)
    const { data: user, refetch: requestUser } = useGetAUser(userId)

    const replyAndAskIsSame = data?.askBy === data?.replyBy

    useEffect(() => {
        if (data) {
            setAskById(data.askBy)
            setUserId(data.params.userId)
            if (data.replyBy) {
                setReplyById(data.replyBy)
            }
        }
    }, [data])

    useEffect(() => {
        if (askById) requestAskBy()
    }, [askById])

    useEffect(() => {
        if (replyById) requestReplyBy()
    }, [replyById])

    useEffect(() => {
        if (userId) requestUser()
    }, [userId])

    const {
        FULLNAME: askFullName,
    } = getNames(askBy?.firstName, askBy?.lastName)

    const {
        FULLNAME: replyFullName,
    } = getNames(replyBy?.firstName, replyBy?.lastName)

    const {
        FULLNAME: userFullName,
    } = getNames(user?.firstName, user?.lastName)

    const stats = {
        pending: 'pending',
        rejected: 'error',
        done: 'success'
    }

    const LinkBox = (link) => {
        return (
            <CartableAttachmentBox onClick={() => setPreview({show: true, file: link?.link?.link})}>
                <img
                    src={link?.link?.link}
                    alt={' '}
                    width={'48px'}
                    height={'48px'}
                />
            </CartableAttachmentBox>
        )
    }

    /* operation */
    const {
        hasWriteAccess,
        onOptionClicked,
        onDetailsClicked,
        onDeleteClicked,
        actionModal,
        closeActionModal,
        deleteModal,
        closeDeleteModal,
        onSubmitDelete,
        deleteLoading
    } = useCartable({ fromDetail: true })


    return (
        <>
            <DetailsLayout
                isFetching={isFetching}
                isError={isError}
                type={stats[data?.status]}
            >
                <RightHeadDecoration>
                    <Flex>
                        <img
                            src={
                                data?.params?.currency === 'irt' ?
                                    require('../../assets/images/tooman.png')
                                    :
                                    SOCKET_URL + `assets/icon/${data?.params?.currency}.png`
                            }
                            alt={' '}
                            width={'48px'}
                        />
                    </Flex>
                </RightHeadDecoration>
                <LeftHeadDecoration left={'10px'}>
                    <DText main>
                        {data?._id}
                    </DText>
                </LeftHeadDecoration>
                <Flex fw justify={'space-between'}>
                    <DText fontSize={'b'} primary>
                        {data?.params?.currency?.toUpperCase()}
                    </DText>
                    <Operation
                        options={hasWriteAccess ? balanceSignatureOperations: []}
                        onOptionClicked={(idx) => onOptionClicked(idx, data)}
                        onDeleteClicked={() => onDeleteClicked(data)}
                        hasWriteAccess={data?.status === 'pending'}
                        hasDelete={data?.status === 'pending' && !hasWriteAccess}
                        deleteTooltip={'delete-cartable'}
                    />
                </Flex>


                <DetailRow>
                    <DText main>
                        <Text tid={'user'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <UserLink
                            name={formatName(userFullName ? userFullName : user?._id)}
                            _id={user?._id}
                        />
                    </Flex>
                </DetailRow>

                <DetailRow>
                    <DText main>
                        <Text tid={'volume'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <DText primary>
                            {formatNumber(data?.params.volume)}
                        </DText>
                    </Flex>
                </DetailRow>

                <DetailRow>
                    <DText main>
                        <Text tid={'action'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <TradeTypeBadge color={getTradeTypeColor(data?.params.action.toLowerCase())}>
                            <Text tid={data?.params.action.toLowerCase()} />
                        </TradeTypeBadge>
                    </Flex>
                </DetailRow>

                <DetailRow>
                    <DText main>
                        <Text tid={'status'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <TradeTypeBadge color={getTradeTypeColor(data?.status)}>
                            <Text tid={data?.status} />
                        </TradeTypeBadge>
                    </Flex>
                </DetailRow>

                <DetailRow>
                    <DText main>
                        <Text tid={'askBy'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <UserLink
                            name={formatName(askFullName ? askFullName : askBy?._id)}
                            _id={askBy?._id}
                        />
                    </Flex>
                </DetailRow>

                <DetailRow>
                    <DText main>
                        <Text tid={'ask'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <Flex style={{ maxWidth: '300px' }}>
                            <DText primary style={{ textAlign: 'end' }}>
                                {data?.note}
                            </DText>
                        </Flex>
                    </Flex>
                </DetailRow>


                <DetailRow>
                    <DText main>
                        <Text tid={'replyBy'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        {replyAndAskIsSame ?
                            <UserLink
                                name={formatName(askFullName ? askFullName : askBy?._id)}
                                _id={askBy?._id}
                            />
                            : (
                                replyById ?
                                    <UserLink
                                        name={formatName(replyFullName ? replyFullName : replyBy?._id)}
                                        _id={replyBy?._id}
                                    />
                                    :
                                    <DText primary>
                                        <Text tid={'not-replied'} />
                                    </DText>
                            )
                        }
                    </Flex>
                </DetailRow>



                <DetailRow>
                    <DText main>
                        <Text tid={'reply'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <DText primary>
                            {data?.reply ?
                                data?.reply
                                :
                                <Text tid={'not-replied'} />
                            }
                        </DText>
                    </Flex>
                </DetailRow>

                <DetailRow>
                    <DText main>
                        <Text tid={'attachment'} />
                    </DText>
                    <Flex justify={'flex-end'} wrap>
                        {data?.files?.map(link => (
                            <LinkBox
                                link={link}
                            />
                        ))}
                    </Flex>
                </DetailRow>

                <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                    <DText main>
                        {formatDate(data?.createdAt, 'date', lang)}
                    </DText>
                    <DText main>
                        {formatDate(data?.createdAt, 'time', lang)}
                    </DText>
                </Flex>
            </DetailsLayout>
            {preview.show &&
                <PreviewImage onClick={() => setPreview(previewInitial)}>
                    <Flex fw fh>
                        <img
                            src={preview?.file}
                            alt={' '}
                            width={'100%'}
                            height={'100%'}
                            style={{ objectFit: 'scale-down' }}
                        />
                    </Flex>
                </PreviewImage>
            }

            <ModalLayout
                width={'580px'}
                open={actionModal.open}
                onClose={closeActionModal}
            >
                <BalanceSignatureActionModal
                    data={actionModal}
                    onClose={closeActionModal}
                />
            </ModalLayout>
            <ModalLayout
                width={'580px'}
                open={deleteModal.open}
                onClose={closeDeleteModal}
            >
                <BasicModal
                    head={'delete-signature'}
                    onClose={closeDeleteModal}
                    onSubmit={onSubmitDelete}
                    loading={deleteLoading}
                />
            </ModalLayout>
        </>
    )
}


export default CartableDetails
