

const Skeleton = (props) => {

    const {
        count = 1,
        Content,
        params
    } = props

    return (
        <>
            {Array.from(Array(count).keys()).map(key => (
                <Content params={params} key={key} />
            ))}
        </>
    )
}


export default Skeleton
