import ListLayout from "../../../components/layout/main/ListLayout";
import {Flex, IconWrapper, PageCard} from "../../../styles/CommonStyles";
import {useEffect, useState} from "react";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import RolesUpdate from "../../../components/main/setting/roles/RolesUpdate";
import {TABLET_SIZE} from "../../../core/constants/common";
import RolesTable from "../../../components/main/setting/roles/RolesTable";
import RRolesTable from "../../../components/responsive/main/setting/roles/RRolesTable";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {useGetAllRoles} from "../../../core/services/react-query/setting/role.js";
import {CacheKeys, roleFilterOptions, roleSortOptions} from "../../../core/constants/filter";
import Tooltip from "../../../components/common/utils/Tooltip";
import {AddIcon} from "../../../styles/main/setting";
import useRoleSetting from "../../../core/hooks/main/setting/role/useRoleSetting";
import {useAclContext} from "../../../core/contexts/acl";


const Roles = () => {

    const { width } = useWindowSize()

    const {permissions} = useAclContext()
    const hasWriteAccess = permissions?.role?.write

    const {
        editMode,
        enterEditMode,
        exitEditMode,
        roles,
        role,
        onPermissionChange,
        onSubmitChanges,
        onQuerySuccess,
        loading
    } = useRoleSetting()

    const Table = width > TABLET_SIZE ? RolesTable : RRolesTable

    return (
        <ListLayout>
            <PageCard>
                {(!editMode && hasWriteAccess) &&
                    <Flex fw justify={'flex-end'}>
                        <Tooltip
                            content={'add-role'}
                        >
                            <IconWrapper onClick={() => enterEditMode()}>
                                <AddIcon size={width > TABLET_SIZE ? 24 : 20}/>
                            </IconWrapper>
                        </Tooltip>
                    </Flex>
                }
                {width &&
                    ( editMode ?
                        <RolesUpdate
                            exitEditMode={exitEditMode}
                            onPermissionChange={onPermissionChange}
                            role={role}
                            onSubmitChanges={onSubmitChanges}
                            loading={loading}
                        />
                        :
                        <FilterLayout
                            query={useGetAllRoles}
                            onQuerySuccess={onQuerySuccess}
                            options={roleFilterOptions}
                            cache={CacheKeys.ROLES}
                            sortOptions={roleSortOptions}
                        >
                            <Table
                                data={roles}
                                enterEditMode={enterEditMode}
                            />
                        </FilterLayout>
                    )
                }
            </PageCard>
        </ListLayout>
    )
}

export default Roles;
