import styled from "styled-components";
import {CFlex, CMargin, DeleteIcon, DText, Flex} from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import {ReactComponent as NoImage} from "../../../assets/images/no-data.svg";
import {ReadIcon} from "../../../styles/main/setting";
import {useState} from "react";
import Loader from "../loading/Loader";
import {CgCheck} from "react-icons/cg";


const MultiUpload = (props) => {

    const {
        files,
        onFilesChange,
        uploading,
        success,
        index
    } = props

    const previewInitial = {file: null, show: false}
    const [preview, setPreview] = useState(previewInitial)

    const onChange = (e) => {
        const files = Array.prototype.slice.call(e?.target?.files)
        onFilesChange(files)
    }

    const onImagePreview = (file) => {
        setPreview({show: true, file})
    }

    const onDeleteImage = (idx) => {
        const newFiles = [...files]
        newFiles.splice(idx, 1)
        onFilesChange(newFiles)
    }


    return (
        <Wrapper {...props}>
            <CFlex fw>
                {files?.length ?
                    <Flex fw wrap align={'stretch'}>
                        {
                            files?.map((file, idx) => (
                                <ImageBox success={index > idx || success}>
                                    <Flex fw justify={'space-between'}>
                                        {(index > idx || success) ?
                                            <CgCheck color={'#1ce087'} size={18} />
                                            :
                                            <div />
                                        }
                                        <Flex>
                                            <ReadIcon
                                                style={{ margin: '0 4px', cursor: 'pointer' }}
                                                onClick={() => onImagePreview(file)}
                                            />
                                            <DeleteIcon
                                                onClick={() => onDeleteImage(idx)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </Flex>
                                    </Flex>

                                    <CMargin margin={'5px'} />
                                    {(uploading && index === idx) ?
                                        <Flex height={'64px'}>
                                            <Loader />
                                        </Flex>
                                        :
                                        <img
                                            width={'64px'}
                                            height={'64px'}
                                            alt={' '}
                                            src={URL.createObjectURL(file)}
                                        />
                                    }

                                    <CMargin margin={'2px'} />
                                    <DText main fontSize={'ss'} lineBreak={'anywhere'}>
                                        {file.name}
                                    </DText>

                                </ImageBox>
                            ))
                        }
                    </Flex>

                    :
                    <NoImage
                        width={'110px'}
                        height={'84px'}
                    />
                }
                <CMargin margin={'10px'} />
                <UploadBtn>
                    <Input
                        id={'fileUpload'}
                        type={'file'}
                        accept="image/*"
                        onChange={onChange}
                        multiple
                    />

                    <Text tid={'upload-file'} />
                </UploadBtn>
            </CFlex>

            {preview.show &&
                <PreviewImage onClick={() => setPreview(previewInitial)}>
                    <Flex fw fh>
                        <img
                            src={URL.createObjectURL(preview.file)}
                            alt={' '}
                            width={'100%'}
                            height={'100%'}
                            style={{ objectFit: 'scale-down' }}
                        />
                    </Flex>
                </PreviewImage>
            }
        </Wrapper>
    )
}


const Wrapper = styled.div`
  width: 100%;
  padding: 20px 1px 1px 1px;
  border: 1px dashed ${props => props.theme.color}80;
  border-radius: 4px;
`

const UploadBtn = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 28px;
  background-color: ${props => props.theme.color};
  color: ${props => props.theme.mainBg};
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
`

const ImageBox = styled(CFlex)`
  width: 100px;
  margin: 6px;
  border: 1px solid ${props => props.success ? props.theme.mainGreen : props.theme.color}15;
  padding: 4px;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

const PreviewImage = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  z-index: 1000;
`


export default MultiUpload
