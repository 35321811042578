import styled from 'styled-components'
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'
import { Flex } from '../../CommonStyles'


export const TradeTypeBadge = styled.div`
  padding: 4px 10px;
  background-color: ${props => props.color || props.theme.tInputBg}80;
  border: 1px solid ${props => props.color || props.theme.tInputBg};
  font-size: 0.8rem;
  border-radius: 8px;
  min-width: 64px;
  text-align: center;
  color: ${props => props.theme.color};
  line-break: auto;
  word-wrap: break-word;

  @media screen and (max-width: 1050px) {
    padding: 2px 10px;
    font-size: 0.7rem;
  };
`


export const TradeAmount = styled.div`
  color: ${props => (props.type === 'sell' || props.type === 'withdraw') ? props.theme.mainRed : props.theme.mainGreen}95;
  font-size: 0.75rem;
  
  padding: ${props => props.padding && props.padding};
`

export const BuyIcon = styled(FaArrowCircleUp)`
	color: ${(props) => props.theme.mainGreen};
	size: ${(props) => props.size || '14px'};
	filter: drop-shadow(0 0 4px ${(props) => props.theme.mainGreen});
`

export const SellIcon = styled(FaArrowCircleDown)`
	color: ${(props) => props.theme.mainRed};
    size: ${(props) => props.size || '14px'};
	filter: drop-shadow(0 0 4px ${(props) => props.theme.mainRed});
`




