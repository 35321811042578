import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";


const ROBOT_KEYS = {
    GET_ROBOT: 'get-robot',
    SET_ROBOT: 'set-robot'
}


const useGetRobotConfig = () => {

    return useQuery(
        ROBOT_KEYS.GET_ROBOT, () => normalFetch('settings/robots'),
        {
            select: res => res?.data?.data
        }
    )
}

const useSetRobotConfig = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        ROBOT_KEYS.SET_ROBOT, (data) => putApi(data, 'settings/robots'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(ROBOT_KEYS.GET_ROBOT)
                setToast({
                    show: true,
                    message: 'operation-success'
                })
            }
        }
    )
}

export {
    useGetRobotConfig,
    useSetRobotConfig
}
