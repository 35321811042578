import {useGetCoinCategories} from "../../../../core/services/react-query/setting/coin-list";
import {ScaleLoader} from "react-spinners";
import {getMainTheme} from "../../../../core/utils/theme";
import {CFlex, Column, Flex, Row} from "../../../../styles/CommonStyles";
import {coinCategoryAttributes} from "../../../../core/constants/headers";
import Text from "../../../../core/utils/Text";
import CoinTag from "../otc/elements/CoinTag";
import {EditIcon} from "../../../../styles/main/setting";
import ModalLayout from "../../../layout/main/ModalLayout";
import CoinCategoryModal from "./CoinCategoryModal";
import {useState} from "react";



const CoinCategory = () => {

    const { data: categories, isLoading: categoriesLoading } = useGetCoinCategories()
    const { headers, cs } = coinCategoryAttributes

    const initial = { show: false, category: null }
    const [catModalOpen, setCatModalOpen] = useState(initial)
    const closeCatModal = () => setCatModalOpen(initial)
    const openCatModal = (cat) => setCatModalOpen({ show: true, category: cat })

    return (
        <>
            <CFlex fw>
                {categoriesLoading ?
                    <Flex height={'300px'}>
                        <ScaleLoader color={getMainTheme().active} />
                    </Flex>
                    :
                    <>
                        <Row cs={cs} header={1}>
                            {headers.map(h => {
                                return (
                                    <Column key={h.title}>
                                        <Text tid={h.title} />
                                    </Column>
                                )
                            })}
                        </Row>
                        {categories.map((cat, idx) => {

                            return (
                                <Row cs={cs} index={idx} key={cat._id} noHover={1}>
                                    <Column>
                                        {cat.name}
                                    </Column>
                                    <Column>
                                        {cat.icon}
                                    </Column>
                                    <Column>
                                        <Flex fw wrap justify={'flex-start'}>
                                            {cat?.symbols.map(coin => (
                                                <CoinTag
                                                    coin={coin}
                                                />
                                            ))}
                                        </Flex>
                                    </Column>
                                    <Column>
                                        <EditIcon size={24} onClick={() => openCatModal(cat)}/>
                                    </Column>
                                </Row>
                            )
                        })}
                    </>
                }
            </CFlex>
            <ModalLayout
                width={'620px'}
                open={catModalOpen.show}
                onClose={closeCatModal}
            >
                <CoinCategoryModal
                    onSuccess={closeCatModal}
                    _category={catModalOpen.category}
                    type={'edit'}
                />
            </ModalLayout>
        </>

    )
}


export default CoinCategory
