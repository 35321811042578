import {useMutation, useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../../fetch-api/get";
import {useMainContext} from "../../../../contexts/main";
import {makeQueryFilters} from "../../../../utils/query-maker";
import { deleteApi } from "../../../fetch-api/delete";
import { useQueryContext } from "../../../../contexts/query";


const ORDER_KEYS = {
    GET_CURRENT_ORDERS: 'get-current-orders',
    GET_AN_ORDER: 'get-an-order',
    GET_TRADES: 'get-trades',
    GET_A_TRADE: 'get-a-trade',
    GET_OTC_ORDERS: 'get-otc-orders',
    GET_AN_OTC: 'get-an-otc',
    DELETE_CURRENT_ORDER: 'delete-current-order',
    GET_OTC_PARTY: 'get-otc-party'
}


const useGetCurrentOrders = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()

    const newFilters = {...extra, ...filters}
    const params = makeQueryFilters(newFilters, lang)
    const newParams = {...params, ...pagination}

    if (csv){
        newParams.export = 'csv'
    }

    return useQuery(
        ORDER_KEYS.GET_CURRENT_ORDERS, () => filterFetch(newParams, 'orders/admin'),
        {
            cacheTime: 0
        }
    )
}


const useGetAnOrder = (id) => {

    return useQuery(
        ORDER_KEYS.GET_AN_ORDER, () => normalFetch(`orders/${id}`),
        {
            select: res => res.data?.data,
            retry: 0
        }
    )
}


const useGetTrades = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = {...extra, ...filters}
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        ORDER_KEYS.GET_TRADES, () => filterFetch(params, 'trades/admin'),
        {
            cacheTime: 0
        }
    )
}


const useGetATrade = (id) => {

    return useQuery(
        ORDER_KEYS.GET_A_TRADE, () => normalFetch(`trades/${id}`),
        {
            select: res => res.data?.data,
            retry: 0
        }
    )
}


const useGetOtcOrders = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        ORDER_KEYS.GET_OTC_ORDERS, () => filterFetch(params, 'otc/admin'),
        {
            cacheTime: 0
        }
    )
}


const useGetAnOtcTrade = (id) => {

    return useQuery(
        ORDER_KEYS.GET_AN_OTC, () => normalFetch(`otc/${id}`),
        {
            select: res => res.data?.data,
            retry: 0
        }
    )
}


const useDeleteCurrentOrder = () => {

    const { queryClient } = useQueryContext()

    return useMutation(
        ORDER_KEYS.DELETE_CURRENT_ORDER, (id) => deleteApi(`orders/${id}`),
        {
            onSuccess: () => queryClient.invalidateQueries(ORDER_KEYS.GET_CURRENT_ORDERS)
        }
    )
}


const useGetOtcThirdparty = (id) => {

    return useQuery(
        ORDER_KEYS.GET_OTC_PARTY, () => normalFetch(`thirdparty/kucoin/order/${id}`),
        {
            enabled: false,
            select: res => res?.data?.data
        }
    )
}



export {
    useGetCurrentOrders,
    useGetAnOrder,
    useGetTrades,
    useGetATrade,
    useGetOtcOrders,
    useGetAnOtcTrade,
    useDeleteCurrentOrder,
    useGetOtcThirdparty
}
