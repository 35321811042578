import {ItemWrapper} from "../../../../styles/layout/filter";
import {useEffect, useRef, useState} from "react";
import {Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {CloseIcon, OpenIcon} from "../../../../styles/components/common/select-styles";
import useClickOutside from "../../../../core/hooks/common/useClickOutside";
import SortBox from "./SortBox";


const SortItem = (props) => {

    const { sort, order, options } = props
    const ref = useRef()
    const sortOption = options.find(item => item.sort === sort)

    const [open, setOpen] = useState(false)
    const onClose = () => {
        if (open) setOpen(false)
    }
    useClickOutside(ref, onClose)

    return (
        <ItemWrapper
            ref={ref}
            sort
        >
            <Flex
                onClick={() => setOpen(state => !state)}
                style={{ cursor: 'pointer' }}
            >
                <Text tid={sortOption?.title} /> :
                <Text style={{margin: '0 4px'}} tid={order === 1 ? 'ascending' : 'descending'} />
                {open ?
                    <CloseIcon size={22} />
                    :
                    <OpenIcon size={22} />
                }
            </Flex>
            {open &&
                <SortBox
                    type={'edit'}
                    sort={sortOption}
                    onClose={onClose}
                />
            }
        </ItemWrapper>
    )
}

export default SortItem
