import MotionFade from "../../../../common/utils/MotionFade";
import {useGetThirdOrderHistory} from "../../../../../core/services/react-query/setting/thirdparty";
import {useState} from "react";
import TableLayout from "../../../../layout/main/TableLayout";
import FilterLayout from "../../../../layout/filter/FilterLayout";
import {CacheKeys} from "../../../../../core/constants/filter";
import NobitexTableLayout from "../../../../layout/main/NobitexTableLayout";
import {nobitexOrderHistoryAttributes, thirdOrderHistoryAttributes} from "../../../../../core/constants/headers";
import NobitexOrderHistoryTable from "../thirdOrderHistory/NobitexOrderHistoryTable";


const ThirdOrderHistory = ({
    third
}) => {


    const { headers, cs } = thirdOrderHistoryAttributes
    const { headers: nobitexHeaders, cs: nobitexCs } = nobitexOrderHistoryAttributes
    const [data, setData] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setData(res)

    if (!third) return <></>

    return (
        <MotionFade>
            <FilterLayout
                query={useGetThirdOrderHistory}
                onQuerySuccess={onQuerySuccess}
                cache={CacheKeys.THIRD_ORDER_HISTORY}
                extra={{ third }}
                hasFilter={false}
            >
                {third === 'nobitex' ?
                    <NobitexTableLayout
                        headers={nobitexHeaders}
                        cs={nobitexCs}
                        data={data}
                        type={'trades'}
                    >
                        <NobitexOrderHistoryTable
                            data={data}
                            cs={nobitexCs}
                        />
                    </NobitexTableLayout>
                    :
                    <TableLayout
                        headers={headers}
                        cs={cs}
                        data={data}
                    >

                    </TableLayout>
                }

            </FilterLayout>
        </MotionFade>
    )
}

export default ThirdOrderHistory
