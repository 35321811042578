import ListLayout from "../../../components/layout/main/ListLayout";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {useState} from "react";
import {useGetAddresses} from "../../../core/services/react-query/report/address";
import {addressFilterOptions, addressSortOptions, CacheKeys} from "../../../core/constants/filter";
import {PageCard} from "../../../styles/CommonStyles";
import AddressesTable from "../../../components/main/reports/address/AddressesTable";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";
import RAddressesTable from "../../../components/responsive/main/reports/address/RAddressesTable";


const Addresses = () => {

    const { width } = useWindowSize()
    const [addresses, setAddresses] =  useState({data: null, loading: true})

    const onQuerySuccess = (res) => setAddresses(res)

    const Component = width > TABLET_SIZE ? AddressesTable : RAddressesTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetAddresses}
                    cache={CacheKeys.ADDRESSES}
                    options={addressFilterOptions}
                    onQuerySuccess={onQuerySuccess}
                    sortOptions={addressSortOptions}
                >
                    <Component
                        data={addresses}
                    />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}


export default Addresses
