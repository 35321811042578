import {IoCopy} from "react-icons/io5";
import {DText, Flex} from "../../../styles/CommonStyles";
import {useCopyToClipboard} from "../../../core/hooks/common/useCopyToClipboard";
import styled from "styled-components";
import {getMainTheme} from "../../../core/utils/theme";
import {useMainContext} from "../../../core/contexts/main";


const CopyText = ({ text, maxWidth, justify }) => {

    const { theme, lang } = useMainContext()
    const { copyToClip } = useCopyToClipboard()

    return (
        <Flex justify={justify || 'items-start'} style={{ cursor: 'pointer', gap: '4px' }} fw onClick={() => copyToClip(text)}>
            <Truncate maxWidth={maxWidth}>
                <DText main cFontSize={'0.75rem'}>
                    {text}
                </DText>
            </Truncate>
            <IoCopy style={{ marginTop: '-4px' }} size={20} color={getMainTheme(theme, lang).primary} />
        </Flex>
    )
}

const Truncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${props => props.maxWidth || '60%'};
`

export default CopyText
