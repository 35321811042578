import {motion} from 'framer-motion'
import { fadeVariants } from '../../../core/utils/theme'


const MotionFade = ({children}) => {

    return (
        <motion.div
            style={{ width: '100%' }}
            variants={{
                in: {
                    opacity: 1, transition: { duration: 0.2, delay: 0.2}
                },
                out: {
                    opacity: 0, transition: { duration: 0.2}
                }
            }}
            animate='in'
            exit='out'
            initial='out'
        >
            {children}
        </motion.div>
    )
}


export default MotionFade
