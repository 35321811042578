import { useEffect, useState } from "react"
import Text from "../../../../../core/utils/Text"
import { getTradeTypeColor } from "../../../../../core/utils/theme"
import { CFlex, CMargin, DText, Flex, Padding } from "../../../../../styles/CommonStyles"
import { PriceBar, BarColor } from "../../../../../styles/main/setting"
import { formatNumber } from "../../../../../core/utils/common"
import { useWindowSize } from "../../../../../core/hooks/common/useWindowSize"
import { TABLET_SIZE } from "../../../../../core/constants/common"
import PriceNumber from "../../../../common/utils/PriceNumber"
import MotionFade from "../../../../common/utils/MotionFade"


const PriceBox = ({
    coin,
    market
}) => {

    const { width } = useWindowSize()
    const [max, setMax] = useState({buy: 0, sell: 0})
    const [_static, setStatic] = useState({buy: 0, sell: 0})

    useEffect(() => {
        const selectedCoin = market?.find(item => item.id === coin?.id)
        if (selectedCoin) {
            setStatic({
                buy: selectedCoin?.buy,
                sell: selectedCoin?.sell
            })
        }
    }, [market, coin])


    useEffect(() => {
        if (market && coin) {
            let buy = 0
            let sell = 0
            buy = Math.max(coin?.isStatic ? _static.buy : 0, coin.price.buyMax, coin.price.buyMin)
            sell = Math.max(coin?.isStatic ? _static.sell : 0, coin.price.sellMax, coin.price.buyMax)
            setMax({buy, sell})
        }
    }, [market, coin, _static])


    const TypePriceBox = ({type}) => {

        return (
            <CFlex width={width > TABLET_SIZE ? '50%' : '100%'}>
                <Padding padding={width > TABLET_SIZE ? '0 10px' : '10px'}>
                    <DText main>
                        <Text tid={type} />
                    </DText>
                    <CMargin margin='6px' />
                    <Flex fw height='80px' align='flex-end'>

                        {coin?.isStatic &&
                            <PriceBar
                                height={_static[type] / max[type] * 100}
                                color={getTradeTypeColor('success')}
                            />
                        }

                        <PriceBar
                            height={coin?.price[`${type}Max`] / max[type] * 100}
                            color={getTradeTypeColor('limit')}
                        />
                        <PriceBar
                            height={coin?.price[`${type}Min`] / max[type] * 100}
                            color={getTradeTypeColor('error')}
                        />
                    </Flex>
                    <CMargin margin='8px' />
                    {coin?.isStatic &&
                        <Flex fw justify='space-between'>
                            <Flex>
                                <BarColor color={getTradeTypeColor('success')}/>
                                <DText main>
                                    <Text tid='static-price' /> :
                                </DText>
                            </Flex>

                            <PriceNumber
                                num={_static[type]}
                            />
                        </Flex>
                    }

                    <Flex fw justify='space-between'>
                        <Flex>
                            <BarColor color={getTradeTypeColor('limit')}/>
                            <DText main>
                                <Text tid='max-price' /> :
                            </DText>
                        </Flex>

                        <PriceNumber
                            num={coin?.price[`${type}Max`]}
                        />
                    </Flex>
                    <Flex fw justify='space-between'>
                        <Flex>
                            <BarColor color={getTradeTypeColor('error')}/>
                            <DText main>
                                <Text tid='min-price' /> :
                            </DText>
                        </Flex>
                        <PriceNumber
                            num={coin?.price[`${type}Min`]}
                        />
                    </Flex>

                </Padding>
            </CFlex>

        )
    }

    return (
        <MotionFade>
            <Flex fw wrap>
                <TypePriceBox
                    type='buy'
                />
                <TypePriceBox
                    type='sell'
                />
            </Flex>
        </MotionFade>
    )
}





export default PriceBox
