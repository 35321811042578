import useTrades from "../../../../../core/hooks/main/orders/useTrades";
import { formatNumber, getNames } from "../../../../../core/utils/common";
import Text from "../../../../../core/utils/Text";
import { getTradeTypeColor } from "../../../../../core/utils/theme";
import { CFlex, CMargin, DText, Flex, Padding, Row } from "../../../../../styles/CommonStyles";
import { BuyIcon, SellIcon, TradeAmount, TradeTypeBadge } from "../../../../../styles/main/orders";
import Avatar from "../../../../common/utils/Avatar";
import CoinMarket from "../../../../common/common/CoinMarket";
import Operation from "../../../../common/utils/Operation";
import RTableLayout from "../../../layout/RTableLayout"
import UserLink from "../../../../common/utils/UserLink";
import {RespIndex} from "../../../../../styles/main/MainCommonStyles";


const RTradesTable = ({
    data
}) => {

    const {data: trades} = data

    const {
        onDetailsClicked,
        hasWriteAccess
    } = useTrades()


    return (
        <RTableLayout
            data={data}
        >
            {trades?.data?.map((trade, idx) => {

                const {
                    FULLNAME: setterName, NAMEAVATAR: setterAvatar
                } = getNames(
                    trade.setter?.firstName,
                    trade.setter?.lastName
                )

                const {
                    FULLNAME: getterName, NAMEAVATAR: getterAvatar
                } = getNames(
                    trade.getter?.firstName,
                    trade.getter?.lastName
                )

                return(
                    <Row cs={'100%'} index={idx} key={trade._id}>
                        <Flex fw justify={'space-evenly'}>
                            <Padding padding={'8px 12px'}>
                                <CoinMarket coin={trade.order?.coin} pair={trade.order?.pair} />
                            </Padding>
                            <Flex justify={'flex-end'}>
                                <Operation
                                    onDetailsClicked={() => onDetailsClicked(trade)}
                                    hasWriteAccess={hasWriteAccess}
                                    hasDetails
                                />
                            </Flex>
                        </Flex>
                        <Flex fw justify={'space-evenly'}>

                            <CFlex width={'33%'}>
                                <DText main fontSize={'s'}>
                                    Maker
                                </DText>
                                <CMargin margin={'4px'} />
                                <Avatar
                                    avatar={trade.setter?.avatar}
                                    name={setterAvatar}
                                />
                                <CMargin margin={'6px'} />
                                <UserLink
                                    _id={trade.order?.userId}
                                    name={setterName}
                                />
                            </CFlex>

                            <CFlex width={'33%'}>
                                {trade.order?.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                                <Flex style={{ margin: '0 7px' }}>
                                    <TradeAmount type={trade.order?.type}>
                                        <Text tid={trade.order?.type} />
                                    </TradeAmount>
                                </Flex>
                                <CMargin margin={'6px'} />
                                <TradeTypeBadge color={getTradeTypeColor(trade.order?.submit)}>
                                    {trade.order?.submit}
                                </TradeTypeBadge>
                            </CFlex>

                            <CFlex width={'33%'}>
                                <DText main fontSize={'s'}>
                                    Taker
                                </DText>
                                <CMargin margin={'4px'} />
                                <Avatar
                                    avatar={trade.order?.Avatar}
                                    name={getterAvatar}
                                />
                                <CMargin margin={'6px'} />
                                <UserLink
                                    _id={trade.client?.userId}
                                    name={getterName}
                                />
                            </CFlex>
                        </Flex>
                        <CMargin margin={'8px'} />
                        <Flex fw justify='space-between'>
                            <DText fontSize={'s'} main>
                                <Text tid={'amount'} /> :
                            </DText>
                            <TradeAmount type={trade.order?.type}>
                                {trade.amount}
                            </TradeAmount>
                        </Flex>
                        <Flex fw justify='space-between'>
                            <DText fontSize={'s'} main>
                                <Text tid={'unitPrice'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                { formatNumber(trade.priceUnit) }
                            </DText>
                        </Flex>

                        <Flex fw justify='space-between'>
                            <DText fontSize={'s'} main>
                                <Text tid={'price'} /> :
                            </DText>
                            <DText fontSize={'s'} main>
                                { formatNumber(trade.price) }
                            </DText>
                        </Flex>

                        <RespIndex>
                           {idx + 1}
                        </RespIndex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}

export default RTradesTable
