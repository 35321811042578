import SettingCard from "../../../layout/main/SettingCard";
import {Flex} from "../../../../styles/CommonStyles";
import DatePicker from "react-multi-date-picker";
import transition from "react-element-popper/animations/transition";
import DateInput from "../../../common/input/DateInput";
import {useMainContext} from "../../../../core/contexts/main";
import CoinSelect from "../../../common/input/CoinSelect";
import Select from "../../../common/dropdown/Select";
import {useChartFilter, useGetPriceTimeOptions} from "./utils";
import EChart from "../../../layout/EChart";


const PriceTimeChart = () => {

    const { lang, theme } = useMainContext()

    const {
        date, setDate,
        coin, setCoin,
        mode, setMode,
        modes, records
    } = useChartFilter()

    const options = useGetPriceTimeOptions({ records, coin, mode })

    return (
        <SettingCard noTitle main>
            <Flex justify={'flex-start'} align={'flex-start'} style={{ gap: '18px' }} wrap>
                <div style={{ width: '180px' }}>
                    <DatePicker
                        value={date}
                        onChange={setDate}
                        onlyMonthPicker
                        animations={[transition()]}
                        render={<DateInput />}
                        className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal`}
                        calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
                    />
                </div>
                <div style={{ marginTop: '10px' }}>
                    <CoinSelect
                        value={coin}
                        onChange={setCoin}
                    />
                </div>
                <div style={{ marginTop: '10px' }}>
                    <Select
                        value={mode}
                        onValueChange={idx => setMode(modes[idx])}
                        options={modes}
                        fontSize={'s'}
                        height={'42px'}
                        width={'180px'}
                    />
                </div>
            </Flex>

            <EChart option={options} style={{ width: '100%', height: '360px' }} dir={'ltr'}/>
        </SettingCard>
    )
}


export default PriceTimeChart
