import {Background, Flex} from "../../../../../styles/CommonStyles";
import Input from "../../../../common/input/Input";


const RobotUserUpdate = ({
    robot,
    onDetailsChange
}) => {

    return (
        <Flex fw wrap justify={'space-between'}>
            <Flex>
                <Background bg={'secondaryBg'}>
                    <Input
                        value={robot?.userIp}
                        label={'user-ip'}
                        onInputChange={(v) => onDetailsChange(v, 'userIp')}
                        width={'300px'}
                        height={'32px'}
                        normal
                    />
                </Background>
            </Flex>
            <Flex style={{ margin: '6px 0' }}>
                <Background bg={'secondaryBg'}>
                    <Input
                        value={robot?.userId}
                        label={'user-id'}
                        onInputChange={(v) => onDetailsChange(v, 'userId')}
                        width={'300px'}
                        height={'32px'}
                        normal
                    />
                </Background>
            </Flex>
        </Flex>
    )
}


export default RobotUserUpdate
