import {useGetTradeStats, useGetOrderStats} from "../../../services/react-query/panel";
import {useEffect, useState} from "react";


const usePanelStats = (type) => {

    const query = type === 'trade' ? useGetTradeStats : useGetOrderStats

    const day = `${type}OfDay`
    const week = `${type}OfWeek`
    const month = `${type}OfMonth`
    const total = `${type}OfTotal`

    const { data } = query()

    const [panel, setPanel] = useState(null)

    useEffect(() => {
        if (data) {
            const newPanel = []
            // keys are coins !!
            Object.keys(data[day]).forEach(coin => {
                newPanel.push({
                    name: coin,
                    day: data[day][coin],
                    week: data[week][coin],
                    month: data[month][coin],
                    total: data[total][coin]
                })
            })
            setPanel(newPanel)
        }
    }, [data])


    return {
        data: panel
    }
}

export default usePanelStats
