import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAvailableCoins } from "../../../services/react-query/setting"
import { useGetWagesTrace } from "../../../services/react-query/report/trace"


const useWagesList = () => {

    const navigate = useNavigate()
    const { data: wagesList, isFetching: loading } = useGetWagesTrace()

    const [wages, setWages] = useState([])

    const { data: availableCoins } = useAvailableCoins()
    const [coin, setCoin] = useState('')

    const onDetailsClicked = (wage) => {
        navigate(`/reports/wages-list/${wage.currency}`)
    }

    const onCoinChange = (idx) => {
        setCoin(availableCoins[idx])
    }

    useEffect(() => {
        if (coin) {
            setWages(wagesList?.data?.filter(item => item.currency === coin.id))
        }else {
            setWages(wagesList?.data)
        }
    }, [coin, wagesList])

    return {
        wages,
        wagesList,
        loading,
        availableCoins,
        coin,
        onDetailsClicked,
        onCoinChange
    }
}


export default useWagesList
