import Tippy from '@tippyjs/react/headless'
import styled from "styled-components";
import Text from "../../../core/utils/Text";


const Tooltip = (props) => {

    const { children, content, placement, disabled } = props

    return (
        <>
            {disabled ?
                children
                :
                <Tippy
                    placement={placement}
                    render={attr => (
                        <TooltipBody
                            animation={false}
                            {...attr}
                        >
                            <Text tid={content} />
                        </TooltipBody>
                    )}
                >
                    {children}
                </Tippy>
            }
        </>

    )
}

const TooltipBody = styled.div`
  width: ${props => props.width ? props.width : 'fit-content'};
  padding: 6px 12px;
  border: 1px solid ${props => props.theme.color}50;
  background-color: ${props => props.theme.primaryBg};
  font-size: 0.8rem;
  border-radius: 4px;
  color: ${props => props.theme.color};
  white-space: nowrap;
  background-color: ${props => props.theme.input};
`


export default Tooltip
