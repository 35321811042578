import { SOCKET_URL } from "../../../core/constants/urls"
import Text from "../../../core/utils/Text"
import { DText, Flex } from "../../../styles/CommonStyles"
import {RiCloseLine} from "react-icons/ri"
import {BiCheck} from "react-icons/bi"
import PriceNumber from "../utils/PriceNumber"


const SettingRow = ({
    isCoin = false,
    isRadio = false,
    suffix,
    prefix,
    padding = null,
    priceNumber = false,
    coin,
    size = 'normal'
}) => {


    return (
        <Flex fw style={{padding: padding || '10px 0'}}>
            <Flex width='50%' justify='flex-start'>
                <DText main fontSize={size === 'small' ? 's' : 'm'}>
                    <Text tid={prefix} /> :
                </DText>
            </Flex>
            <Flex width='50%' justify='flex-start'>
                {isCoin ?
                    <>
                        <img
                            src={SOCKET_URL + `assets/icon/${suffix}.png`}
                            alt={' '}
                            width={'36px'}
                        />
                        <DText primary style={{margin: '0 12px'}}>
                            {suffix?.toUpperCase()}
                        </DText>
                    </>
                    : (
                        isRadio ?
                        (suffix ?
                            <BiCheck color={'#37b88b'} size={24}/>
                            :
                            <RiCloseLine color={'#f6465d'} size={24} />
                        )
                            :
                        ((typeof suffix === 'string' || typeof suffix === 'number') ?
                            (
                                priceNumber ?
                                    <PriceNumber
                                        num={suffix}
                                        coin={coin}
                                    />
                                    :
                                    <DText primary fontSize={size === 'small' ? 's' : 'm'} lineBreak='anywhere'>
                                        {suffix}
                                    </DText>
                            )
                            :
                            suffix
                        )
                    )
                }
            </Flex>
        </Flex>
    )
}


export default SettingRow
