import {
    useGetNobitexDepositAddress,
    useGetThirdBalances
} from "../../../../../core/services/react-query/setting/thirdparty";
import {useMemo, useState, useEffect} from "react";
import {CFlex, CMargin, Flex} from "../../../../../styles/CommonStyles";
import Select from "../../../../common/dropdown/Select";
import styled from "styled-components";
import CopyText from "../../../../common/utils/CopyText";
import QRCode from "react-qr-code";
import Loader from "../../../../common/loading/Loader";


const ThirdDepositAddress = ({
    third
}) => {

    const [coin, setCoin] = useState(null)
    const { data: address, isLoading } = useGetNobitexDepositAddress(third, coin?.toLowerCase())

    const { data: balances, refetch: getBalances } = useGetThirdBalances(third)
    useEffect(() => {
        if (third) getBalances()
    },[third])

    const coins = useMemo(() => {
        let temp = []
        if (balances) {
            temp = Object.keys(balances.response.wallets)
            temp = temp.filter(t => t !== 'RLS')
        }
        return temp
    }, [balances])

    return (
        <CFlex align={'flex-start'} fw >
            <CMargin margin={'10px'} />
            <Select
                options={coins}
                value={coin}
                onValueChange={idx => setCoin(coins[idx])}
                width={'330px'}
                placeHolder={'select-coin'}
            />
            {isLoading ?
                <Flex width={'330px'} height={'200px'}>
                    <Loader />
                </Flex>
            : null}
            {!!address ?
                <>
                    <CMargin margin={'10px'} />
                    <AddressWrapper>
                        <CopyText maxWidth={'90%'} text={address?.response?.address} />
                    </AddressWrapper>
                    <CMargin margin={'10px'} />
                    <QrWrapper>
                        <QrBody>
                            <QRCode style={{ width: '144px', height: '144px' }} value={address?.response?.address || ''} />
                        </QrBody>
                    </QrWrapper>
                </>
            : null}
        </CFlex>
    )
}

const AddressWrapper = styled.div`
  width: 330px;
  border-radius: 4px;
  height: 42px;
  border: 1px solid ${props => props.theme.mainOrange};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  color: ${props => props.theme.color};
`

const QrWrapper = styled.div`
  width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
`

const QrBody = styled.div`
  border-radius: 4px;
  padding: 4px;
  background: ${props => props.theme.primary};
`

export default ThirdDepositAddress
