import useTableLayout from "../../../core/hooks/layout/useTableLayout";
import Pagination from "../../common/utils/Pagination";
import Skeleton from "../../../core/packages/skeleton";
import RListSkeleton from "../skeleton/RListSkeleton";
import NoData from "../../common/utils/NoData";


const RTableLayout = ({
    data,
    hasPagination = true,
    children
}) => {

    const { data: _data, loading } = data
    const TOTAL = _data?.meta?.total ? _data?.meta.total : 0

    const {
        pagination,
        onPageChange,
        onLimitChange
    } = useTableLayout()

    return (
        <div style={{ margin: '10px 0' }}>
            <div style={{ minHeight: '300px' }}>
                {loading ?
                    <Skeleton
                        Content={RListSkeleton}
                        count={5}
                    />
                    :
                    _data?.data?.length ?
                        children
                        :
                        <NoData />
                }
            </div>
            {hasPagination &&
                <Pagination
                    total={TOTAL}
                    page={pagination?.page}
                    limit={pagination?.limit}
                    onPageChange={onPageChange}
                    onLimitChange={onLimitChange}
                />
            }

        </div>
    )
}

export default RTableLayout
