import {useFilterContext} from "../../contexts/filter";

const useGetTableIndex = () => {


    const { pagination: _pagination, cache } = useFilterContext()
    const pagination = _pagination[cache]

    const getTableIndex = (idx) => {
        const { page, limit } = pagination
        return (idx + 1) + (page - 1) * limit
    }

    return { getTableIndex }
}


export default useGetTableIndex
