import {useState} from "react";
import ProfileHead from "../../components/main/profile/ProfileHead";
import {ProfileWrapper} from "../../styles/main/profile";
import {CFlex, CMargin, PageCard} from "../../styles/CommonStyles";
import Tabbar from "../../components/common/tabs/Tabbar";
import ProfileInfo from "../../components/main/profile/ProfileInfo";
import ProfileBanks from "../../components/main/profile/ProfileBanks";
import ProfileWallets from "../../components/main/profile/ProfileWallets";
import {AnimatePresence} from "framer-motion";


const Profile = () => {

    /**
     * tabs => 1) profile info - 2) bank accounts - 3) wallets
     */
    const [tab, setTab] = useState(1)


    return (
        <ProfileWrapper>
            <ProfileHead />
            <CMargin margin={'10px'} />
            <PageCard style={{ minHeight: 'unset' }}>
                <CFlex fw>
                    <Tabbar
                        tabs={tabs}
                        active={tab}
                        onTabClicked={(idx) => setTab(idx)}
                    />
                    <AnimatePresence exitBeforeEnter>
                        {tab === 1 &&
                            <ProfileInfo />
                        }
                        {tab === 2 &&
                            <ProfileBanks />
                        }
                        {tab === 3 &&
                            <ProfileWallets />
                        }
                    </AnimatePresence>
                </CFlex>
            </PageCard>
        </ProfileWrapper>
    )
}


const tabs = [
    'profile-info', 'bank-accounts', 'wallets'
]

export default Profile
