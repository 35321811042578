import {useGetConfigItems, useSetConfigItems} from "../../../../../core/services/react-query/setting";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {useEffect, useState} from "react";
import {deepCopy, formatObjectNumbers} from "../../../../../core/utils/common";
import MotionFade from "../../../../common/utils/MotionFade";
import {MOBILE_SIZE} from "../../../../../core/constants/common";
import ABActionButton from "../ABActionButton";
import {Absolute, Background, CFlex, CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import Input from "../../../../common/input/Input";


const Discord = () => {

    const { width } = useWindowSize()
    const { data: configs } = useGetConfigItems()
    const { mutate: updateConfigs, isLoading } = useSetConfigItems()

    const [discordData, setDiscordData] = useState(null)
    const onInputValueChange = (v, type) => {
        setDiscordData(state => ({
            ...state,
            [type]: v
        }))
    }

    useEffect(() => {
        configs && setDiscordData(deepCopy(configs.discord))
    }, [configs])


    const onSubmitClicked = () => {
        const newWages = deepCopy(configs)
        newWages.discord = discordData
        updateConfigs({data: newWages})
    }

    return (
        <MotionFade>
            <CMargin margin={'30px'} />
            <Flex fw justify={'flex-start'}>
                <Decoration />
                <DText main>
                    <Text tid={'discord-setting-note'} />
                </DText>
            </Flex>
            <CMargin margin={'20px'} />
            <CFlex fw align={'flex-start'}>
                <Background bg={'mainBg'} dir={'ltr'}>
                    <Input
                        value={discordData?.bankDeposit}
                        onInputChange={(v) => onInputValueChange(v, 'bankDeposit')}
                        label={'d-bank-deposit'}
                    />
                    <Input
                        value={discordData?.bankWithdraw}
                        onInputChange={(v) => onInputValueChange(v, 'bankWithdraw')}
                        label={'d-bank-withdraw'}
                    />
                    <Input
                        value={discordData?.coinDeposit}
                        onInputChange={(v) => onInputValueChange(v, 'coinDeposit')}
                        label={'d-coin-deposit'}
                    />
                    <Input
                        value={discordData?.coinWithdraw}
                        onInputChange={(v) => onInputValueChange(v, 'coinWithdraw')}
                        label={'d-coin-withdraw'}
                    />
                    <Input
                        value={discordData?.userKyc}
                        onInputChange={(v) => onInputValueChange(v, 'userKyc')}
                        label={'d-user-kyc'}
                    />
                    <Input
                        value={discordData?.bankAccountKyc}
                        onInputChange={(v) => onInputValueChange(v, 'bankAccountKyc')}
                        label={'d-bank-kyc'}
                    />
                    <Input
                        value={discordData?.multiSignatureKyc}
                        onInputChange={(v) => onInputValueChange(v, 'multiSignatureKyc')}
                        label={'d-multi-kyc'}
                    />
                </Background>
            </CFlex>

            {width > MOBILE_SIZE ?
                <ABActionButton
                    show
                    onClick={onSubmitClicked}
                    loading={isLoading}
                />
                :
                <Absolute style={{ position: 'fixed' }} left={0} bottom={0} height={'60px'}>
                    <ABActionButton
                        show
                        onClick={onSubmitClicked}
                        loading={isLoading}
                    />
                </Absolute>
            }
        </MotionFade>
    )
}

export default Discord
