import usePairsSetting from "../../../../../core/hooks/main/setting/pairs/usePairsSetting"
import { Column, Flex, IconWrapper, Row } from "../../../../../styles/CommonStyles"
import { AddIcon } from "../../../../../styles/main/setting"
import CoinRow from "../../../../common/common/CoinRow"
import Operation from "../../../../common/utils/Operation"
import ModalLayout from "../../../../layout/main/ModalLayout"
import AddPairModal from "../../../../modals/AddPairModal"
import BasicModal from "../../../../modals/BasicModal"
import RTableLayout from "../../../layout/RTableLayout"


const RPairsTable = () => {

    const {
        pairs,
        hasWriteAccess,
        loading,
        addModal,
        enterAddMode,
        exitAddMode,
        onAddPair,
        deleteModal,
        enterDeleteMode,
        exitDeleteMode,
        onDeletePair,
        updatingPairs
    } = usePairsSetting()

    return (
        <>
            <Flex fw justify='flex-end'>
                <IconWrapper
                    onClick={enterAddMode}
                >
                    <AddIcon size={24}/>
                </IconWrapper>
            </Flex>
            <RTableLayout
                data={{ data: pairs, loading }}
                hasPagination={false}
            >
                {pairs?.data?.map((pair, idx) => (
                    <Row index={idx} cs={'70% 30%'}>
                        <Column>
                            <CoinRow
                                coin={pair}
                            />
                        </Column>
                        <Column>
                            <Operation
                                onDeleteClicked={() => enterDeleteMode(pair)}
                                hasWriteAccess={hasWriteAccess}
                                deleteTooltip='delete-pair'
                                hasDelete
                            />
                        </Column>
                    </Row>
                ))}
                <ModalLayout
                    open={addModal}
                    width={'520px'}
                    onClose={exitAddMode}
                >
                    <AddPairModal
                        currentPairs={pairs?.data}
                        onSubmit={onAddPair}
                        loading={updatingPairs}
                    />
                </ModalLayout>
                <ModalLayout
                    open={deleteModal.open}
                    width={'520px'}
                    onClose={exitDeleteMode}
                >
                    <BasicModal
                        head={'delete-pair-head'}
                        desc={'delete-pair-desc'}
                        onClose={exitDeleteMode}
                        onSubmit={onDeletePair}
                        loading={updatingPairs}
                    />
                </ModalLayout>
            </RTableLayout>
        </>
    )
}

export default RPairsTable
