import useUserLevels from "../../../../core/hooks/main/setting/user-levels/useUserLevels";
import {CFlex, CMargin, Decoration, DText, Flex, IconWrapper} from "../../../../styles/CommonStyles";
import Tooltip from "../../../common/utils/Tooltip";
import {EditIcon, ModeDecoration} from "../../../../styles/main/setting";
import {AnimatePresence} from "framer-motion";
import SubmitModal from "../../../modals/SubmitSettingModal";
import Text from "../../../../core/utils/Text";
import UserLevelsUpdate from "./UserLevelsUpdate";
import UserLevelsShow from "./UserLevelsShow";


const UserLevelsView = () => {

    const {
        userLevels,
        loading,
        editMode,
        enterEditMode,
        exitEditMode,
        onSubmitChanges,
        onInputValueChange,
        addNewLevel,
        deleteUserLevel
    } = useUserLevels()


    return (
        <>
            <CFlex fw>
                <Flex fw justify={'space-between'}>
                    <Flex>
                        <ModeDecoration editMode={editMode}/>
                        <DText main fontSize='s'>
                            <Text tid='mode' /> :
                        </DText>
                        <DText main style={{ margin: '0 4px' }} fontSize='s'>
                            <Text tid={`${editMode ? 'open' : 'close'}-mode` } />
                        </DText>
                    </Flex>
                    <Tooltip
                        content={'edit-user-levels'}
                    >
                        <IconWrapper onClick={enterEditMode}>
                            <EditIcon size={24} />
                        </IconWrapper>
                    </Tooltip>
                </Flex>
                <CMargin margin={'10px'} />
                <Flex fw justify='flex-start'>
                    <Decoration />
                    <DText main>
                        <Text tid='user-level-note' />
                    </DText>
                </Flex>
                <AnimatePresence exitBeforeEnter>
                    {editMode ?
                        <UserLevelsUpdate
                            userLevels={userLevels}
                            onInputValueChange={onInputValueChange}
                            addNewLevel={addNewLevel}
                            deleteUserLevel={deleteUserLevel}
                        />
                        :
                        <UserLevelsShow
                            userLevels={userLevels}
                        />
                    }
                </AnimatePresence>
            </CFlex>
            <SubmitModal
                editMode={editMode}
                onSubmit={onSubmitChanges}
                onCancel={exitEditMode}
                loading={loading}
            />
        </>
    )
}

export default UserLevelsView
