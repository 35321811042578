import { useTranslation } from 'react-i18next'

/**
 * Translator
 * @param tid - key for dictionary
 * @return {JSX.Element}
 * @constructor
 */
const Text = (props) => {
	const { tid } = props

	const { t } = useTranslation()
	const result = t(tid)
	return <span {...props}>{result ? result : tid}</span>
}

export default Text
