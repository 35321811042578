import RTableLayout from "../../../../responsive/layout/RTableLayout";
import {Column, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {formatDate, formatNumber} from "../../../../../core/utils/common";
import {MiniLineBreak} from "../../../../../styles/main/MainCommonStyles";
import CopyText from "../../../../common/utils/CopyText";
import {TradeTypeBadge} from "../../../../../styles/main/orders";
import {useMainContext} from "../../../../../core/contexts/main";


const RTable = ({data}) => {

    const { lang } = useMainContext()

    return (
        <RTableLayout
            data={data}
        >
            {data?.data?.data?.map((item, idx) => {

                return (
                    <Row cs={'100%'} key={item._id}>
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'amount'} />
                            <Column>{formatNumber(item.data?.payAmount)}</Column>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'wage'} />
                            <Column>{formatNumber(item.data?.wage)}</Column>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'sourceIban'} />
                            <Column><CopyText text={item.data?.sourceIban} justify={'flex-end'} /></Column>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'destinationIban'} />
                            <Column><CopyText text={item.data?.destinationIban} justify={'flex-end'} /></Column>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'payId'} />
                            <Column><CopyText text={item.payId} justify={'flex-end'} /></Column>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'thirdParty'} />
                            <Column>
                                <TradeTypeBadge>
                                    {item.thirdParty}
                                </TradeTypeBadge>
                            </Column>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'} style={{ fontSize: '0.7rem' }}>
                            <Text tid={'payId'} />
                            <Column><CopyText text={item.trackingCode} justify={'flex-end'} /></Column>
                        </Flex>
                        <MiniLineBreak />
                        <Flex justify={'space-between'}>
                            <DText fontSize={'s'} main>
                                {formatDate(item.createdAt, 'date', lang)}
                            </DText>
                            <DText fontSize={'s'} main>
                                {formatDate(item.createdAt, 'time', lang)}
                            </DText>
                        </Flex>
                    </Row>
                )
            })}
        </RTableLayout>
    )
}

export default RTable
