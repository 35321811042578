import {DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import UserLink from "../../../../common/utils/UserLink";


const RobotUser = ({
    robot
}) => {

    return (
        <Flex fw justify={'space-between'} wrap>
            <Flex style={{ margin: '2px 0' }}>
                <DText main>
                    <Text tid={'user-ip'} /> :
                </DText>
                <DText primary style={{ margin: '0 5px' }}>
                    {robot?.userIp}
                </DText>
            </Flex>
            <Flex style={{ margin: '2px 0' }}>
                <DText main>
                    <Text tid={'user-id'} /> :
                </DText>
                <UserLink
                    style={{ margin: '0 5px' }}
                    size={'big'}
                    _id={robot?.userId}
                    name={robot?.userId}
                />
            </Flex>
        </Flex>
    )
}


export default RobotUser
