import {useMainContext} from "../../../contexts/main";
import {makeQueryFilters} from "../../../utils/query-maker";
import {useMutation, useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {putApi} from "../../fetch-api/put";
import {postApi} from "../../fetch-api/post";
import {deleteApi} from "../../fetch-api/delete";
import {useQueryContext} from "../../../contexts/query";


const TOKEN_KEYS = {
    GET_ALL_TOKENS: 'get-all-tokens',
    GET_TOKENS: 'get-tokens',
    GET_BLOCKCHAINS: 'get-blockchains',
    GET_A_TOKEN: 'get-a-token',
    UPDATE_TOKEN: 'update-token',
    CREATE_TOKEN: 'create-token',
    DELETE_TOKEN: 'delete-token',
    UPDATE_TOKEN_ICON: 'update-token-icon',
}


const useGetTokens = (filters, pagination) => {

    const { lang } = useMainContext()
    const params = {...makeQueryFilters(filters, lang), ...pagination}

    return useQuery(
        TOKEN_KEYS.GET_TOKENS, () => filterFetch(params, 'crypto/tokens')
    )
}


const useGetAllTokens = () => {

    return useQuery(
        TOKEN_KEYS.GET_ALL_TOKENS, () => normalFetch('crypto/tokens/all'),
        {
            select: res => res?.data?.data
        }
    )
}

const useGetAToken = (id, enabled = true) => {

    return useQuery(
        TOKEN_KEYS.GET_A_TOKEN, () => normalFetch(`crypto/tokens/${id}`),
        {
            select: res => res?.data?.data,
            enabled
        }
    )
}


const useGetBlockchains = () => {

    return useQuery(
        TOKEN_KEYS.GET_BLOCKCHAINS, () => normalFetch('settings/coins/networks'),
        {
            select: res => res?.data?.data,
            staleTime: 30 * 60 * 1000,
            cacheTime: 30 * 60 * 1000
        }
    )
}


const useUpdateAToken = () => {

    return useMutation(
        TOKEN_KEYS.UPDATE_TOKEN, (data) => putApi(data, `crypto/tokens/${data._id}`)
    )
}


const useCreateAToken = () => {

    return useMutation(
        TOKEN_KEYS.CREATE_TOKEN, (data) => postApi(data, 'crypto/tokens')
    )
}


const useDeleteAToken = () => {

    const { queryClient } = useQueryContext()

    return useMutation(
        TOKEN_KEYS.DELETE_TOKEN, (id) => deleteApi(`crypto/tokens/${id}`),
        {
            onSuccess: () => queryClient.invalidateQueries(TOKEN_KEYS.GET_TOKENS)
        }
    )
}


const useSetTokenIcon = () => {
    
    return useMutation(
        TOKEN_KEYS.UPDATE_TOKEN_ICON, (data) => putApi(data.icon, `crypto/tokens/icon/${data.symbol}`) 
    )
}

export {
    useGetTokens,
    useGetAllTokens,
    useGetAToken,
    useGetBlockchains,
    useUpdateAToken,
    useCreateAToken,
    useDeleteAToken,
    useSetTokenIcon
}
