import {useEffect, useState} from "react";
import {useAvailableCoins} from "../../../../services/react-query/setting";
import {deepCopy} from "../../../../utils/common";
import {useNavigate} from "react-router-dom";
import {useDeleteAToken} from "../../../../services/react-query/setting/token";
import {useSetAvailableCoins} from "../../../../services/react-query/setting";
import { useQueryContext } from "../../../../contexts/query";


const useTokens = (tokens) => {

    const navigate = useNavigate()
    const {setToast} = useQueryContext()

    const [tokenList, setTokenList] = useState([])

    const deleteModalInitial = {
        open: false, token: null
    }
    const [deleteModal, setDeleteModal] = useState(deleteModalInitial)
    const [deleteFromACModal, setDeleteFromACModal] = useState(deleteModalInitial)

    const { mutate: setAvailableCoins, isLoading: acLoading, isSuccess: coinsSet } = useSetAvailableCoins()
    const { mutate: deleteToken, isLoading: deletingToken } = useDeleteAToken()

    useEffect(() => {
        if (tokens) {
            setTokenList(tokens?.data)
        }
    }, [tokens])

    const { data: availableCoins } = useAvailableCoins()
    useEffect(() => {
        if (availableCoins && tokens) {
            const temp = []
            const symbols = availableCoins.map(item => item.id)
            tokens.data?.forEach(token => {
                const isAvailable = symbols.includes(token.symbol)
                const _token = deepCopy(token)
                _token.isAvailable = !!isAvailable
                temp.push(_token)
            })
            setTokenList(temp)
        }
    }, [availableCoins, tokens])

    const onDetailsClicked = (token) => {
        navigate(`/setting/tokens/${token._id}`)
    }

    const onOptionClicked = (idx, token) => {
        switch (idx) {
            case 0:
                navigate(`/setting/tokens/operation?type=edit&tokenId=${token?._id}`)
                break
            case 1:
                setDeleteModal({
                    open:true, token
                })
                break
            case 2:
                if (token.isAvailable) {
                    setDeleteFromACModal({
                        open: true, token
                    })
                }else {
                    navigate(`/setting/available-coins?coin=${token?.symbol}`)
                }
                break
            default:
                break
        }
    }

    const onDeleteModalClose = () => setDeleteModal(deleteModalInitial)

    const onDeleteToken = (fromDetail = false) => {
        deleteToken(deleteModal.token?._id)
        onDeleteModalClose()
        setDeleteModal(deleteModalInitial)

        if (fromDetail) {
            navigate('/setting/tokens')
        }

        setToast({
            show: true,
            message: 'operation-success'
        })
    }


    const onACModalClose = () => setDeleteFromACModal(deleteModalInitial)
    const onDeleteFromAC = () => {
        if (deleteFromACModal.token) {
            const newCoinSet = availableCoins.filter(item => item.symbol !== deleteFromACModal.token.symbol)
            setAvailableCoins(newCoinSet)
        }
    }

    useEffect(() => {
        if (coinsSet) {
            onACModalClose()
        }
    }, [coinsSet])


    return {
        tokenList,
        onDetailsClicked,
        onOptionClicked,
        deleteModal,
        onDeleteModalClose,
        deletingToken,
        onDeleteToken,
        deleteFromACModal,
        onACModalClose,
        onDeleteFromAC,
        acLoading

    }
}


export default useTokens
