import {DetailsBody, DetailsBorder, DetailsWrapper} from "../../../styles/main/MainCommonStyles";
import Skeleton from "../../../core/packages/skeleton";
import DetailSkeleton from "../../skeleton/DetailSkeleton";
import NoData from "../../common/utils/NoData";


const DetailsLayout = (props) => {

    const {
        isFetching,
        isError,
        type = null,
        children
    } = props

    return (
        <DetailsWrapper {...props}>
            <DetailsBorder>
                <DetailsBody type={type}>
                    {isFetching &&
                        <Skeleton
                            Content={DetailSkeleton}
                        />
                    }
                    {isError &&
                        <NoData />
                    }
                    {(!isError && !isFetching) &&
                        children
                    }
                </DetailsBody>
            </DetailsBorder>
        </DetailsWrapper>
    )
}


export default DetailsLayout
