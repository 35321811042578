import AffiliateListDetails from "./AffiliateListDetails"
import AffilateReportList from "../../../components/main/reports/affiliates/trace/AffiliateReportList"
import { useMainContext } from "../../../core/contexts/main"


const AffiliateReport = () => {

    const { affiliateTab } = useMainContext()

    const Component = affiliateTab === 2 ? AffilateReportList : AffiliateListDetails

    return (
        <Component />
    )
}


export default AffiliateReport
