import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import {useState} from "react";
import FilterLayout from "../../../components/layout/filter/FilterLayout";
import {useGetCurrentOrders, useGetOtcOrders, useGetTrades} from "../../../core/services/react-query/report/orders";
import {
    CacheKeys,
    currentOrderSortOptions,
    orderFilterOptions, otcSortOptions,
    otcTradeFilterOptions,
    tradeFilterOptions, tradeSortOptions
} from "../../../core/constants/filter";
import Tabbar from "../../../components/common/tabs/Tabbar";
import Orders from "../../../components/main/reports/all-orders/currentOrders/Orders";
import Trades from "../../../components/main/reports/all-orders/trades/Trades";
import OtcTrades from "../../../components/main/reports/all-orders/otc/OtcTrades";
import {useMainContext} from "../../../core/contexts/main";
import {useAclContext} from "../../../core/contexts/acl";


const AllOrders = () => {

    const orderComponentDetails = [
        { query: useGetCurrentOrders, Component: Orders, options: orderFilterOptions, cache: CacheKeys.ORDER, sortOptions: currentOrderSortOptions },
        { query: useGetTrades, Component: Trades, options: tradeFilterOptions, cache: CacheKeys.TRADE, sortOptions: tradeSortOptions },
        { query: useGetOtcOrders, Component: OtcTrades, options: otcTradeFilterOptions, cache: CacheKeys.OTC, sortOptions: otcSortOptions }
    ]

    /**
     * tabs ==> 1) all-currentOrders - 2) trades - 3) otc trades
     */
    const { orderTab, setOrderTab } = useMainContext()
    const [selectedOption, setSelectedOption] = useState(orderComponentDetails[orderTab - 1])

    /**
     * download perm
     */
    const { permissions } = useAclContext()
    const hasDownload = permissions?.export?.write

    const [ items, setItems ] = useState({data: null, loading: true})

    const onAllOrdersQuery = (res) => {
        setItems(res)
    }

    const onTabChange = (idx) => {
        setOrderTab(idx)
        setSelectedOption(orderComponentDetails[idx - 1])
    }

    const {query, Component, options, cache, sortOptions} = selectedOption

    return (
        <ListLayout>
            <PageCard>
                <Tabbar
                    tabs={['current-orders', 'trades', 'otc-orders']}
                    active={orderTab}
                    onTabClicked={onTabChange}
                />
                <FilterLayout
                    query={query}
                    options={options}
                    onQuerySuccess={onAllOrdersQuery}
                    cache={cache}
                    extra={orderTab === 1 ? { isDelete: false } : {}}
                    hasDownload={hasDownload}
                    sortOptions={sortOptions}
                >
                    <Component
                        data={items}
                    />
                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default AllOrders;
