import {useEffect, useState} from "react";
import {Column, Row} from "../../../../styles/CommonStyles";
import {BalanceWrapper} from "../../../../styles/main/user";
import Text from "../../../../core/utils/Text";
import CoinRow from "../../../common/common/CoinRow";
import {useGetPredefinedCoins} from "../../../../core/services/react-query/setting";
import {formatNumber} from "../../../../core/utils/common";

const UserBalances = ({
    user
}) => {

    const { data: allCoins } = useGetPredefinedCoins()
    const [balances, setBalances] = useState([])

    useEffect(() => {
        if (user && allCoins) {
            let temp = [{
                id: 'usd',
                fa: 'dollar',
                name: 'USD',
                amount: user.balance,
                amountBlocked: user.amountBlocked
            }]
            user.coins.forEach(coin => {
                const _coin = allCoins.find(item => item.id === coin.coin)
                const balance = {
                    id: _coin?.id ? _coin.id : coin.coin,
                    fa: _coin?.fa ? _coin.fa : coin.coin,
                    name: _coin?.name ? _coin.name : coin.coin,
                    amount: coin.amount,
                    amountBlocked: coin.amountBlocked
                }
                temp.push(balance)
            })
            setBalances(temp)
        }
    }, [user, allCoins])


    return (
        <BalanceWrapper fw>
            <Row cs={CS} header>
                {headers.map(head => (
                    <Column>
                        <Text tid={head} />
                    </Column>
                ))}
            </Row>

            {balances.map((balance, idx) => (
                <Row index={idx} cs={CS}>
                    <Column>
                        {idx + 1}
                    </Column>
                    <Column>
                        <CoinRow
                            coin={balance}
                        />
                    </Column>
                    <Column>
                        {formatNumber(balance.amount)}
                    </Column>
                    <Column>
                        {formatNumber(balance.amountBlocked)}
                    </Column>
                </Row>
            ))}
        </BalanceWrapper>
    )
}

const CS = '10% 30% 30% 30%'
const headers = [
    'index', 'market', 'amount', 'amount-blocked'
]

export default UserBalances
