import Tabbar from "../../../components/common/tabs/Tabbar";
import ListLayout from "../../../components/layout/main/ListLayout";
import {Flex, PageCard} from "../../../styles/CommonStyles";
import {useState} from "react";
import CoinCategory from "../../../components/main/setting/coin-list/CoinCategory";
import FavoriteCoins from "../../../components/main/setting/coin-list/FavoriteCoins";
import {AddIcon} from "../../../styles/main/setting";
import Tooltip from "../../../components/common/utils/Tooltip";
import ModalLayout from "../../../components/layout/main/ModalLayout";
import CoinCategoryModal from "../../../components/main/setting/coin-list/CoinCategoryModal";


const CoinList = () => {

    const tabs = ['coin-category', 'favorite-coins']
    const [activeTab, setActiveTab] = useState(1)
    const onTabChange = idx => setActiveTab(idx)

    const options = [CoinCategory, FavoriteCoins]
    const ActiveCoin = options[activeTab - 1]

    const [catModalOpen, setCatModalOpen] = useState(false)
    const closeCatModal = () => setCatModalOpen(false)
    const openCatModal = () => setCatModalOpen(true)

    return (
        <ListLayout>
            <PageCard>
                <Flex fw justify={'space-between'}>
                    <Tabbar
                        tabs={tabs}
                        active={activeTab}
                        onTabClicked={onTabChange}
                    />
                    {activeTab === 1 &&
                        <Tooltip content={'add-category'}>
                            <div onClick={openCatModal}>
                                <AddIcon size={24} />
                            </div>
                        </Tooltip>
                    }
                </Flex>
                <ActiveCoin />
            </PageCard>
            <ModalLayout
                width={'620px'}
                open={catModalOpen}
                onClose={closeCatModal}
            >
                <CoinCategoryModal
                    onSuccess={closeCatModal}
                />
            </ModalLayout>
        </ListLayout>
    )
}

export default CoinList
