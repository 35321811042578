import {UserDetailListWrapper} from "../../../../styles/main/user";
import FilterLayout from "../../../layout/filter/FilterLayout";
import {CacheKeys, tradeFilterOptions} from "../../../../core/constants/filter";
import Trades from "../../reports/all-orders/trades/Trades";
import {useGetTrades} from "../../../../core/services/react-query/report/orders";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {useAclContext} from "../../../../core/contexts/acl";


const UserTrades = () => {

    const params = useParams()
    const [trades, setTrades] = useState({loading: true, data: null})
    const { permissions } = useAclContext()
    const hasDownload = permissions?.export?.write
    const onQuerySuccess = (res) => {
        setTrades(res)
    }

    return (
        <UserDetailListWrapper>
            <FilterLayout
                query={useGetTrades}
                options={tradeFilterOptions}
                onQuerySuccess={onQuerySuccess}
                cache={CacheKeys.USER_TRADES}
                extra={{ 'client.userId': params.id }}
                hasDownload={hasDownload}
            >
                <Trades
                    data={trades}
                />
            </FilterLayout>
        </UserDetailListWrapper>
    )
}

export default UserTrades
