import {useContext, createContext} from "react";
import useNetwork from "../hooks/common/useNetwork";


const Context = createContext()

export const useConnectionContext = () => {
    return useContext(Context)
}

const ConnectionContextProvider = ({ children }) => {

    const status = useNetwork()

    return (
        <Context.Provider value={{
            status
        }}>
            {children}
        </Context.Provider>
    )
}

export default ConnectionContextProvider;
