import { DText, Flex} from "../../../styles/CommonStyles";
import {BankStatus, BankWrapper, CardsAnimate} from "../../../styles/main/profile";
import Text from "../../../core/utils/Text";
import {formatDate} from "../../../core/utils/common";
import {useMainContext} from "../../../core/contexts/main";
import WalletCard from "../../../core/packages/wallet-service/WalletCard";
import {fadeVariants} from "../../../core/utils/theme";
import useGetProfileOrUser from "../../../core/hooks/main/users/useGetProfileOrUser";
import NoData from "../../common/utils/NoData";


const ProfileWallets = ({
    detail = false,
    user = null
}) => {

    const { lang } = useMainContext()
    const { profile } = useGetProfileOrUser(detail, user)
    const wallets = profile?.wallets

    return (
        <CardsAnimate
            variants={fadeVariants}
            animate='in'
            exit='out'
            initial='out'
        >
            {wallets?.map(wallet => {

                return (
                    <BankWrapper
                        key={wallet.verifyAt}
                    >
                        <WalletCard
                            item={wallet}
                        />
                        <Flex fw justify={'space-around'}>
                            <Flex>
                                <DText main>
                                    <Text tid='status' /> :
                                </DText>
                                <BankStatus
                                    verified={!!wallet.verifyAt}
                                >
                                    <Text tid={wallet.verifyAt ? 'verified' : 'pending'} />
                                </BankStatus>
                            </Flex>
                            <Flex>
                                <DText main>
                                    <Text tid='verify-date' /> :
                                </DText>
                                <DText main>
                                    {wallet.verifyAt ?
                                        formatDate(wallet.verifyAt, 'date', lang)
                                        :
                                        <Text tid='not-verified' />
                                    }
                                </DText>
                            </Flex>
                        </Flex>
                    </BankWrapper>
                )
            })}
            {!wallets?.length &&
                <NoData
                    desc={'no-wallet-found'}
                />
            }
        </CardsAnimate>
    )

}

export default ProfileWallets
