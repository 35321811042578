import styled from "styled-components";
import {Flex} from "../../../styles/CommonStyles";


const ListLayout = ({children}) => {


    return (
        <ListWrapper>
            {children}
        </ListWrapper>
    )
}


const ListWrapper = styled(Flex)`
  margin-top: 70px;
  padding: 0 40px;
  
  @media screen and (max-width: 768px) {
    padding: 0 8px;
  };
`

export default ListLayout
