import { useState } from "react"
import { useQueryContext } from "../../core/contexts/query"
import Text from "../../core/utils/Text"
import { Background, CFlex, CMargin, Decoration, DText, Flex } from "../../styles/CommonStyles"
import BasicModalActionButton from "../common/buttons/BasicModalActionButton"
import Input from "../common/input/Input"


const AddNetworkModal = ({
    details,
    onSubmit,
    onCancel
}) => {

    const {setToast} = useQueryContext()
    const {type, coin} = details

    const initialState = {
        network: '',
        min: 0,
        max: 0,
        feeFactor: 0,
        feeMax: 0,
        scanner: ''
    }
    const [network, setNetwork] = useState(initialState)

    const onNetworkInputChange = (v, type) => {
        setNetwork(state => ({...state, [type]: v}))
    }

    const onSubmitClicked = () => {
        const valid = network.network
        if (valid) onSubmit(network)
        else setToast({
            isError: true, show: true,
            message: 'fill-input-errors'
        })
    }

    return (
        <CFlex fw align='flex-start'>
            <Background bg='mainBg'>
                <Flex>
                    <Decoration />
                    <DText main>
                        <Text tid={`add-${type}`} />
                    </DText>
                </Flex>
                <CMargin margin='10px' />
                <Input
                    value={network.network}
                    label={'network'}
                    onInputChange={(v) => onNetworkInputChange(v, 'network')}
                    normal
                />
                <Input
                    value={network.min}
                    label={'min'}
                    onInputChange={(v) => onNetworkInputChange(v, 'min')}
                    currency={coin.id}
                    number
                    normal
                />
                <Input
                    value={network.max}
                    label={'max'}
                    onInputChange={(v) => onNetworkInputChange(v, 'max')}
                    currency={coin.id}
                    number
                    normal
                />
                <Input
                    value={network.feeFactor}
                    label={'fee-factor'}
                    onInputChange={(v) => onNetworkInputChange(v, 'feeFactor')}
                    number
                    normal
                />
                <Input
                    value={network.feeMax}
                    label={'fee-max'}
                    onInputChange={(v) => onNetworkInputChange(v, 'feeMax')}
                    currency={coin.id}
                    number
                    normal
                />
                <Input
                    value={network.scanner}
                    label={'scanner'}
                    placeholder={'https://blockchair.com/$coin/transaction/$TXID'}
                    onInputChange={(v) => onNetworkInputChange(v, 'scanner')}
                    normal
                />
                <BasicModalActionButton
                    onSubmit={onSubmitClicked}
                    onCancel={onCancel}
                />
            </Background>
        </CFlex>
    )
}


export default AddNetworkModal
