import TableLayout from "../../../../layout/main/TableLayout";
import {formatNumber, getNames} from "../../../../../core/utils/common";
import {Column, DText, Flex, Row} from "../../../../../styles/CommonStyles";
import Avatar from "../../../../common/utils/Avatar";
import CoinMarket from "../../../../common/common/CoinMarket";
import {BuyIcon, SellIcon, TradeAmount, TradeTypeBadge} from "../../../../../styles/main/orders";
import {getTradeTypeColor} from "../../../../../core/utils/theme";
import Text from "../../../../../core/utils/Text";
import DateTime from "../../../../common/utils/DateTime";
import Operation from "../../../../common/utils/Operation";
import useTrades from "../../../../../core/hooks/main/orders/useTrades";
import UserLink from "../../../../common/utils/UserLink";
import useGetTableIndex from "../../../../../core/hooks/layout/useGetTableIndex";
import {tradeAttributes} from "../../../../../core/constants/headers";


const TradesTable = ({
    data
}) => {

    const {data: trades} = data
    const { cs, headers } = tradeAttributes
    const { getTableIndex } = useGetTableIndex()

    const {
        onDetailsClicked,
        hasWriteAccess
    } = useTrades()

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasWriteAccess={hasWriteAccess}
        >
            {trades?.data?.map((trade, idx) => {
                const {
                    FULLNAME: setterName, NAMEAVATAR: setterAvatar
                } = getNames(
                    trade.setter?.firstName,
                    trade.setter?.lastName
                )

                const {
                    FULLNAME: getterName, NAMEAVATAR: getterAvatar
                } = getNames(
                    trade.getter?.firstName,
                    trade.getter?.lastName
                )

                return (
                    <Row cs={cs} index={idx} key={trade._id}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column avatar>
                            <Flex width='42px'>
                                <Avatar
                                    name={setterAvatar}
                                    avatar={trade.setter?.avatar}
                                />
                            </Flex>
                            <UserLink
                                _id={trade.order?.userId}
                                name={setterName}
                            />
                        </Column>
                        <Column avatar>
                            <Flex width='42px'>
                                <Avatar
                                    name={getterAvatar}
                                    avatar={trade.getter?.avatar}
                                />
                            </Flex>
                            <UserLink
                                _id={trade.client?.userId}
                                name={getterName}
                            />
                        </Column>
                        <Column>
                            <CoinMarket
                                coin={trade.order?.coin}
                                pair={trade.order?.pair}
                            />
                        </Column>
                        <Column>
                            <TradeTypeBadge color={getTradeTypeColor(trade.order?.submit)}>
                                {trade.order?.submit}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            <TradeAmount type={trade.order?.type}>
                                {formatNumber(trade.amount)}
                            </TradeAmount>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(trade.priceUnit)}
                            </DText>
                        </Column>
                        <Column>
                            <DText main fontSize={'s'}>
                                {formatNumber(trade.price)}
                            </DText>
                        </Column>
                        <Column>
                            <Flex>
                                {trade.order?.type === 'buy' ? <BuyIcon /> : <SellIcon />}
                                <Flex style={{ margin: '0 7px' }}>
                                    <TradeAmount type={trade.order?.type}>
                                        <Text tid={trade.order?.type} />
                                    </TradeAmount>
                                </Flex>
                            </Flex>
                        </Column>
                        <Column center>
                            <DateTime dt={trade.createdAt} />
                        </Column>
                        <Column>
                            <Operation
                                onDetailsClicked={() => onDetailsClicked(trade)}
                                hasWriteAccess={hasWriteAccess}
                                hasDetails
                            />
                        </Column>
                    </Row>
                )
            })}
        </TableLayout>
    )
}



export default TradesTable
