import { motion } from "framer-motion";
import styled, {css} from "styled-components";
import {Flex} from "../CommonStyles";
import {AiOutlineEye} from "react-icons/ai";
import {BiCopy} from "react-icons/bi";


export const DetailsBorder = styled.div`
  min-width: 960px;
  //box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // border: 1px solid ${props => props.theme.color}40;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    min-width: 760px;
  };
  
  @media screen and (max-width: 1050px) {
    min-width: 600px;
  };

  @media screen and (max-width: 768px) {
    min-width: 95%;
    margin: 0 10px;
  };
`


export const DetailsBody = styled.div`
  width: calc(100% - 4px);
  margin: 2px 0;

  background-color: ${props => props.theme.mainBg};

  ${props => props.type && css`
    background-image: linear-gradient(
            135deg, 
            ${({type, theme}) => type === 'success' ? theme.mainGreen : (
                type === 'error' ? theme.mainRed : theme.mainOrange
            ) }20 0%, 
            ${props => props.theme.mainBg} 40%);
  `};
  
  border-radius: 2px;
  padding: 20px;
  @media screen and (max-width: 768px) {
    padding: 8px;
  };
`

export const DetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 200px;
  margin-bottom: 30px;
`

export const RightHeadDecoration = styled.div`
  position: absolute;
  z-index: 2;
  transition: all 0.3s;
  transform: rotate(45deg);
  width: 58px;
  height: 58px;
  bottom: calc(100% + 12px);
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > div {
    transform: rotate(-45deg);
  };
  right: 15px;
`


export const LeftHeadDecoration = styled.div`
  position: absolute;
  z-index: 2;
  transition: all 0.3s;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  height: 52px;
  bottom: 100%;
  overflow-x: visible;
  padding: 0 10px;
`

export const RespIndex = styled(Flex)`
  border-top: 1px solid ${props => props.theme.color}15;
  padding-top: 6px;
  margin-top: 4px;
`


export const DetailRow = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  row-gap: 2px;
  
  ${props => !props.noBorder && css`
    border-bottom: 1px solid ${props => props.theme.color}20;
  `};
  padding: 10px 0;
  margin: 4px 0;
`


export const TxidLink = styled.a`
    line-break: anywhere;
    color: #2196f3;
    text-decoration: underline;
    font-size: 0.8rem;
    direction: ltr;
  letter-spacing: 1.1px;

  @media screen and (max-width: 1050px){
    font-size: 0.7rem;
  };
`


export const SubpageCard = styled(motion.div)`
  width: 240px;
  height: 320px;
  border: 1px solid ${props => props.theme.color}10;
  border-radius: 8px;
  box-shadow: 0 0 8px rgb(0, 0, 0, 0.05);
  background-color: ${props => props.theme.secondaryBg};
  margin: 15px;
  padding: 30px 8px;
  filter: brightness(0.9);
  cursor: pointer;
  transition: all 0.5s;

  &:hover{
    filter: brightness(1.1);
    transform: scale(1.05);
  };

  @media screen and (max-width: 768px) {
    height: 260px;
  };
`

export const LineBreak = styled.div`
  margin: 5px auto;
  height: 1px;
  background-color: ${props => props.theme.color}10;
  width: 100%;
  border-radius: 4px;
`

export const MiniLineBreak = styled.div`
  margin: 5px auto;
  height: 1px;
  background-color: ${props => props.theme.color}20;
  width: 32px;
  border-radius: 4px;
`

export const CartableAttachmentBox = styled(Flex)`
  border: 1px solid ${props => props.theme.color}20;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
`

export const ShowIcon = styled(AiOutlineEye)`
  color: ${props => props.theme.primary};
  cursor: pointer;
`

export const CopyIcon = styled(BiCopy)`
  color: ${props => props.theme.primary};
  margin: 0 4px;
  cursor: pointer;
`

