import { normalFetch } from '../../fetch-api/get';
import { putApi } from "../../fetch-api/put"
import { useQuery, useMutation } from 'react-query';
import {useQueryContext} from "../../../contexts/query"


const PAIR_KEYS = {
    GET_PAIRS: 'get-pairs',
    SET_PAIRS: 'set-pairs'
}


const useGetPairs = () => {

    return useQuery(
        PAIR_KEYS.GET_PAIRS, () => normalFetch('settings/pairs'),
        {
            select: res => res?.data
        }
    )
}


const useSetPairs = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        PAIR_KEYS.SET_PAIRS, (data) => putApi(data, 'settings/pairs'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(PAIR_KEYS.GET_PAIRS)
                setToast({
                    show: true,
                    message: 'operation-success'
                })
            }
        }
    )
}


export {
    useGetPairs,
    useSetPairs
}