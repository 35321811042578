import styled from "styled-components";
import {Flex} from "../CommonStyles";
import {FiAlertTriangle} from 'react-icons/fi'


export const ModalHeader = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.color}15;
  padding: 12px 0;
`


export const AlertIcon = styled(FiAlertTriangle)`
  color: ${props => props.theme.mainOrange};
`
