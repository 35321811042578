import {useCrumbs} from "../../../core/hooks/common/useCrumbs";
import {NavWrapper} from "../../../styles/layout/navigator";
import {DetailsLtr, DetailsRtl, DText, Flex, Padding} from "../../../styles/CommonStyles";
import {useMainContext} from "../../../core/contexts/main";
import {useNavigate} from "react-router-dom";
import NavigatorItem from "./NavigatorItem";


const Navigator = () => {

    const {crumbs, getCrumbStatus, crumbIcons} = useCrumbs()
    const { lang, sidebar } = useMainContext()
    const navigate = useNavigate()

    const SIDEBAR_OPEN = sidebar === 'desktop'
    const LAST_ITEM = crumbs.length - 1

    const CrumbIcon = () => {
        const pathIdx = crumbs.length > 1 ? 1 : 0
        const { path } = getCrumbStatus(pathIdx)
        const Icon = crumbIcons[path]
        return (
            <>
                {Icon && <Icon style={{ margin: '5px 8px 0 8px' }} size={20}/>}
            </>
        )
    }

    const onCrumbClicked = (idx) => {
        const { path, valid } = getCrumbStatus(idx)
        valid && navigate(path)
    }

    return (
        <NavWrapper open={SIDEBAR_OPEN}>
            <DText main>
                <CrumbIcon />
            </DText>
            {crumbs.map((crumb, idx) => (
                    <Flex
                        key={crumb}
                    >
                        <NavigatorItem
                            idx={idx}
                            crumb={crumb}
                            onCrumbClicked={onCrumbClicked}
                        />
                        {idx !== LAST_ITEM &&
                            <Padding padding={'0 6px'}>
                                {lang === 'en' ?
                                    <DetailsLtr size={16}/>
                                    :
                                    <DetailsRtl size={16}/>
                                }
                            </Padding>
                        }
                    </Flex>
            ))}
        </NavWrapper>
    )
}


export default Navigator
