import TableLayout from "../../../layout/main/TableLayout"
import { Column, DText, Flex, IconWrapper, Row } from "../../../../styles/CommonStyles"
import CoinRow from "../../../common/common/CoinRow"
import Operation from "../../../common/utils/Operation"
import ModalLayout from "../../../layout/main/ModalLayout"
import BasicModal from "../../../modals/BasicModal"
import Tooltip from "../../../common/utils/Tooltip"
import {AddIcon} from "../../../../styles/main/setting"
import AddPairModal from "../../../modals/AddPairModal"
import usePairsSetting from "../../../../core/hooks/main/setting/pairs/usePairsSetting"
import {pairAttributes} from "../../../../core/constants/headers";



const PairsTable = () => {

    const { cs, headers } = pairAttributes
    const {
        pairs,
        hasWriteAccess,
        loading,
        addModal,
        enterAddMode,
        exitAddMode,
        onAddPair,
        deleteModal,
        enterDeleteMode,
        exitDeleteMode,
        onDeletePair,
        updatingPairs
    } = usePairsSetting()

    return(
            <>
                <Flex fw justify='flex-end'>
                    <Tooltip
                        content='add-pair'
                    >
                        <IconWrapper
                            onClick={enterAddMode}
                        >
                            <AddIcon size={24}/>
                        </IconWrapper>
                    </Tooltip>
                </Flex>
                <TableLayout
                    headers={headers}
                    cs={cs}
                    data={{data: pairs, loading}}
                    hasPagination={false}
                    hasWriteAccess={hasWriteAccess}
                >
                    {pairs?.data?.map((pair, idx) => (
                        <Row index={idx} cs={cs}>
                            <Column>
                                {idx + 1}
                            </Column>
                            <Column>
                                <CoinRow
                                    coin={pair}
                                />
                            </Column>
                            <Column>
                                <DText main>
                                    {pair.name}
                                </DText>
                            </Column>
                            <Column>
                                <DText main>
                                    {pair.fa}
                                </DText>
                            </Column>
                            <Column>
                                <Operation
                                    onDeleteClicked={() => enterDeleteMode(pair)}
                                    hasWriteAccess={hasWriteAccess}
                                    deleteTooltip='delete-pair'
                                    hasDelete
                                />
                            </Column>
                        </Row>
                    ))}
                </TableLayout>
                <ModalLayout
                    open={addModal}
                    width={'520px'}
                    onClose={exitAddMode}
                >
                    <AddPairModal
                        currentPairs={pairs?.data}
                        onSubmit={onAddPair}
                        loading={updatingPairs}
                    />
                </ModalLayout>
                <ModalLayout
                    open={deleteModal.open}
                    width={'520px'}
                    onClose={exitDeleteMode}
                >
                    <BasicModal
                        head={'delete-pair-head'}
                        desc={'delete-pair-desc'}
                        onClose={exitDeleteMode}
                        onSubmit={onDeletePair}
                        loading={updatingPairs}
                    />
                </ModalLayout>
            </>
        )
}


export default PairsTable
