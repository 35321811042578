import MotionFade from "../../../../common/utils/MotionFade";
import {
    useGetThirdWithdrawHistory
} from "../../../../../core/services/react-query/setting/thirdparty";
import {useState} from "react";
import TableLayout from "../../../../layout/main/TableLayout";
import FilterLayout from "../../../../layout/filter/FilterLayout";
import {CacheKeys} from "../../../../../core/constants/filter";
import NobitexTableLayout from "../../../../layout/main/NobitexTableLayout";
import NobitexOrderHistoryTable from "../thirdOrderHistory/NobitexOrderHistoryTable";
import {nobitexWithdrawHistoryAttributes, thirdWithdrawHistoryAttributes} from "../../../../../core/constants/headers";
import NobitexWithdrawsTable from "../thirdWithdrawsHistory/NobitexWithdrawsTable";


const ThirdWithdrawHistory = ({
   third
}) => {

    const { cs, headers } = thirdWithdrawHistoryAttributes
    const { cs: nobitexCs, headers: nobitexHeaders } = nobitexWithdrawHistoryAttributes
    const [data, setData] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setData(res)

    if (!third) return <></>

    return (
        <MotionFade>
            <FilterLayout
                query={useGetThirdWithdrawHistory}
                onQuerySuccess={onQuerySuccess}
                cache={CacheKeys.THIRD_WITHDRAW_HISTORY}
                extra={{ third }}
                hasFilter={false}
            >
                {third === 'nobitex' ?
                    <NobitexTableLayout
                        headers={nobitexHeaders}
                        cs={nobitexCs}
                        data={data}
                        type={'withdraws'}
                    >
                        <NobitexWithdrawsTable
                            data={data}
                            cs={nobitexCs}
                        />
                    </NobitexTableLayout>
                    :
                    <TableLayout
                        headers={headers}
                        cs={cs}
                        data={data}
                    >

                    </TableLayout>
                }
            </FilterLayout>
        </MotionFade>
    )
}

const CS = '5% 20% 20% 20% 20% 15%'
const headers = [
    'coin', 'type', 'balance', 'available', 'holds'
]

export default ThirdWithdrawHistory
