import Text from "../../core/utils/Text"
import { CFlex, CMargin, DText, Flex } from "../../styles/CommonStyles"
import { AlertIcon, ModalHeader } from "../../styles/modals"
import BasicModalActionButton from "../common/buttons/BasicModalActionButton"



const BasicModal = ({
    head, desc,
    _id, onClose,
    onSubmit,
    loading
}) => {


    return (
        <CFlex fw align='flex-start'>
            <ModalHeader>
                <DText primary>
                    <Text tid={head ? head : 'basic-modal-head'} />
                </DText>
                <AlertIcon size={30}/>
            </ModalHeader>
            <CMargin margin={'15px'} />
            <Flex justify='flex-end' fw>
                <DText main>
                    {_id}
                </DText>
            </Flex>
            <CMargin margin='10px' />
            <DText main>
                <Text tid={desc ? desc : 'basic-modal-desc'} />
            </DText>
            <CMargin margin='15px' />

            <BasicModalActionButton
                onSubmit={onSubmit}
                onCancel={onClose}
                loading={loading}
            />
        </CFlex>
    )
}

export default BasicModal
