import Dropdown from "../dropdown/Dropdown";
import {DeleteIcon, DetailsLtr, DetailsRtl, Flex, IconWrapper} from "../../../styles/CommonStyles";
import {useMainContext} from "../../../core/contexts/main";
import Tooltip from "./Tooltip";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";


const Operation = (props) => {

    const {
        options = [],
        onOptionClicked,
        onDetailsClicked,
        onDeleteClicked,
        hasDetails,
        hasWriteAccess,
        hasDelete,
        deleteTooltip
    } = props

    const { lang } = useMainContext()
    const { width } = useWindowSize()

    return (
        <Flex fw justify={'flex-end'}>
            {(hasWriteAccess && hasDelete )
                ?
                    <Tooltip
                        content={deleteTooltip}
                    >
                        <IconWrapper padding={'4px'} onClick={onDeleteClicked}>
                            <DeleteIcon size={22} />
                        </IconWrapper>
                    </Tooltip>
            :null}
            {(hasWriteAccess && options.length)
                ?
                <Dropdown
                    options={options}
                    onOptionClicked={onOptionClicked}
                    minWidth={'150px'}
                />
            :null}

            {hasDetails &&
                <Tooltip
                    content={'details'}
                >
                    <IconWrapper
                        onClick={onDetailsClicked}
                    >
                        {lang === 'en' ?
                            <DetailsLtr size={width > TABLET_SIZE ? 24 : 18 } />
                            :
                            <DetailsRtl size={width > TABLET_SIZE ? 24 : 18 } />
                        }
                    </IconWrapper>
                </Tooltip>
            }

        </Flex>
    )

}

export default Operation
