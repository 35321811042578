import styled from "styled-components";

export const SKeletonText = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.big ? '40px' : '20px'};
  border-radius: 4px;
  background-color: ${props => props.theme.skeleton};
  margin: ${props => props.margin};
  position: relative;
  overflow: hidden;
`


export const SkeletonAvatar = styled.div`
  width: ${props => props.size || '32px'};
  height: ${props => props.size || '32px'};
  background-color: ${props => props.theme.skeleton};
  border-radius: 50%;
  position: relative;
  overflow: hidden;
`


export const SkeletonBar = styled.div`
  width: 42px;
  height: 148px;
  border: 2px solid ${props => props.theme.secondary}30;
  border-radius: 8px 8px 0 0;
  position: relative;

  &::after{
    content: ' ';
    background-color: ${props => props.type === 'sell' ? props.theme.mainRed : props.theme.mainGreen}70;
    width: 100%;
    height: ${props => props.percent ? props.percent : 0}%;
    position: absolute;
    bottom: 0;
  };
`
