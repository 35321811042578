import {useFilterContext} from "../../contexts/filter";


const useTableLayout = () => {


    const { pagination: _pagination, setPagination, cache } = useFilterContext()
    const pagination = _pagination[cache]

    const onPageChange = (page) => {
        const newState = {...pagination}
        newState.page = page
        setPagination(newState)
    }

    const onLimitChange = (limit) => {
        const newState = {...pagination}
        newState.limit = limit
        setPagination(newState)
    }

    const onSortChange = (sortKey) => {
        if (!pagination) return
        const newState = { ...pagination }
        let newSortOrder = 1
        const oldSortKey = pagination.sort
        const sameKey = sortKey === oldSortKey

        const oldSortOrder = pagination.order
        if (oldSortOrder && sameKey) {
            if (oldSortOrder === 1) newSortOrder = -1
            if (oldSortOrder === -1) newSortOrder = 0
        }
        if (newSortOrder) {
            newState.sort = sortKey
            newState.order = newSortOrder
        }else {
            delete newState.order
            delete newState.sort
        }

        setPagination(newState)
    }

    return {
        pagination,
        onPageChange,
        onLimitChange,
        onSortChange
    }
}

export default useTableLayout
