
export const initialAcl = {
    user: {read: false, write: false},
    panel: {read: false, write: false},
    trace: {read: false, write: false},
    order: {read: false, write: false},
    trade: {read: false, write: false},
    otc: {read: false, write: false},
    setting: {read: false, write: false},
    coining: {read: false, write: false},
    banking: {read: false, write: false},
    notification: {read: false, write: false},
    ticket: {read: false, write: false},
    balance: {read: false, write: false},
    role: {read: false, write: false},
    affiliate: {read: false, write: false},
    thirdParty: {read: false, write: false},
    config: {read: false, write: false},
    export: {read: false, write: false},
    multiSignature: {read: false, write: false},
    moveToMaster: {read: false, write: false},
    address: {read: false, write: false}
}

export const superAdmin = {
    user: {read: true, write: true},
    panel: {read: true, write: true},
    trace: {read: true, write: true},
    order: {read: true, write: true},
    trade: {read: true, write: true},
    otc: {read: true, write: true},
    setting: {read: true, write: true},
    coining: {read: true, write: true},
    banking: {read: true, write: true},
    notification: {read: true, write: true},
    ticket: {read: true, write: true},
    balance: {read: true, write: true},
    role: {read: true, write: true},
    affiliate: {read: true, write: true},
    thirdParty: {read: true, write: true},
    config: {read: true, write: true},
    export: {read: true, write: true},
    multiSignature: {read: true, write: true},
    moveToMaster: {read: true, write: true},
    address: {read: true, write: true}
}
