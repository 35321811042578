import Input from "../../../common/input/Input";
import {forwardRef, useImperativeHandle, useState} from "react";
import {Background, CFlex, CMargin, DText} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {useFilterContext} from "../../../../core/contexts/filter";
import {useQueryContext} from "../../../../core/contexts/query";


const NameFilter = forwardRef((props, ref) => {

    const { setToast } = useQueryContext()

    const { onClose } = props
    const { filters: _filters, setFilters, cache } = useFilterContext()
    const filters = _filters[cache]

    const [firstName, setFirstName] = useState(filters.firstName ? filters.firstName : '')
    const [lastName, setLastName] = useState(filters.lastName ? filters.lastName : '')


    useImperativeHandle(ref, () => ({
        submitFilters: () => {
            if (firstName || lastName) {
                const newState = {...filters}
                newState.firstName = firstName
                newState.lastName = lastName
                setFilters(newState)
                onClose()
            }else {
                setToast({
                    show: true, isError: true,
                    message: 'fill-input-errors'
                })
            }
        }
    }))

    return (
        <CFlex>
            <Background bg='primaryBg'>
                <DText main>
                    <Text tid={'filter-name'} />
                </DText>
                <CMargin margin={'4px'} />
                <Input
                    size={'small'}
                    value={firstName}
                    onInputChange={(v) => setFirstName(v)}
                    label={'firstName'}
                />
                <CMargin margin={'4px'} />
                <Input
                    size={'small'}
                    value={lastName}
                    onInputChange={(v) => setLastName(v)}
                    label={'lastName'}
                />
            </Background>
        </CFlex>
    )
})

export default NameFilter
