import { TABLET_SIZE } from "../../../../../core/constants/common"
import { useWindowSize } from "../../../../../core/hooks/common/useWindowSize"
import RAffiliateTraceTable from "../../../../responsive/main/reports/affiliates/RAffiliateTraceTable"
import AffiliateTraceTable from "./AffilateTraceTable"



const AffiliateTrace = () => {

    const { width } = useWindowSize()
    const Component = width > TABLET_SIZE ? AffiliateTraceTable : RAffiliateTraceTable

    return (
        <>
            {width && <Component />}
        </>
    )
}


export default AffiliateTrace
