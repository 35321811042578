import {useGetWages, useSetWages} from "../../../../services/react-query/setting/wages";
import {useEffect, useState} from "react";
import {deepCopy, formatObjectNumbers, makeObjectFormattedNumber, stringToNumber} from "../../../../utils/common";


export const DOLLAR_FEE_TYPES = {
    BUY_STATIC: 'buyStatic',
    BUY_MAX: 'buyMax',
    BUY_MIN: 'buyMin',
    BUY_FACTOR: 'buyFactor',
    SELL_STATIC: 'sellStatic',
    SELL_MAX: 'sellMax',
    SELL_MIN: 'sellMin',
    SELL_FACTOR: 'sellFactor'
}

const useDollarFee = () => {


    const { data: wages, isFetching: fetchingWages } = useGetWages()
    const { mutate: updateWages, isLoading: updatingWages, isSuccess: wagesUpdated } = useSetWages()

    const [ dollarFee, setDollarFee ] = useState(null)
    const [editMode, setEditMode] = useState(false)

    const resetDollarFee = () => setDollarFee(makeObjectFormattedNumber(wages.dollar))

    const enterEditMode = () => setEditMode(true)
    const exitEditMode = () => {
        resetDollarFee()
        setEditMode(false)
    }

    useEffect(() => {
        if (wages) {
            resetDollarFee()
        }
    }, [wages])

    useEffect(() => {
        if (wagesUpdated) exitEditMode()
    }, [wagesUpdated])

    const onInputValueChange = (v, type) => {
        setDollarFee(state => ({
            ...state,
            [type]: v
        }))
    }


    const onSubmitChanges = () => {
        const newDollarFee = deepCopy(wages)
        newDollarFee.dollar = formatObjectNumbers(dollarFee)
        updateWages({data: newDollarFee})
    }


    return {
        dollarFee,
        loading: fetchingWages || updatingWages,
        editMode,
        enterEditMode,
        exitEditMode,
        onSubmitChanges,
        onInputValueChange
    }
}


export default useDollarFee
