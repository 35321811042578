import {useChangeUserStatus} from "../../../services/react-query/user";
import {useEffect, useState} from "react";


const useBlockUserAccess = (user, refetch) => {

    const { mutate, isLoading: loading, isSuccess } = useChangeUserStatus()

    const [detailsChanged, setDetailsChanged] = useState(false)
    const initialState = {
        order: false, otc: false,
        withdraw: false, deposit: false
    }
    const [accessData, setAccessData] = useState(initialState)

    useEffect(() => {
        if (user?.block) {
            setAccessData(user.block)
        }
    }, [user])

    useEffect(() => {
        if (accessData) {
            checkChanges()
        }
    }, [accessData])

    useEffect(() => {
        if (isSuccess) {
            refetch()
        }
    }, [isSuccess])

    const checkChanges = () => {
        const cache = user?.block ? user?.block : initialState
        const cacheKeys = Object.keys(cache)
        let changed = false
        for (let i = 0; i < cacheKeys.length; i++) {
            const key = cacheKeys[i]
            const cacheItem = cache[key]
            const newItem = accessData[key]
            if (newItem !== cacheItem) {
                changed = true
                break
            }
        }
        setDetailsChanged(changed)
    }

    const onInputValueChange = (v, type) => {
        if (type === 'deposit') {
            setAccessData(state => ({
                ...state,
                deposit: v,
                depositCoining: v,
                depositBanking: v
            }))
            return
        }
        if (type === 'withdraw') {
            setAccessData(state => ({
                ...state,
                withdraw: v,
                withdrawCoining: v,
                withdrawBanking: v
            }))
            return
        }
        setAccessData(state => ({
            ...state,
            [type]: v
        }))
    }

    const onSubmitClicked = () => {
        const payload = {
            data: {
                block: accessData
            },
            id: user._id
        }
        mutate(payload)
    }

    return {
        onSubmitClicked,
        onInputValueChange,
        accessData,
        loading,
        detailsChanged
    }
}

export default useBlockUserAccess
