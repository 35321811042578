import {Column, TableBody, Row, Flex, TableWrapper} from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import Skeleton from "../../../core/packages/skeleton";
import ListSkeleton from "../../skeleton/ListSkeleton";
import NoData from "../../common/utils/NoData";
import SortButton from "../../common/buttons/SortButton";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import NobitexPagination from "../../common/utils/NobitexPagination";
import {useFilterContext} from "../../../core/contexts/filter";
import {useMemo} from "react";


const NobitexTableLayout = ({
    headers,
    cs,
    children,
    data,
    hasWriteAccess,
    hasPagination = true,
    type
}) => {

    const { data: _data, loading } = data
    const { width } = useWindowSize()

    const hasNext = useMemo(() => {
        return data?.data?.data?.response?.hasNext
    }, [data])

    const { pagination: _pagination, setPagination, cache } = useFilterContext()
    const pagination = _pagination[cache]

    const onNextPage = () => {
        const newState = {...pagination}
        newState.page = newState.page + 1
        setPagination(newState)
    }

    const onLastPage = () => {
        const newState = {...pagination}
        newState.page = newState.page - 1
        setPagination(newState)
    }


    const centerHeaders = [
        'date', 'cause', 'action', 'share',
        'is-active', 'is-available', 'traded-date',
        'permissions', 'file-name', 'file-type',
        'file-status', 'status', 'trace-type'
    ]

    const TableHead = () => {
        return (
            <Row cs={cs} header style={{ padding: '4px 20px' }}>
                <Column>
                    <Text tid={'index'} />
                </Column>
                {headers?.map(item => (
                    <Column
                        key={item}
                        center={centerHeaders.includes(item.title)}
                        style={{ cursor: item.sort && 'pointer' }}
                    >
                        <Flex>
                            <Text tid={item.title} />
                            {item.sort &&
                                <SortButton
                                    active={
                                        pagination?.sort === item.sort ?
                                            pagination?.order : false
                                    }
                                />
                            }
                        </Flex>
                    </Column>
                ))}
                {hasWriteAccess &&
                    <Column center />
                }
            </Row>
        )
    }

    return (
        <>
            {width > 1400 &&
                <TableHead />
            }
            <TableBody>
                <TableWrapper>
                    {width <= 1400 &&
                        <TableHead />
                    }
                    <div style={{ minHeight: '300px' }}>
                        {loading ?
                            <Skeleton
                                Content={ListSkeleton}
                                count={5}
                                params={{ cs, headers }}
                            />
                            :
                            (
                                (_data && !_data?.data?.response[type]?.length) ?
                                    <NoData />
                                    :
                                    // <div style={{
                                    //     height: '54vh',
                                    //     overflowY: 'auto',
                                    //     overflowX: 'hidden',
                                    //     padding: '0 20px'
                                    // }}>
                                    children
                                // </div>
                            )
                        }
                    </div>
                </TableWrapper>
            </TableBody>
            {hasPagination &&
                <NobitexPagination
                    pagination={pagination}
                    onNext={onNextPage}
                    onBack={onLastPage}
                    hasNext={hasNext}
                />
            }
        </>

    )
}


export default NobitexTableLayout
