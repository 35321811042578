import {useMutation, useQuery} from "react-query";
import {filterFetch, normalFetch} from "../../fetch-api/get";
import {useMainContext} from "../../../contexts/main";
import {makeQueryFilters} from "../../../utils/query-maker";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";


export const COINING_KEYS = {
    GET_COIN_TRANSACTIONS: 'get_coin_transactions',
    GET_COIN_CONFIG: 'get-coin-config',
    UPDATE_COIN_TRANSACTION: 'update-coin-transaction',
    GET_A_TRANSACTION: 'get-a-coin-transaction',
    CONFIRM_COINING_TRANSACTION: 'confirm-coining-transaction'
}

const useGetCoinTransactions = (filters, pagination, extra, csv) => {

    const { lang } = useMainContext()
    const newFilters = { ...extra, ...filters }
    const params = {...makeQueryFilters(newFilters, lang), ...pagination}
    if (csv){
        params.export = 'csv'
    }

    return useQuery(
        COINING_KEYS.GET_COIN_TRANSACTIONS, () => filterFetch(params, 'coining/admin'),
        {
            cacheTime: 0
        }
    )
}


const useGetCoiningConfig = () => {

    return useQuery(
        COINING_KEYS.GET_COIN_CONFIG, () => normalFetch('configs/coining'),
        {
            select: (res) => res?.data?.data
        }
    )
}


const useConfirmCoinTransaction = (onSuccess) => {

    const { queryClient } = useQueryContext()

    return useMutation(
        COINING_KEYS.UPDATE_COIN_TRANSACTION, ({ payload, id }) => putApi(payload, `coining/confirm/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(COINING_KEYS.GET_COIN_TRANSACTIONS)
                onSuccess && onSuccess()
            }
        }
    )
}

const useUpdateCoinTransaction = (onSuccess = () => {}, onError = () => {}) => {

    return useMutation(
        COINING_KEYS.CONFIRM_COINING_TRANSACTION, ({ data, id }) => putApi(data, `coining/${id}`),
        {
            onSuccess,
            onError
        }
    )
}

const useUpdateNobitexCoinTransaction = (onSuccess = () => {}, onError = () => {}) => {

    return useMutation(
        COINING_KEYS.CONFIRM_COINING_TRANSACTION, ({ data, id }) => putApi(data, `coining/nobitex/${id}`),
        {
            onSuccess,
            onError
        }
    )
}


const useGetACoinTransaction = (id) => {

    return useQuery(
        COINING_KEYS.GET_A_TRANSACTION, () => normalFetch(`coining/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}


export {
    useGetCoinTransactions,
    useGetCoiningConfig,
    useUpdateCoinTransaction,
    useGetACoinTransaction,
    useConfirmCoinTransaction,
    useUpdateNobitexCoinTransaction
}
