import {useNavigate, useParams} from "react-router-dom";
import {useAclContext} from "../../../contexts/acl";


const useOtcTrades = () => {

    const params = useParams()
    const navigate = useNavigate()
    const { permissions } = useAclContext()
    const hasWriteAccess = permissions.otc?.write

    const onDetailsClicked = (order) => {
        if (params.tab)
            navigate(`/users/${params.id}/${params.tab}/${order._id}?type=otc`)
        else
            navigate(`/reports/orders/${order._id}?type=otc`)
    }

    return {
        hasWriteAccess,
        onDetailsClicked
    }
}

export default useOtcTrades
