import { QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'
import {useQueryContext} from "./core/contexts/query";
import MainContextProvider from "./core/contexts/main";
import Index from './pages'
import './core/i18n'


const App = () => {

    const { queryClient } = useQueryContext()

    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <MainContextProvider>
          <Index />
        </MainContextProvider>
      </QueryClientProvider>
    );
}

export default App;
