
export const walletInfo = [
    {name: "btc", color:"#f7931a", shadow: '#F9AE53', primary: '#FABC70'},
    {name: "eth", color:"#21325b", shadow: '#596584', primary: '#747F98'},
    {name: "usdt", color:"#26a17b", shadow: '#3C997C', primary: '#5CB99C'},
    {name: "usdc", color:"#26a17b", shadow: '#3C997C', primary: '#5CB99C'},
    {name: "bnb", color:"#f0b90b", shadow: '#F4CB48', primary: '#D4AB28'},
    {name: "busd", color:"#f0b90b", shadow: '#F4CB48', primary: '#D4AB28'},
    {name: "ada", color:"#7c96d6", shadow: '#9DB0E0', primary: '#7D90C0'},
    {name: "xrp", color:"#7f8386", shadow: '#AFB2B3', primary: '#7F8284'},
    {name: "sol", color:"#29ddb9", shadow: '#79EAD3', primary: '#49BAA3'},
    {name: "doge", color:"#c1a236", shadow: '#D1B968', primary: '#E0D19B'},
    {name: "dot", color:"#e6007a", shadow: '#EC409B', primary: '#F280BD'},
    {name: "dai", color:"#fab324", shadow: '#FBC65B', primary: '#FCD992'},
    {name: "trx", color:"#ff0013", shadow: '#FF404E', primary: '#FF8089'},
    {name: "avax", color:"#e84142", shadow: '#EE7171', primary: '#F3A0A1'},
    {name: "shib", color:"#f5ab39", shadow: '#F8C06B', primary: '#FAD59C'},
    {name: "matic", color:"#8247e5", shadow: '#A175EB', primary: '#C1A3F2'},
    {name: "ftt", color:"#62d1e5", shadow: '#89DDEB', primary: '#B1E8F2'},
    {name: "cro", color:"#1a2f5b", shadow: '#536384', primary: '#8D97AD'},
    {name: "ltc", color:"#345d9d", shadow: '#6785B6', primary: '#9AAECE'},
    {name: "link", color:"#335dd2", shadow: '#6685DD', primary: '#99AEE9'},
    {name: "uni", color:"#f70274", shadow: '#F94197', primary: '#FB81BA'},
    {name: "xlm", color:"#adadad", shadow: '#C2C2C2', primary: '#D6D6D6'},
    {name: "bch", color:"#8dc351", shadow: '#AAD27D', primary: '#C6E1A8'},
    {name: "xmr", color:"#fa6800", shadow: '#FB8E40', primary: '#FCB380'},
    {name: "near", color:"#cacaca", shadow: '#D7D7D7', primary: '#E5E5E5'},
    {name: "etc", color:"#328332", shadow: '#65A265', primary: '#99C199'},
    {name: "algo", color:"#7d7d7d", shadow: '#9E9E9E', primary: '#BEBEBE'},
    {name: "atom", color:"#2e3148", shadow: '#626576', primary: '#9698A4'},
    {name: "flow", color:"#05ef8b", shadow: '#44F3A8', primary: '#82F7C5'},
    {name: "vet", color:"#15bdff", shadow: '#50CEFF', primary: '#8ADEFF'},
    {name: "xtz", color:"#0063ff", shadow: '#408AFF', primary: '#80B1FF'},
    {name: "hbar", color:"#c0c0c0", shadow: '#D0D0D0', primary: '#E0E0E0'},
    {name: "mana", color:"#e55072", shadow: '#EB7C95', primary: '#F2A8B9'},
    {name: "kcs", color:"#23af91", shadow: '#5AC3AD', primary: '#76CDBA'},
    {name: "fil", color:"#0090ff", shadow: '#40ACFF', primary: '#60BAFF'},
    {name: "icp", color:"#de6236", shadow: '#E68968', primary: '#EA9D81'},
    {name: "tusd", color:"#1a5aff", shadow: '#5383FF', primary: '#7098FF'},
    {name: "sand", color:"#00aeef", shadow: '#40C2F3', primary: '#60CCF5'},
    {name: "ape", color:"#1e63df", shadow: '#568AE7', primary: '#729EEB'},
    {name: "theta", color:"#2ab8e6", shadow: '#5FCAEC', primary: '#7AD3EF'},
    {name: "zec", color:"#ecb244", shadow: '#F1C573', primary: '#F3CF8A'},
    {name: "egld", color:"#d7d6d8", shadow: '#E1E0E2', primary: '#E6E5E7'},
    {name: "hnt", color:"#474dff", shadow: '#757AFF', primary: '#8C90FF'},
    {name: "ht", color:"#2a3069", shadow: '#5F648F', primary: '#7A7EA1'},
    {name: "eos", color:"#acacac", shadow: '#C1C1C1', primary: '#CBCBCB'},
    {name: "aave", color:"#9c65a6", shadow: '#B58CBC', primary: '#C19FC7'},
    {name: "axs", color:"#09c6f4", shadow: '#47D4F7', primary: '#65DBF8'},
    {name: "usdp", color:"#68ad38", shadow: '#8EC26A', primary: '#A1CC83'},
    {name: "bsv", color:"#eab300", shadow: '#EFC640', primary: '#F2CF60'},
    {name: "mkr", color:"#5dbbab", shadow: '#85CCC0', primary: '#9AD4CA'},
    {name: "klay", color:"#5dbbab", shadow: '#85CCC0', primary: '#9AD4CA'},
    {name: "btt", color: "#5dbbab", shadow: '#85CCC0', primary: '#9AD4CA'},
]