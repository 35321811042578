import { useTranslation } from 'react-i18next'
import { useMainContext } from '../contexts/main'
import { getMainTheme } from '../utils/theme'

export const useFullNode = () => {
	const { theme, lang, token, sidebar } = useMainContext()
	const { t } = useTranslation()

	const CUMULATIVE_STATUSES = [
		{
			status: 'init',
			description: t('fullnode-init-description'), // "Asset received on fullnode but didn't moved to master wallet yet",
			color: getMainTheme(theme, lang).mainGrey,
		},
		{
			status: 'pending',
			description: t('fullnode-pending-description'), // 'Marked for moving to master wallet',
			color: getMainTheme(theme, lang).mainYellow,
		},
		{
			status: 'working',
			description: t('fullnode-working-description'), // ' Moving to master wallet',
			color: getMainTheme(theme, lang).mainBlue,
		},
		{
			status: 'success',
			description: t('fullnode-success-description'), // ' Moved to master wallet successfully',
			color: getMainTheme(theme, lang).mainGreen,
		},
		{
			status: 'error',
			description: t('fullnode-error-description'), // ' Moved to master wallet failed',
			color: getMainTheme(theme, lang).mainRed,
		},
		{
			status: 'postpone',
			description: t('fullnode-postpone-description'), // 'When we are waiting for native transfer to be done to move smart contracts',
			color: getMainTheme(theme, lang).mainOrange,
		},
		{
			status: 'low',
			description: t('fullnode-low-description'), // 'Too low for transfer',
			// color: getMainTheme(theme, lang).mainInvert,
			color: getMainTheme(theme, lang).mainYellow,
		},
	]

	const getStatusInfo = (status) => CUMULATIVE_STATUSES.find((item) => item.status === status)

	const mergeSymbols = (data) => {
		if (!data?.length) return []

		let temp = []

		data.forEach((item) => {
			const { symbol } = item

			const symbolExist = !!temp.find((item) => item?.symbol === symbol)?.symbol?.length

			if (!symbolExist) {
				temp.push({ symbol })
			}
		})

		data.forEach((item) => {
			const { symbol, cumulativeStatus, amount, count, network } = item

			const symbolExist = !!temp.find((item) => item?.symbol === symbol)?.symbol?.length

			if (symbolExist) {
				temp = temp.map((item, index) => {
					if (item.symbol === symbol) {
						item = {
							...item,
							symbol,
							status: [...(item.status || []), { amount, count, network, cumulativeStatus }],
						}
					}

					return item
				})
			}
		})

		return temp
	}

	return { CUMULATIVE_STATUSES, getStatusInfo, mergeSymbols }
}
