import {Flex, IconWrapper, PageCard} from "../../styles/CommonStyles";
import ListLayout from "../../components/layout/main/ListLayout";
import {useState} from "react";
import {useAclContext} from "../../core/contexts/acl";
import Tooltip from "../../components/common/utils/Tooltip";
import {AddIcon} from "../../styles/main/setting";
import {useNavigate} from "react-router-dom";
import Tabbar from "../../components/common/tabs/Tabbar";
import AllUsers from "./AllUsers";
import NotVerifiedUsers from "./NotVerifiedUsers";


const Users = () => {

    const navigate = useNavigate()

    const {permissions} = useAclContext()
    const hasAddUser = permissions?.user?.write

    const [activeTab, setActiveTab] = useState(1)

    const onAddUser = () => {
        navigate('/users/add-user')
    }

    return (
        <ListLayout>
            <PageCard>
                {hasAddUser &&
                    <Flex fw justify={'justify-between'}>
                        <Tabbar
                            active={activeTab}
                            tabs={['all-users', 'not-verified-users']}
                            onTabClicked={(idx) => setActiveTab(idx)}
                        />
                        <Tooltip
                            content={'add-user'}
                        >
                            <IconWrapper onClick={onAddUser}>
                                <AddIcon size={24} />
                            </IconWrapper>
                        </Tooltip>
                    </Flex>
                }
                {activeTab === 1 ?
                    <AllUsers />
                    :
                    <NotVerifiedUsers />
                }

            </PageCard>
        </ListLayout>
    )
}

export default Users
