import {UserDetailsBox} from "../../styles/main/user";
import {SKeletonText} from "../../core/packages/skeleton/elements";
import {CFlex, CMargin, Flex} from "../../styles/CommonStyles";
import Shimmer from "../../core/packages/skeleton/Shimmer";
import ActionButton from "../common/buttons/ActionButton";


const UserDetailSettingSkeleton = () => {

    return (
        <>
            <UserDetailsBox>
                <CFlex fw>
                    <Flex fw justify={'space-between'}>
                        <SKeletonText width={'30%'} big>
                            <Shimmer />
                        </SKeletonText>
                        <SKeletonText width={'45%'} big>
                            <Shimmer />
                        </SKeletonText>
                    </Flex>
                    <CMargin margin={'10px'} />
                    <Flex fw justify={'space-between'}>
                        <SKeletonText width={'25%'} big>
                            <Shimmer />
                        </SKeletonText>
                        <SKeletonText width={'20%'} big>
                            <Shimmer />
                        </SKeletonText>
                    </Flex>
                    <CMargin margin={'10px'} />
                    <Flex fw justify={'space-between'}>
                        <SKeletonText width={'18%'} big>
                            <Shimmer />
                        </SKeletonText>
                        <SKeletonText width={'38%'} big>
                            <Shimmer />
                        </SKeletonText>
                    </Flex>
                    <CMargin margin={'10px'} />
                    <Flex fw justify={'space-between'}>
                        <SKeletonText width={'36%'} big>
                            <Shimmer />
                        </SKeletonText>
                        <SKeletonText width={'32%'} big>
                            <Shimmer />
                        </SKeletonText>
                    </Flex>
                    <CMargin margin={'10px'} />
                    <SKeletonText big >
                        <Shimmer />
                    </SKeletonText>
                </CFlex>
            </UserDetailsBox>
            <Flex width={'300px'} style={{ margin: '20px 0' }}>
                <ActionButton />
            </Flex>
        </>
    )
}

export default UserDetailSettingSkeleton
