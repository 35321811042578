import FilterLayout from "../../../../layout/filter/FilterLayout";
import {useGetBankTransactions} from "../../../../../core/services/react-query/report/banking";
import {bankTransactionOptions, bankTransactionSortOptions, CacheKeys} from "../../../../../core/constants/filter";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {useState} from "react";
import {useAclContext} from "../../../../../core/contexts/acl";
import {TABLET_SIZE} from "../../../../../core/constants/common";
import BankTransactionTable from "./BankTransactionTable";
import RBankTransactionTable from "../../../../responsive/main/reports/bank-transactions/RBankTransactionTable";


const BankTransactionComp = () => {

    const { width } = useWindowSize()
    const [transactions, setTransactions] =  useState({data: null, loading: true})

    const {permissions} = useAclContext()
    const hasDownload = permissions?.export?.write

    const onQuerySuccess = (res) => {
        setTransactions(res)
    }

    const Component = width > TABLET_SIZE ? BankTransactionTable : RBankTransactionTable

    return (
        <FilterLayout
            query={useGetBankTransactions}
            onQuerySuccess={onQuerySuccess}
            options={bankTransactionOptions}
            cache={CacheKeys.BANK_TRANSACTIONS}
            hasDownload={hasDownload}
            sortOptions={bankTransactionSortOptions}
        >
            {width &&
                <Component
                    data={transactions}
                />
            }

        </FilterLayout>
    )
}

export default BankTransactionComp
