import {useLocation} from "react-router-dom";
import useSidebar from "../../../core/hooks/layout/useSidebar";
import {useEffect, useState} from "react";
import NoAccess from "./NoAccess";
import GlobalLoading from "../../common/loading/GlobalLoading";
import PageNotFound from "./PageNotFound";


const specialPathes = ['users', 'cartable', 'fullnode']

const Restricted = ({children}) => {

    const location = useLocation()
    const [path, setPath] = useState(location.pathname)

    useEffect(() => {
        let _path = location.pathname

        let temp;
        _path = _path.substring(1, _path.length).split('/')

        if (_path.length === 1 || specialPathes.includes(_path[0])) {
            temp = `/${_path[0]}`
        }else {
            temp = `/${_path[0]}/${_path[1]}`
        }
        setPath(temp ? temp : _path.join('/'))
    }, [location])

    const [ hasAccess, setHasAccess ] = useState(null)

    const { urlDirectAccess } = useSidebar()
    useEffect(() => {
        if (urlDirectAccess) {
            if (typeof urlDirectAccess[path] !== 'boolean') setHasAccess('redirect')
            else setHasAccess(urlDirectAccess[path])
        }
    }, [urlDirectAccess, path])

    const LOGIN = path === '/login'
    const FIRST_PAGE = path === '/'


    return (
        <>
            {((hasAccess && hasAccess !== 'redirect') || LOGIN || FIRST_PAGE) && children}
            {(hasAccess === 'redirect' && !FIRST_PAGE) &&
                <PageNotFound />
            }
            {hasAccess === false &&
                <NoAccess />
            }
            {(hasAccess === null && !LOGIN) &&
                <GlobalLoading />
            }
        </>
    )
}


export default Restricted
