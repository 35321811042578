import {useGetUserAffiliates} from "../../../../core/services/react-query/user";
import {useProfileQuery} from "../../../../core/services/react-query/profile";
import styled from "styled-components";
import {CFlex, DText, Flex} from "../../../../styles/CommonStyles";
import {forwardRef, useEffect, useRef} from "react";
import UserLink from "../../../common/utils/UserLink";
import NoData from "../../../common/utils/NoData";
import Loader from "../../../common/loading/Loader";



const UserAffiliate = ({user}) => {

    const { data: profile } = useProfileQuery()
    const { data: affs, isLoading } = useGetUserAffiliates(user?._id)

    // const affs = [
    //     1, 2, 3, 4, 5
    // ]

    const wrapperRef = useRef()
    const parentRef = useRef()
    const childRefs = useRef([])
    const dragger = useRef()

    let mouseDown = false
    let startX
    let scrollLeft

    const startDragging = (e) => {
        mouseDown = true
        startX = e.pageX - dragger.current?.offsetLeft
        scrollLeft = dragger.current?.scrollLeft
    }

    const stopDragging = (e) => {
        mouseDown = false
    }

    useEffect(() => {
        dragger.current?.addEventListener('mousemove', (e) => {
            e.preventDefault()
            if (!mouseDown) { return }
            const x = e.pageX - dragger.current.offsetLeft
            const scroll = x - startX
            dragger.current.scrollLeft = scrollLeft - scroll
        })
        dragger.current?.addEventListener('mousedown', startDragging, false)
        dragger.current?.addEventListener('mouseup', stopDragging, false)
        dragger.current?.addEventListener('mouseleave', stopDragging, false)

    }, [dragger])

    return (
        <>
            <CFlex fw fh ref={wrapperRef}>
                {isLoading ?
                    <Loader />
                    :
                    (affs?.length ?
                        <>
                            <AffItem ref={parentRef} user={profile}/>
                            <HLine />
                            <Grabber ref={dragger} width={wrapperRef?.current?.offsetWidth}>
                                <Content count={affs?.length || 0} width={wrapperRef?.current?.offsetWidth}>
                                    <WLine count={affs?.length || 0} />
                                    <Flex>
                                        {affs?.map((item, idx) => (
                                            <CFlex>
                                                <HLine />
                                                <AffItem ref={el => childRefs.current[idx] = el} user={item}/>
                                            </CFlex>
                                        ))}
                                    </Flex>

                                </Content>
                            </Grabber>
                        </>
                            :
                            <NoData />
                    )
                }


            </CFlex>
        </>

    )
}


const AffItem = forwardRef(({ user }, ref) => {

    return (
        <ItemWrapper ref={ref}>
            <CFlex fw style={{ gap: '12px' }}>
                <DText main style={{ direction: 'ltr' }}>
                    <UserLink name={`${user?._id?.substring(0, 15)}...`} _id={user._id} />
                </DText>
                <DText primary style={{ textAlign: 'center' }} fontSize={'s'}>
                    {`${user.firstName} ${user.lastName}`}
                </DText>
                <DText main fontSize={'s'}>
                    {user.mobile}
                </DText>
            </CFlex>
        </ItemWrapper>
    )
})


const ItemWrapper = styled.div`
  width: 164px;
  height: 184px;
  background-color: ${props => props.theme.tInputBg};
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.1);
  border: 1px solid ${props => props.theme.mainOrange}10;
  margin: 0 15px;
  padding: 10px;
`

const HLine = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${props => props.theme.color};
`

const WLine = styled.div`
  width: ${({count}) => (count * 164) + (count * 30) - (2 * 97)}px;
  height: 1px;
  background-color: ${props => props.theme.color};
`


const Grabber = styled.div`
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar{
    display: none;
  };
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const Content = styled.div`
  width: ${({count, width}) => Math.max((count * 164) + (count * 30), width)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default UserAffiliate
