import {fullnodeListAttributes} from "../../../core/constants/headers";
import TableLayout from "../../layout/main/TableLayout";
import {Column, Flex, Relative, Row} from "../../../styles/CommonStyles";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import Address from "../../common/common/Address";
import Tooltip from "../../common/utils/Tooltip";
import {CopyIcon, ShowIcon} from "../../../styles/main/MainCommonStyles";
import {useCopyToClipboard} from "../../../core/hooks/common/useCopyToClipboard";
import CoinRow from "../../common/common/CoinRow";
import {formatNumber} from "../../../core/utils/common";
import {TradeTypeBadge} from "../../../styles/main/orders";
import DateTime from "../../common/utils/DateTime";
import Operation from "../../common/utils/Operation";
import {useNavigate} from "react-router-dom";
import {getTradeTypeColor} from "../../../core/utils/theme";


const FullnodeListTable = ({ data }) => {

    const { getTableIndex } = useGetTableIndex()
    const { data: transactions } = data
    const { cs, headers } = fullnodeListAttributes
    const { copyToClip } = useCopyToClipboard()

    const navigate = useNavigate()
    const onDetailsClicked = (trans) => {
        navigate(`/fullnode/${trans._id}`)
    }

    return (
        <TableLayout
            data={data}
            cs={cs}
            headers={headers}
        >
            {transactions?.data?.map((item, idx) => (
                <Relative key={item._id}>
                    <Row index={idx} cs={cs}>
                        <Column>
                            {getTableIndex(idx)}
                        </Column>
                        <Column>
                            <Flex>
                                <Address address={item.fromAddress} />
                                <Tooltip content={item.fromAddress}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(item.fromAddress)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Column>
                        <Column>
                            <Flex>
                                <Address address={item.toAddress} />
                                <Tooltip content={item.toAddress}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-address'}>
                                    <div onClick={() => copyToClip(item.toAddress)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Column>
                        <Column>
                            <CoinRow coin={{ id: item.symbol, name: item.symbol, fa: item.symbol }} />
                        </Column>
                        <Column>
                            <TradeTypeBadge>
                                {item.network}
                            </TradeTypeBadge>
                        </Column>
                        <Column>
                            {formatNumber(item.amount, { type: item.symbol })}
                        </Column>
                        <Column>
                            <Flex>
                                <Address address={item.txHash} />
                                <Tooltip content={item.txHash}>
                                    <div>
                                        <ShowIcon size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip content={'copy-txHash'}>
                                    <div onClick={() => copyToClip(item.txHash)}>
                                        <CopyIcon size={20} />
                                    </div>
                                </Tooltip>
                            </Flex>
                        </Column>
                        <Column center>
                            <TradeTypeBadge color={getTradeTypeColor(item.cumulative.status)}>
                                {item.cumulative.status}
                            </TradeTypeBadge>
                        </Column>
                        <Column center>
                            <DateTime dt={item.createdAt} />
                        </Column>
                    </Row>
                    <Column operation>
                        <Operation
                            onDetailsClicked={() => onDetailsClicked(item)}
                            hasDetails
                        />
                    </Column>
                </Relative>
            ))}
        </TableLayout>
    )
}

export default FullnodeListTable
