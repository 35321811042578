import {AscendingIcon, DescendingIcon, DropFilterBody, SortHeader, SortRow} from "../../../../styles/layout/filter";
import {fadeVariants} from "../../../../core/utils/theme";
import {CFlex, DeleteIcon, DText, Flex} from "../../../../styles/CommonStyles";
import Text from "../../../../core/utils/Text";
import {useFilterContext} from "../../../../core/contexts/filter";
import {deepCopy} from "../../../../core/utils/common";


const SortBox = (props) => {

    const {
        type,
        sort,
        onClose
    } = props

    const { pagination, cache, setPagination } = useFilterContext()
    const onSortClicked = (sortType) => {
        if (!pagination) return
        const newPagination = deepCopy(pagination[cache])
        newPagination.order = sortType === 'ascending' ? 1 : -1
        newPagination.sort = sort?.sort
        setPagination(newPagination)
        onClose()
    }

    const removeSort = () => {
        if (!pagination) return
        try {
            const newPagination = deepCopy(pagination[cache])
            delete newPagination.sort
            delete newPagination.order
            setPagination(newPagination)
        }
        catch {}
    }

    return (
        <DropFilterBody
            variants={fadeVariants}
            animate='in'
            initial='out'
            exit='out'
            type={type}
            style={{ padding: '0' }}
        >
            <CFlex width={'140px'}>
                <SortHeader>
                    <Flex width={'85%'}>
                        <DText primary fontSize={'ss'}>
                            <Text tid={`sort-${sort?.title}`} />
                        </DText>
                    </Flex>
                    {type === 'edit' &&
                        <DeleteIcon size={18} onClick={removeSort}/>
                    }
                </SortHeader>


                <SortRow ascending onClick={() => onSortClicked('ascending')}>
                    <AscendingIcon size={12} />
                    <DText main>
                        <Text tid={'ascending'} />
                    </DText>
                </SortRow>
                <SortRow onClick={() => onSortClicked('descending')}>
                    <DescendingIcon size={12} />
                    <DText main>
                        <Text tid={'descending'} />
                    </DText>
                </SortRow>
            </CFlex>
        </DropFilterBody>
    )
}

export default SortBox
